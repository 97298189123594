import React from "react";
import { Modal } from "@shopify/polaris";

function ConfirmationDialog({
  active,
  cancelText = "Cancel",
  content,
  okText,
  onCancel,
  onClose,
  onOk,
  title,
  isDestructive = false,
}: {
  readonly active: boolean;
  readonly cancelText?: string;
  readonly content: string;
  readonly okText: string;
  readonly onCancel: () => void;
  readonly onClose: () => void;
  readonly onOk: () => void;
  readonly title: string;
  readonly isDestructive?: boolean;
}): React.JSX.Element {
  return (
    <Modal
      onClose={onClose}
      open={active}
      primaryAction={{
        content: okText,
        onAction: onOk,
        destructive: isDestructive,
      }}
      secondaryActions={[
        {
          content: cancelText,
          onAction: onCancel,
        },
      ]}
      title={title}
    >
      <Modal.Section>{content}</Modal.Section>
    </Modal>
  );
}

export default ConfirmationDialog;
