import React, { useCallback, useRef, useState } from "react";
import {
  FormLayout,
  Label,
  Card,
  Link,
  Select,
  TextField,
  BlockStack,
  Text,
  Icon,
  InlineStack,
  Button,
} from "@shopify/polaris";
import { EditMinor } from "@shopify/polaris-icons";
import type { IPurchaseOrder, Supplier } from "../../api_utils/types";
import CustomDatePicker from "../common/CustomDatePicker/CustomDatePicker";
import { formatDateStringToMMDYY } from "../common/CustomDatePicker/utils";
import { assertString } from "../../helper_functions/utils";
import {
  useHorseLocationsOptions,
  useHorseVariantVendorsOptions,
  useSuppliersSearchOptions,
} from "../../api_utils/requests";

interface SupplierOrVendor {
  label: string;
  value: string;
  name?: string;
}

type PurchaseOrderSetter = (prevPurchaseOrder: IPurchaseOrder) => IPurchaseOrder;

export default function PurchaseOrderEditCard({
  purchaseOrder,
  setPurchaseOrder,
  setIsDirty,
  currencies,
  editVendorOrSupplier,
  setEditVendorOrSupplier,
  editDestination,
  setEditDestination,
}: {
  readonly purchaseOrder: IPurchaseOrder;
  readonly setPurchaseOrder: (purchaseOrder: IPurchaseOrder | PurchaseOrderSetter) => void;
  readonly setIsDirty: (isDirty: boolean) => void;
  readonly currencies: string[];
  readonly editVendorOrSupplier: boolean;
  readonly setEditVendorOrSupplier: (editVendorOrSupplier: boolean) => void;
  readonly editDestination: boolean;
  readonly setEditDestination: (editDestination: boolean) => void;
}): React.ReactElement {
  const firstSelectableDate = useRef(new Date());
  const { data: vendors } = useHorseVariantVendorsOptions({ omitAll: true });
  const { data: suppliers } = useSuppliersSearchOptions({ omitAll: true });
  const { data: horseLocations } = useHorseLocationsOptions({ omitAll: true });

  const [editedVendorOrSupplierValue, setEditedVendorOrSupplierValue] = useState<string | null>(
    purchaseOrder.supplier ? `supplier:${purchaseOrder.supplier.id}` : `vendor:${purchaseOrder.vendor}`,
  );
  const [editedDestination, setEditedDestination] = useState<string>(purchaseOrder.horse_location_id.toString());

  const formattedSuppliers = suppliers.map<SupplierOrVendor>((supplierOption) => ({
    label: `Supplier - ${supplierOption.label}`,
    value: `supplier:${supplierOption.value}`,
    name: supplierOption.label,
  }));
  const formattedVendors = vendors.map<SupplierOrVendor>((vendorOption) => ({
    label: `Vendor - ${vendorOption.label}`,
    value: `vendor:${vendorOption.value}`,
  }));

  const vendorsAndSuppliers = [...formattedSuppliers, ...formattedVendors];

  const handleCurrencyChange = useCallback(
    (currency: string) => {
      setPurchaseOrder({ ...purchaseOrder, currency });
      setIsDirty(true);
    },
    [purchaseOrder, setPurchaseOrder, setIsDirty],
  );

  const handleLabelChange = useCallback(
    (label: string) => {
      setPurchaseOrder({ ...purchaseOrder, label });
      setIsDirty(true);
    },
    [purchaseOrder, setPurchaseOrder, setIsDirty],
  );

  const setDate = useCallback(
    (newArrivalDate: Date) => {
      setPurchaseOrder({ ...purchaseOrder, arrival_date: newArrivalDate });
      setIsDirty(true);
    },
    [purchaseOrder, setPurchaseOrder, setIsDirty],
  );

  const handleVendorOrSupplierChange = (value: string): void => {
    const [type, id] = value.split(":") as [string, number | string];
    if (type === "supplier") {
      const newSupplier = vendorsAndSuppliers.find((item) => item.value === value);
      setPurchaseOrder((prevOrder: IPurchaseOrder): IPurchaseOrder => {
        const updatedOrder: IPurchaseOrder = { ...prevOrder };
        updatedOrder.vendor = null;
        updatedOrder.supplier = {
          id: id as number,
          name: newSupplier.name,
        } as Supplier;
        updatedOrder.supplier_id = id as number;
        return updatedOrder;
      });
    } else {
      setPurchaseOrder((prevOrder: IPurchaseOrder): IPurchaseOrder => {
        const updatedOrder: IPurchaseOrder = { ...prevOrder };
        updatedOrder.supplier_id = null;
        updatedOrder.supplier = null;
        updatedOrder.vendor = id as string;
        return updatedOrder;
      });
    }
    setEditedVendorOrSupplierValue(value);
    setIsDirty(true);
  };

  const handleDestinationChange = (id: string): void => {
    const destination = horseLocations.find((location) => location.value === id);
    setEditedDestination(destination.value);
    setPurchaseOrder({
      ...purchaseOrder,
      horse_location_id: parseInt(destination.value),
      destination: destination.label,
    });
    setIsDirty(true);
  };

  return (
    <Card>
      <BlockStack gap="400">
        {editVendorOrSupplier ? (
          <Select
            helpText="Select the vendor or supplier from whom you will buy your variants."
            id="vendorOrSupplier"
            label="Vendor or supplier"
            onChange={handleVendorOrSupplierChange}
            options={vendorsAndSuppliers}
            value={editedVendorOrSupplierValue}
          />
        ) : purchaseOrder.supplier ? (
          <BlockStack gap="200">
            <Text as="h3" variant="headingSm">
              Supplier
            </Text>
            <InlineStack align="space-between">
              <Label id="supplier-link">
                <Link url={`/suppliers/${purchaseOrder.supplier.id}`}>{purchaseOrder.supplier.name}</Link>
              </Label>
              <Button
                icon={<Icon source={EditMinor} />}
                onClick={() => {
                  setEditVendorOrSupplier(true);
                }}
                variant="plain"
              />
            </InlineStack>
          </BlockStack>
        ) : (
          <BlockStack gap="200">
            <Text as="h3" variant="headingSm">
              Vendor
            </Text>
            <InlineStack align="space-between">
              <Label id="vendor-name">{purchaseOrder.vendor}</Label>
              <Button
                icon={<Icon source={EditMinor} />}
                onClick={() => {
                  setEditVendorOrSupplier(true);
                }}
                variant="plain"
              />
            </InlineStack>
          </BlockStack>
        )}

        {editDestination ? (
          <Select
            helpText="Select the location that the variants will be shipped to."
            id="horse_location_id"
            label="Destination"
            onChange={handleDestinationChange}
            options={horseLocations}
            value={editedDestination}
          />
        ) : (
          <BlockStack gap="200">
            <Text as="h3" variant="headingSm">
              Destination
            </Text>
            <Label id="destination-link">
              <InlineStack align="space-between">
                <Link url={`/horse_locations/${purchaseOrder.horse_location_id}`}>{purchaseOrder.destination}</Link>
                <Button
                  icon={<Icon source={EditMinor} />}
                  onClick={() => {
                    setEditDestination(true);
                  }}
                  variant="plain"
                />
              </InlineStack>
            </Label>
          </BlockStack>
        )}
        <FormLayout>
          <FormLayout.Group>
            <TextField
              autoComplete="off"
              label="Label"
              onChange={handleLabelChange}
              placeholder={purchaseOrder.id.toString()}
              value={assertString(purchaseOrder.label)}
            />
            <Select
              id="currencies"
              label="Currency"
              onChange={handleCurrencyChange}
              options={currencies}
              value={purchaseOrder.currency}
            />
            <CustomDatePicker
              date={purchaseOrder.arrival_date}
              firstSelectableDate={firstSelectableDate.current}
              label="Estimated arrival date"
              placeholder={formatDateStringToMMDYY(purchaseOrder.arrival_date)}
              setDate={setDate}
            />
          </FormLayout.Group>
        </FormLayout>
      </BlockStack>
    </Card>
  );
}
