import React from "react";
import { Link } from "react-router-dom";

export function LinkText({
  href,
  children,
}: {
  readonly href: string;
  readonly children: React.ReactNode;
}): React.ReactNode {
  if (href) {
    return (
      <Link className="Polaris-Link Polaris-Link--removeUnderline" tabIndex={-1} to={href}>
        {children}
      </Link>
    );
  } else {
    return children;
  }
}
