import React, { useState, useEffect, useCallback, useRef, useMemo } from "react";
import {
  Card,
  Page,
  Badge,
  TextField,
  Select,
  LegacyStack,
  IndexTable,
  EmptyState,
  Layout,
  PageActions,
  Button,
  Text,
  Banner,
  BlockStack,
  InlineGrid,
  Box,
  InlineStack,
} from "@shopify/polaris";
import primaryFillImage from "images/primary-fill.svg";
import { ImportMinor, ExportMinor, FollowUpEmailMajor } from "@shopify/polaris-icons";
import EmailPdfDialog from "./support/EmailPdfDialog";
import AddVariantDialog from "../common/AddVariantDialog";
import Summary from "./support/Summary";
import {
  deletePurchaseOrder,
  getPurchaseOrderHorseVariants,
  removePurchaseOrderHorseVariantLineItem,
  updatePurchaseOrder,
  updatePurchaseOrderSelection,
  getRecommendations,
  removeAllPurchaseOrderHorseVariantLineItem,
  importPurchaseOrderLineItems,
  emailPdf,
  downloadFile,
} from "../../api_utils/requests";
import DeleteConfirmationDialog from "../common/DeleteConfirmationDialog";
import ForecastingForm from "./support/ForecastingForm";
import {
  formatMoney,
  commaDelimiter,
  makeLineItemsMap,
  assertString,
  assertNumber,
  extractMessageFromError,
  omit,
} from "../../helper_functions/utils";
import { ImportFile } from "../common/ImportFile";
import { ErrorBanner } from "../common/ErrorBanner";
import { Footer } from "../common/Footer";
import { KEYS } from "./support/utils";
import {
  PurchaseOrderState,
  type IPurchaseOrder,
  type IPurchaseOrderLineItem,
  type PurchaseOrderLineItemsQueryParams,
  type PurchaseOrderLineItemUpdatePayload,
  type PurchaseOrderUpdatePayload,
  type QueryParams,
  type RecommendationQueryParams,
} from "../../api_utils/types";
import type { NonEmptyArray } from "@shopify/polaris/build/ts/src/types";
import type { IndexTableHeading } from "@shopify/polaris/build/ts/src/components/IndexTable";
import { PURCHASE_ORDER_HELP_PAGE_URL } from "../../constants";
import PurchaseOrderDraftRow from "./PurchaseOrderDraftRow";
import PurchaseOrderEditCard from "./PurchaseOrderEditCard";
import { useQueryClient } from "@tanstack/react-query";
import SaveBarWithConfirmation from "../common/SaveBarWithConfirmation";

import Comments from "./Comments";

type PurchaseOrderLineItems = Record<number, IPurchaseOrderLineItem>;

const getPurchaseOrderLineItemsPayload = (
  purchaseOrderLineItems: PurchaseOrderLineItems,
): PurchaseOrderLineItemUpdatePayload[] =>
  Object.values(purchaseOrderLineItems).map(
    (purchaseOrderLineItem: IPurchaseOrderLineItem): PurchaseOrderLineItemUpdatePayload => {
      return {
        id: purchaseOrderLineItem.id,
        quantity: purchaseOrderLineItem.quantity,
        cost: purchaseOrderLineItem.cost,
      };
    },
  );

const assertDate = (date: Date | string, fallback: Date): Date => (date ? new Date(date) : fallback);

const notValidatePurchaseOrderValue = (quantities: PurchaseOrderLineItems): boolean =>
  Object.keys(quantities).some((key) => !quantities[key].quantity || quantities[key].quantity.toString() === "0");

const sortOptions = [
  { label: "Newest", value: "created_at-desc" },
  { label: "Oldest", value: "created_at-asc" },
  { label: "Title A-Z", value: "product_title-asc" },
  { label: "Title Z-A", value: "product_title-desc" },
  { label: "SKU A-Z", value: "sku-asc" },
  { label: "SKU Z-A", value: "sku-desc" },
  { label: "Available small to big", value: "currently_available-asc" },
  { label: "Available big to small", value: "currently_available-desc" },
  { label: "Ordered small to big", value: "ordered_quantity-asc" },
  { label: "Ordered big to small", value: "ordered_quantity-desc" },
  { label: "Sales rate small to big", value: "sales_rate-asc" },
  { label: "Sales rate big to small", value: "sales_rate-desc" },
  { label: "Recommend small to big", value: "needed-asc" },
  { label: "Recommend big to small", value: "needed-desc" },
  { label: "Newest stockout date", value: "oos_date-asc" },
  { label: "Oldest stockout date", value: "oos_date-desc" },
  { label: "Cost small to big", value: "cost-asc" },
  { label: "Cost big to small", value: "cost-desc" },
  { label: "Quantity small to big", value: "quantity-asc" },
  { label: "Quantity big to small", value: "quantity-desc" },
  { label: "Line total small to big", value: "line_total-asc" },
  { label: "Line total big to small", value: "line_total-desc" },
];

const findDiff = (
  currentPolis: PurchaseOrderLineItems,
  newPolis: PurchaseOrderLineItems,
): {
  new: (keyof PurchaseOrderLineItems)[];
  removed: (keyof PurchaseOrderLineItems)[];
  unchanged: (keyof PurchaseOrderLineItems)[];
} => {
  const diff = {
    new: [],
    removed: [],
    unchanged: [],
  };
  const currentPolisIds = new Set<number>(Object.keys(currentPolis).map(assertNumber));
  const newPolisIds = new Set<number>(Object.keys(newPolis).map(assertNumber));
  newPolisIds.forEach((newPoliId) => {
    if (!currentPolisIds.has(newPoliId)) {
      diff.new.push(newPoliId);
    }
  });
  currentPolisIds.forEach((currentPoliId) => {
    if (!newPolisIds.has(currentPoliId)) {
      diff.removed.push(currentPoliId);
    }
  });
  newPolisIds.forEach((newPoliId) => {
    if (currentPolisIds.has(newPoliId)) {
      diff.unchanged.push(newPoliId);
    }
  });
  return diff;
};

export default function Index({
  purchaseOrder: initialPurchaseOrder,
  refetch,
  lineItems: initialLineItems,
  currencies,
}: {
  purchaseOrder: IPurchaseOrder;
  refetch: () => void;
  lineItems: IPurchaseOrderLineItem[];
  currencies: string[];
}): React.ReactElement {
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    shopify.loading(isLoading);
  }, [isLoading]);

  // State
  const [isDirty, setIsDirty] = useState(false);

  const [importCSVModalIsOpen, setImportCSVModalIsOpen] = useState(false);
  const [sortedValue, setSortedValue] = useState("created_at-asc");
  const [loadingTable, setLoadingTable] = useState(false);
  const [deletionDialogueActive, setDeletionDialogueActive] = useState(false);
  const [addVariantModalActive, setAddVariantModalActive] = useState(false);
  const [emailPdfDialogActive, setEmailPdfDialogActive] = useState(false);
  const [editVendorOrSupplier, setEditVendorOrSupplier] = useState<boolean>(false);
  const [editDestination, setEditDestination] = useState<boolean>(false);

  const handleEmailPdfModalClose = useCallback(() => {
    setEmailPdfDialogActive(false);
  }, []);
  const openImportCSVModal = useCallback(() => {
    setImportCSVModalIsOpen(true);
  }, []);
  const openDeletionModal = useCallback(() => {
    setDeletionDialogueActive(true);
  }, []);
  const openEmailPdfModal = useCallback(() => {
    setEmailPdfDialogActive(true);
  }, []);
  const openAddVariantModal = useCallback(() => {
    setAddVariantModalActive(true);
  }, []);

  // Purchase Order
  const defaultArrivalDate = useMemo(() => {
    const date = new Date();
    date.setMonth(date.getMonth() + 1);
    return date;
  }, []);
  const estimatedArrivalDate = assertDate(initialPurchaseOrder?.arrival_date, defaultArrivalDate);
  const initialPurchaseOrderWithArrivalDate = {
    ...initialPurchaseOrder,
    arrival_date: estimatedArrivalDate,
  };
  const [resetPurchaseOrder, setResetPurchaseOrder] = useState<IPurchaseOrder>(initialPurchaseOrderWithArrivalDate);
  const [purchaseOrder, setPurchaseOrder] = useState<IPurchaseOrder>(initialPurchaseOrderWithArrivalDate);
  const [selectedForecastingMethod, setSelectedForecastingMethod] = useState<"sales_rate" | "seasonal">(
    initialPurchaseOrder.forecasting_method || "sales_rate",
  );

  const [selectedSamplingPeriod, setSelectedSamplingPeriod] = useState<number>(
    initialPurchaseOrder.sampling_period || 90,
  );

  // PurchaseOrderLineItems
  const polis = makeLineItemsMap<IPurchaseOrderLineItem>(initialLineItems);
  const [resetPolis, setResetPolis] = useState<PurchaseOrderLineItems>(polis);
  const [purchaseOrderLineItems, setPurchaseOrderLineItems] = useState<PurchaseOrderLineItems>(polis);

  // Filters
  const [salesChannelIds, setSalesChannelIds] = useState<number[]>([]);

  const [errorMessage, setErrorMessage] = useState("");
  const [autoAddMessage, setAutoAddMessage] = useState<string>("");
  const clearAutoAddMessage = useCallback(() => {
    setAutoAddMessage("");
  }, []);

  const query = useMemo<PurchaseOrderLineItemsQueryParams>(
    () => ({
      sampling_period: selectedSamplingPeriod,
      arrival_date: purchaseOrder.arrival_date,
      days_of_inventory: purchaseOrder.days_of_inventory,
      forecasting_method: selectedForecastingMethod,
      by_sort: sortedValue,
      sale_shopify_channel_ids: salesChannelIds,
    }),
    [
      selectedSamplingPeriod,
      purchaseOrder.arrival_date,
      purchaseOrder.days_of_inventory,
      selectedForecastingMethod,
      sortedValue,
      salesChannelIds,
    ],
  );

  // Only run this update on update
  const isInitialMount = useRef(true);
  useEffect(() => {
    if (!isInitialMount.current && !isLoading) {
      // manually send the resize event every time the table is updated to fix the table scrollbars
      const event = new Event("resize");
      window.dispatchEvent(event);
    }
    isInitialMount.current = false;
  }, [isLoading]);

  const handleNewPolis = useCallback(
    (response: IPurchaseOrderLineItem[], overwrite = false): void => {
      const formattedNewPolis = makeLineItemsMap<IPurchaseOrderLineItem>(response || []);

      const polisClone = { ...purchaseOrderLineItems };
      const diff = findDiff(polisClone, formattedNewPolis);
      diff.new.forEach((newPoliId) => {
        polisClone[newPoliId] = formattedNewPolis[newPoliId];
      });
      diff.removed.forEach((removedPoliId) => {
        delete polisClone[removedPoliId];
      });
      diff.unchanged.forEach((unchangedPoliId) => {
        const newPoli = formattedNewPolis[unchangedPoliId];
        polisClone[unchangedPoliId] = {
          ...polisClone[unchangedPoliId],
          currently_available: newPoli.currently_available,
          oos_date: newPoli.oos_date,
          sales_rate: newPoli.sales_rate,
          needed: newPoli.needed,
          ordered_quantity: newPoli.ordered_quantity,
        };

        // Most of the time we don't want to overwrite these if the user has unsaved changes
        // BUT if we're importing a CSV, then we do want to overwrite with the new values from the CSV
        if (overwrite) {
          polisClone[unchangedPoliId].cost = newPoli.cost;
          polisClone[unchangedPoliId].quantity = newPoli.quantity;
        }
      });

      // Use the new sorting
      Object.keys(formattedNewPolis).forEach((poliId) => {
        polisClone[poliId].sortIndex = formattedNewPolis[poliId].sortIndex;
      });

      setPurchaseOrderLineItems(polisClone);
      setResetPolis(polisClone);
    },
    [purchaseOrderLineItems],
  );

  const fetchAndUpdateVariant = useCallback(async (): Promise<void> => {
    setLoadingTable(true);
    try {
      const newPolis = await getPurchaseOrderHorseVariants(purchaseOrder.id, query);
      handleNewPolis(newPolis);
    } catch (err: unknown) {
      Rollbar.error(err, { purchaseOrderId: purchaseOrder.id, query });
      console.error(err);
    } finally {
      setIsLoading(false);
      setLoadingTable(false);
    }
  }, [purchaseOrder.id, handleNewPolis, query]);

  useEffect(() => {
    void fetchAndUpdateVariant();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const handleNoteChange = useCallback(
    (note: string) => {
      setPurchaseOrder({ ...purchaseOrder, note });
      setIsDirty(true);
    },
    [purchaseOrder],
  );

  const removeVariant = useCallback(
    (lineItemId: number) => {
      setIsLoading(true);
      // Clone twice
      const failsafeLineItemsMap = { ...purchaseOrderLineItems };
      const newPurchaseOrderLineItems = { ...purchaseOrderLineItems };
      delete newPurchaseOrderLineItems[lineItemId];
      setPurchaseOrderLineItems(newPurchaseOrderLineItems);

      removePurchaseOrderHorseVariantLineItem(lineItemId)
        .then(() => {
          setResetPolis(newPurchaseOrderLineItems);
        })
        .catch((err: unknown): void => {
          Rollbar.error(err, { purchaseOrderId: purchaseOrder.id, lineItemId });
          console.error(err);
          const message = extractMessageFromError(err);
          setErrorMessage(message);
          setPurchaseOrderLineItems(failsafeLineItemsMap);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [purchaseOrderLineItems],
  );

  const removeAllVariants = useCallback(async (): Promise<void> => {
    setIsLoading(true);
    setLoadingTable(true);
    const failsafeLineItemsMap = { ...purchaseOrderLineItems };
    try {
      setPurchaseOrderLineItems({} as PurchaseOrderLineItems);
      await removeAllPurchaseOrderHorseVariantLineItem(purchaseOrder.id);
      setResetPolis({} as PurchaseOrderLineItems);
      shopify.toast.show("All variants removed");
    } catch (err: unknown) {
      Rollbar.error(err, { purchaseOrderId: purchaseOrder.id });
      console.error(err);
      const message = extractMessageFromError(err);
      setErrorMessage(message);
      setPurchaseOrderLineItems(failsafeLineItemsMap);
    } finally {
      setIsLoading(false);
      setLoadingTable(false);
    }
  }, [purchaseOrder.id, purchaseOrderLineItems]);

  const handleDiscardAction = (): void => {
    setPurchaseOrder(resetPurchaseOrder);
    setPurchaseOrderLineItems(resetPolis);
    setEditVendorOrSupplier(false);
    setEditDestination(false);
    setIsDirty(false);
  };

  const payload = useMemo<PurchaseOrderUpdatePayload>(
    () => ({
      ...omit(purchaseOrder, ["id", "destination", "created_at", "updated_at", "supplier"]),
      purchase_order_line_items_attributes: getPurchaseOrderLineItemsPayload(purchaseOrderLineItems),
      forecasting_method: selectedForecastingMethod,
      sampling_period: selectedSamplingPeriod,
      arrival_date: purchaseOrder.arrival_date,
      days_of_inventory: purchaseOrder.days_of_inventory || 90,
    }),
    [purchaseOrder, purchaseOrderLineItems, selectedForecastingMethod, selectedSamplingPeriod],
  );

  const handleMarkAsOrdered = (): void => {
    if (notValidatePurchaseOrderValue(purchaseOrderLineItems)) {
      setErrorMessage("Quantity & cost must be greater or equal to 1");
      return;
    }
    setIsLoading(true);
    const mPayload = {
      purchase_order: {
        ...payload,
        state: PurchaseOrderState.Ordered,
      },
    };
    updatePurchaseOrder(purchaseOrder.id, mPayload)
      .then(() => {
        shopify.toast.show("Save successful");
        refetch();
      })
      .catch((err: unknown): void => {
        Rollbar.error(err, mPayload);
        console.error(err);
        const message = extractMessageFromError(err);
        setErrorMessage(message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleSaveDraft = async (): Promise<void> => {
    setIsLoading(true);

    await updatePurchaseOrder(purchaseOrder.id, {
      purchase_order: payload,
    })
      .then(async () => {
        setResetPurchaseOrder(purchaseOrder);
        setResetPolis(purchaseOrderLineItems);
        shopify.toast.show("Save successful");
        setIsDirty(false);
        setEditVendorOrSupplier(false);
        setEditDestination(false);
        await queryClient.invalidateQueries({ queryKey: ["purchase_orders", purchaseOrder.id] });
      })
      .catch((err: unknown): void => {
        Rollbar.error(err, { payload });
        console.error(err);

        const message = extractMessageFromError(err);
        setErrorMessage(message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleAutofill = (): void => {
    const clonedPolis = { ...purchaseOrderLineItems };
    Object.values(clonedPolis).forEach((lineItem) => {
      const itemQuantity = Number(lineItem.needed || "0");
      let newQuantity = itemQuantity < 0 ? 0 : itemQuantity;
      if (lineItem.minimum_order_quantity && newQuantity < lineItem.minimum_order_quantity) {
        newQuantity = lineItem.minimum_order_quantity;
      }
      if (lineItem.pack_size) {
        newQuantity = Math.ceil(newQuantity / lineItem.pack_size) * lineItem.pack_size;
      }
      lineItem.quantity = newQuantity;
    });
    setPurchaseOrderLineItems(clonedPolis);
    setIsDirty(true);
  };

  const handleFormFieldChange = (
    key: (typeof KEYS)[keyof typeof KEYS],
    value: "sales_rate" | "seasonal" | number | "Any" | number[],
  ): void => {
    switch (key) {
      case KEYS.FORECASTING_METHOD: {
        setSelectedForecastingMethod(value as "sales_rate" | "seasonal");
        break;
      }
      case KEYS.SAMPLING_PERIOD: {
        setSelectedSamplingPeriod(value as number);
        break;
      }
      case KEYS.DAYS_OF_INVENTORY: {
        setPurchaseOrder({ ...purchaseOrder, days_of_inventory: value as number });
        break;
      }
      case KEYS.SALES_CHANNEL_IDS: {
        setSalesChannelIds(value === "Any" ? [] : (value as number[]));
        break;
      }
      default:
        throw new Error(`Unknown key: ${key}, value: ${value.toString()}`);
    }
  };

  const autoAdd = useCallback(
    (queryParams: RecommendationQueryParams) => {
      setIsLoading(true);
      setLoadingTable(true);
      getRecommendations(purchaseOrder.id, queryParams)
        .then((lineItems) => {
          if (lineItems.length <= Object.keys(purchaseOrderLineItems).length) {
            setAutoAddMessage(
              "Horse doesn't have any variants to recommend. You could try adjusting the recommendation settings.",
            );
            return;
          }
          const formattedNewPolis = makeLineItemsMap<IPurchaseOrderLineItem>(lineItems);
          const diff = findDiff(purchaseOrderLineItems, formattedNewPolis);
          if (diff.new.length === 0) {
            setAutoAddMessage(
              "Horse doesn't have any more variants to recommend. You could try adjusting the recommendation settings.",
            );
            return;
          }

          handleNewPolis(lineItems);
        })
        .catch((err: unknown): void => {
          Rollbar.error(err, queryParams);
          console.error(err);
          const message = extractMessageFromError(err);
          setErrorMessage(message);
        })
        .finally(() => {
          setIsLoading(false);
          setLoadingTable(false);
        });
    },
    [
      purchaseOrder,
      purchaseOrderLineItems,
      setIsLoading,
      setLoadingTable,
      setAutoAddMessage,
      setErrorMessage,
      handleNewPolis,
    ],
  );

  const emptyState = (
    <EmptyState
      action={{
        content: "Add variants",
        onAction: openAddVariantModal,
      }}
      heading="No variants added"
      image=""
    >
      <p>You have not added any variants to your purchase order yet.</p>
    </EmptyState>
  );

  const poliArray: IPurchaseOrderLineItem[] = Object.values(purchaseOrderLineItems);
  const total = poliArray.reduce(
    (acc, item) => {
      acc.totalCost += Number(item.quantity) * Number(item.cost);
      acc.cost += Number(item.cost);
      acc.quantity += Number(item.quantity);
      return acc;
    },
    { quantity: 0, cost: 0, totalCost: 0 },
  );

  const sortedLineItems: IPurchaseOrderLineItem[] = poliArray.sort((a, b) => {
    if (a.sortIndex < b.sortIndex) {
      return -1;
    } else {
      return 1;
    }
  });

  const headings = [
    { title: "Variant" },
    { title: "Available" },
    { title: "Ordered" },
    { title: selectedForecastingMethod === "seasonal" ? "seasonal" : "Sales rate" },
    { title: "Recommendation" },
    { title: "Stockout date" },
    { title: "Cost" },
    { title: "Quantity" },
    { title: "Line total" },
    { title: "" },
  ].filter((item): boolean => item.title !== "seasonal") as NonEmptyArray<IndexTableHeading>;

  const updateSelection = useCallback(
    async (selectionPayload: object, horseVariantQueryParams: QueryParams) => {
      await updatePurchaseOrderSelection(purchaseOrder.id, selectionPayload, horseVariantQueryParams);
    },
    [purchaseOrder.id],
  );

  const setPoli = useCallback(
    (newPoli: IPurchaseOrderLineItem) => {
      setPurchaseOrderLineItems({ ...purchaseOrderLineItems, [newPoli.id]: newPoli });
      setIsDirty(true);
    },
    [purchaseOrderLineItems],
  );

  const handleEmailPdf = useCallback(
    async (id, emailPayload): Promise<any> => {
      return await emailPdf(id, emailPayload, query);
    },
    [query],
  );

  const selectHorseVariantIds = useMemo(() => {
    return Object.values(purchaseOrderLineItems).map(({ horse_variant_id }) => horse_variant_id);
  }, [purchaseOrderLineItems]);

  const getQuery = useCallback((): RecommendationQueryParams => {
    const queryParams: RecommendationQueryParams = { minimum_needed: undefined };
    if (selectedSamplingPeriod) {
      queryParams.sampling_period = selectedSamplingPeriod;
    }
    if (selectedForecastingMethod) {
      queryParams.forecasting_method = selectedForecastingMethod;
    }
    if (salesChannelIds) {
      queryParams.sale_shopify_channel_ids = salesChannelIds;
    }
    if (purchaseOrder.arrival_date) {
      queryParams.arrival_date = purchaseOrder.arrival_date;
    }
    if (purchaseOrder.days_of_inventory) {
      queryParams.days_of_inventory = purchaseOrder.days_of_inventory;
    }
    return queryParams;
  }, [purchaseOrder, salesChannelIds, selectedForecastingMethod, selectedSamplingPeriod]);

  const handleAutoAdd = useCallback(() => {
    const recommendationQuery = getQuery();
    autoAdd(recommendationQuery);
  }, [autoAdd, getQuery]);

  const maxWidthStyle = { "--pc-box-max-width": "1228px", display: "flex", flexWrap: "wrap" } as React.CSSProperties;

  const pageMarkup = purchaseOrder && (
    <Page
      actionGroups={[
        {
          title: "More actions",
          actions: [
            {
              content: "Export PDF",
              onAction: () => {
                const purchaseOrderName = purchaseOrder.label || purchaseOrder.id;
                const filename = `Horse Purchase Order ${purchaseOrderName}.pdf`;
                setIsLoading(true);
                downloadFile(`/purchase_orders/${purchaseOrder.id}.pdf`, filename, query)
                  .then(() => {
                    setIsLoading(false);
                  })
                  .catch((err: unknown): void => {
                    Rollbar.error(err, query);
                    console.error(err);
                    setIsLoading(false);
                    setErrorMessage("Failed to generate PDF");
                  });
              },
              icon: ExportMinor,
            },
            {
              content: "Email PDF",
              onAction: openEmailPdfModal,
              icon: FollowUpEmailMajor,
            },
            {
              content: "Export CSV",
              onAction: async (): Promise<void> => {
                const purchaseOrderName = purchaseOrder.label || purchaseOrder.id;
                const filename = `Horse Purchase Order ${purchaseOrderName}.csv`;
                await downloadFile(`/purchase_orders/${purchaseOrder.id}.csv${window.location.search}`, filename);
              },
              icon: ExportMinor,
            },
            {
              content: "Import CSV",
              onAction: openImportCSVModal,
              icon: ImportMinor,
            },
          ],
        },
      ]}
      backAction={{
        content: "Purchase orders",
        url: "/purchase_orders",
      }}
      fullWidth
      primaryAction={{
        content: "Mark as ordered",
        onAction: handleMarkAsOrdered,
      }}
      title="Draft purchase order"
      titleMetadata={<Badge tone="new">Draft</Badge>}
    >
      <SaveBarWithConfirmation handleDiscard={handleDiscardAction} handleSave={handleSaveDraft} isDirty={isDirty} />
      <EmailPdfDialog
        active={emailPdfDialogActive}
        onClose={handleEmailPdfModalClose}
        onSend={handleEmailPdf}
        purchaseOrder={purchaseOrder}
        setErrorMessage={setErrorMessage}
      />
      <ImportFile
        apiCallMethod={async (formData) => importPurchaseOrderLineItems(purchaseOrder.id, formData)}
        modalIsOpen={importCSVModalIsOpen}
        setApiResponse={(newPOLIs) => {
          handleNewPolis(newPOLIs, true);
        }}
        setErrorMessage={setErrorMessage}
        setModalIsOpen={setImportCSVModalIsOpen}
        title="Import purchase order CSV"
      />
      <Layout>
        {errorMessage ? (
          <Layout.Section variant="fullWidth">
            <ErrorBanner errorMessage={errorMessage} setErrorMessage={setErrorMessage} />
          </Layout.Section>
        ) : null}

        <div style={maxWidthStyle}>
          <Layout.Section variant="oneHalf">
            <PurchaseOrderEditCard
              currencies={currencies}
              editDestination={editDestination}
              editVendorOrSupplier={editVendorOrSupplier}
              purchaseOrder={purchaseOrder}
              setEditDestination={setEditDestination}
              setEditVendorOrSupplier={setEditVendorOrSupplier}
              setIsDirty={setIsDirty}
              setPurchaseOrder={setPurchaseOrder}
            />
          </Layout.Section>
          <Layout.Section variant="oneHalf">
            <Card>
              <BlockStack gap="400">
                <InlineStack gap="400">
                  <img src={primaryFillImage} />
                  <Text as="h2" variant="headingSm">
                    Recommendations
                  </Text>
                </InlineStack>
                <ForecastingForm
                  daysOfInventory={purchaseOrder.days_of_inventory}
                  handleFormFieldChange={handleFormFieldChange}
                  hideProductAvailability
                  hideProductTypes
                  salesChannelIds={salesChannelIds}
                  selectedForecastingMethod={selectedForecastingMethod}
                  selectedSamplingPeriod={selectedSamplingPeriod}
                />
                <InlineStack align="center">
                  <Button onClick={handleAutoAdd} variant="primary">
                    Auto-add variants
                  </Button>
                </InlineStack>
              </BlockStack>
            </Card>
          </Layout.Section>
        </div>
        <Layout.Section variant="fullWidth">
          <Card padding="0">
            <BlockStack gap="400">
              <BlockStack gap="200">
                <Box padding="400" paddingBlockEnd="0">
                  <InlineStack align="center">
                    <AddVariantDialog
                      active={addVariantModalActive}
                      handleVariantUpdate={fetchAndUpdateVariant}
                      initialHorseLocationId={purchaseOrder.horse_location_id}
                      initialSupplierId={purchaseOrder.supplier?.id}
                      initialVendor={purchaseOrder.vendor}
                      removeAllVariants={removeAllVariants}
                      selected={selectHorseVariantIds}
                      selectionKey="purchase_order_line_items_attributes"
                      setActive={setAddVariantModalActive}
                      setErrorMessage={setErrorMessage}
                      updateSelection={updateSelection}
                    />
                  </InlineStack>
                </Box>
                {autoAddMessage ? (
                  <Box>
                    <Banner onDismiss={clearAutoAddMessage}>
                      <p>{autoAddMessage}</p>
                    </Banner>
                  </Box>
                ) : null}
                <Box paddingInlineEnd="400">
                  <LegacyStack alignment="center" distribution="trailing">
                    <Button disabled={sortedLineItems.length === 0} onClick={handleAutofill}>
                      Auto-fill quantities
                    </Button>
                    <Select
                      disabled={isDirty}
                      label="Sort by"
                      labelInline
                      onChange={setSortedValue}
                      options={sortOptions}
                      value={sortedValue}
                    />
                  </LegacyStack>
                </Box>
              </BlockStack>
              <IndexTable
                emptyState={emptyState}
                headings={headings}
                itemCount={sortedLineItems.length}
                loading={loadingTable}
                selectable={false}
              >
                <IndexTable.Row id="0" key={0} position={0} rowType="subheader">
                  <IndexTable.Cell>Totals</IndexTable.Cell>
                  <IndexTable.Cell />
                  <IndexTable.Cell />
                  {selectedForecastingMethod === "sales_rate" && <IndexTable.Cell />}
                  <IndexTable.Cell />
                  <IndexTable.Cell />
                  <IndexTable.Cell />
                  <IndexTable.Cell>{commaDelimiter(total.quantity)}</IndexTable.Cell>
                  <IndexTable.Cell>{formatMoney(total.totalCost, purchaseOrder.currency)}</IndexTable.Cell>
                  <IndexTable.Cell />
                </IndexTable.Row>
                {sortedLineItems.map((poli: IPurchaseOrderLineItem, index) => (
                  <PurchaseOrderDraftRow
                    currency={purchaseOrder.currency}
                    key={poli.id.toString()}
                    poli={poli}
                    position={index + 1}
                    removeVariant={removeVariant}
                    selectedForecastingMethod={selectedForecastingMethod}
                    setPoli={setPoli}
                  />
                ))}
              </IndexTable>
            </BlockStack>
          </Card>
        </Layout.Section>
        <Layout.Section variant="oneHalf">
          <Card>
            <InlineGrid columns="1fr auto">
              <Text as="h2" variant="headingSm">
                Note to supplier
              </Text>
            </InlineGrid>
            <Box>
              <TextField
                autoComplete="off"
                // eslint-disable-next-line @typescript-eslint/no-magic-numbers
                error={purchaseOrder.note?.length >= 1000 ? "Note is too long" : ""}
                label="Note"
                maxLength={1000}
                multiline={2}
                onChange={handleNoteChange}
                value={assertString(purchaseOrder.note)}
              />
            </Box>
          </Card>
        </Layout.Section>
        <Layout.Section variant="oneHalf">
          <Card>
            <InlineGrid columns="1fr auto">
              <Text as="h2" variant="headingSm">
                Summary
              </Text>
            </InlineGrid>
            <Box>
              <Summary
                currency={purchaseOrder.currency}
                lineTotal={Number(total.totalCost)}
                purchaseOrder={purchaseOrder}
                setErrorMessage={setErrorMessage}
              />
            </Box>
          </Card>
        </Layout.Section>
      </Layout>
      <PageActions
        secondaryActions={[
          {
            content: "Delete",
            destructive: true,
            onAction: openDeletionModal,
          },
        ]}
      />
      <DeleteConfirmationDialog
        active={deletionDialogueActive}
        content="Are you sure you want to delete this purchase order? This action cannot be reversed."
        deleteItem={deletePurchaseOrder}
        gotoPath="/purchase_orders"
        itemId={purchaseOrder.id}
        setActive={setDeletionDialogueActive}
        title="Delete purchase"
        toastMessage="Purchase order deleted successfully"
      />
      <div style={{ maxWidth: "640px", margin: "0 auto" }}>
        <Comments />
      </div>
      <Footer pageTitle="purchase orders" url={PURCHASE_ORDER_HELP_PAGE_URL} />
    </Page>
  );
  return pageMarkup;
}
