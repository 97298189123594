import React from "react";
import { LegacyCard, LegacyStack, RadioButton, List } from "@shopify/polaris";

export function InventorySyncOptions({
  userSyncOption,
  handleSyncOptionChange,
  shopifyShops,
}: {
  readonly userSyncOption: string;
  readonly handleSyncOptionChange: (newValue: boolean, id: string) => void;
  readonly shopifyShops: { shopify_domain: string }[];
}): React.JSX.Element {
  return (
    <LegacyCard>
      <LegacyCard.Header title="Select sync mode" />
      <LegacyCard.Section>
        Variants are always linked together based on their SKU and barcode. To unlink variants, simply change their SKU
        or barcode in your Shopify store.
      </LegacyCard.Section>
      <LegacyCard.Section>
        <LegacyStack vertical>
          <RadioButton
            checked={userSyncOption === "hybrid_sync"}
            disabled={shopifyShops.length > 1}
            helpText={
              <List type="bullet">
                <List.Item>
                  Shopify is the master of all inventory quantities. Horse will match Shopify's numbers.
                </List.Item>
                <List.Item>
                  Only your purchase orders, transfer orders and Stocktakes will be synced to Shopify.
                </List.Item>
              </List>
            }
            id="hybrid_sync"
            label="Basic Sync"
            onChange={handleSyncOptionChange}
          />
          <RadioButton
            checked={userSyncOption === "full_sync"}
            helpText={
              <List type="bullet">
                <List.Item>
                  Horse is the master of all inventory quantities. Shopify will match Horse's numbers.
                </List.Item>
                <List.Item>Locations from your stores will be linked together based on their name.</List.Item>
                <List.Item>Inventory changes from any store will be synced to all other stores.</List.Item>
              </List>
            }
            id="full_sync"
            label="Full Sync"
            onChange={handleSyncOptionChange}
          />
        </LegacyStack>
      </LegacyCard.Section>
    </LegacyCard>
  );
}
