import React, { useEffect, useState, useCallback, useMemo } from "react";
import {
  Button,
  LegacyCard,
  FormLayout,
  Icon,
  Page,
  LegacyStack,
  TextField,
  Layout,
  Select,
  PageActions,
  IndexTable,
  EmptyState,
} from "@shopify/polaris";
import { MobileCancelMajor, ImportMinor, ExportMinor } from "@shopify/polaris-icons";
import AddVariantDialog from "../common/AddVariantDialog";
import DeleteConfirmationDialog from "../common/DeleteConfirmationDialog";
import HorseVariant from "../common/HorseVariant/HorseVariant";
import {
  deleteSupplier,
  getSupplierHorseVariants,
  removeSupplierHorseVariant,
  removeAllSupplierHorseVariants,
  updateSupplierSelection,
  updateSupplier,
  importSupplierHorseVariants,
  downloadFile,
  getSupplier,
  useUser,
  useCurrencies,
} from "../../api_utils/requests";
import { ErrorBanner } from "../common/ErrorBanner";
import { ImportFile } from "../common/ImportFile";
import { Footer } from "../common/Footer";
import {
  assertString,
  extractMessageFromError,
  getCurrencySymbol,
  getIdFromPath,
  softAssertNumber,
} from "../../helper_functions/utils";
import type { QueryParams, Supplier, SupplierHorseVariant } from "../../api_utils/types";
import type { IndexTableHeading } from "@shopify/polaris/build/ts/src/components/IndexTable";
import type { NonEmptyArray } from "@shopify/polaris/build/ts/src/types";
import { SupplierShowPageSkeleton } from "../common/skeletons";
import { SUPPLIERS_HELP_PAGE_URL } from "../../constants";
import SaveBarWithConfirmation from "../common/SaveBarWithConfirmation";

type SupplierHorseVariants = Record<number, SupplierHorseVariant>;

const formatSupplierToSave = ({
  name,
  email,
  currency,
  notes,
  minimum_order_value,
  delivery_lead_days,
}: Supplier): Partial<Supplier> => ({
  name,
  email,
  currency,
  notes,
  minimum_order_value,
  delivery_lead_days: delivery_lead_days === 0 ? undefined : delivery_lead_days,
});

const formatSupplierHorseVariants = (supplierHorseVariants: SupplierHorseVariant[]): SupplierHorseVariants =>
  supplierHorseVariants.reduce<SupplierHorseVariants>((map, horseVariant) => {
    map[horseVariant.id] = horseVariant;
    return map;
  }, {});

const columnNames: NonEmptyArray<IndexTableHeading> = [
  { title: "Variant" },
  { title: "Minimum order quantity" },
  { title: "Pack size" },
  { title: "Supplier SKU" },
  { title: "Supplier cost" },
  { title: "" },
];

function ShowSupplier(): React.JSX.Element {
  const supplierId = useMemo(() => getIdFromPath(), []);

  const [importCSVModalIsOpen, setImportCSVModalIsOpen] = useState(false);

  const [originalSupplier, setOriginalSupplier] = useState<Supplier>();
  const [supplier, setSupplier] = useState<Supplier>({} as Supplier);
  const [originalSupplierHorseVariants, setOriginalSupplierHorseVariants] = useState<SupplierHorseVariants>(
    {} as SupplierHorseVariants,
  );
  const [supplierHorseVariants, setSupplierHorseVariants] = useState<SupplierHorseVariants>(
    {} as SupplierHorseVariants,
  );

  const [pageLoading, setPageLoading] = useState(true);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    shopify.loading(loading);
  }, [loading]);
  const [isDirty, setIsDirty] = useState(false);
  const [deletionDialogueActive, setDeletionDialogueActive] = useState(false);

  const [addVariantModalActive, setAddVariantModalActive] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");

  // Pagination for SupplierHorseVariants
  const [hasNext, setHasNext] = useState<boolean>(false);
  const [hasPrev, setHasPrev] = useState<boolean>(false);
  const urlParams = new URLSearchParams(window.location.search);
  const [pageNum, setPageNum] = useState<number>(Number(urlParams.get("page")) || 1);

  const setSupplierHorseVariantsData = (
    newSupplierHorseVariants: SupplierHorseVariant[],
    newHasNext: boolean,
    newHasPrev: boolean,
  ): void => {
    const formattedSupplierHorseVariants = formatSupplierHorseVariants(newSupplierHorseVariants || []);
    setSupplierHorseVariants(formattedSupplierHorseVariants);
    setOriginalSupplierHorseVariants(formattedSupplierHorseVariants);
    setHasNext(newHasNext);
    setHasPrev(newHasPrev);
  };

  const {
    data: {
      user: { currency: userCurrency },
    },
  } = useUser();

  const { data: currencies } = useCurrencies();

  useEffect(() => {
    Promise.all([getSupplier(supplierId), getSupplierHorseVariants(supplierId, { page: pageNum })])
      .then(
        ([{ supplier: newSupplier }, { rows: newSupplierHorseVariants, hasNext: newHasNext, hasPrev: newHasPrev }]) => {
          setOriginalSupplier(newSupplier);
          setSupplier(newSupplier);
          setSupplierHorseVariantsData(newSupplierHorseVariants, newHasNext, newHasPrev);

          setPageLoading(false);
        },
      )
      .catch((error: unknown) => {
        console.error("Error fetching data:", error);
      });
  }, [supplierId, pageNum]);

  const handleVariantUpdate = useCallback(async () => {
    await getSupplierHorseVariants(supplierId, { page: pageNum }).then(
      ({ rows: newSupplierHorseVariants, hasNext: newHasNext, hasPrev: newHasPrev }) => {
        setSupplierHorseVariantsData(newSupplierHorseVariants, newHasNext, newHasPrev);
      },
    );
  }, [supplierId, pageNum]);

  const handleSupplierChange = useCallback((value: string, key: string): void => {
    setSupplier((prevSupplier) => {
      return { ...prevSupplier, [key]: value };
    });
    setIsDirty(true);
  }, []);

  const handleSupplierNumberChange = useCallback((value: string, key: string): void => {
    const cleanValue = value?.replace(/[^\d.-]/g, "");
    setSupplier((prevSupplier) => {
      return { ...prevSupplier, [key]: cleanValue };
    });
    setIsDirty(true);
  }, []);

  const handleSaveSupplier = useCallback(() => {
    setLoading(true);
    const formattedSupplier = formatSupplierToSave(supplier);
    const payload = {
      supplier: {
        ...formattedSupplier,
        supplier_horse_variants_attributes: supplierHorseVariants,
      },
    };
    updateSupplier(supplierId, payload)
      .then((res) => {
        shopify.toast.show("Save successful");
        setIsDirty(false);
        setSupplier(res.supplier);
        setOriginalSupplier(res.supplier);
        // Refetch supplier horse variants
        void getSupplierHorseVariants(supplierId, { page: pageNum }).then(
          ({ rows: newSupplierHorseVariants, hasNext: newHasNext, hasPrev: newHasPrev }) => {
            setSupplierHorseVariantsData(newSupplierHorseVariants, newHasNext, newHasPrev);
          },
        );
      })
      .catch((err: unknown) => {
        Rollbar.error(err, payload);
        const message = extractMessageFromError(err);
        setErrorMessage(message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [supplier, supplierHorseVariants, supplierId, pageNum]);

  const handleHorseVariantsChange = useCallback(
    (variantId: number, value: number | string, key: string) => {
      const newSupplierHorseVariants = { ...supplierHorseVariants };
      newSupplierHorseVariants[variantId] = {
        ...newSupplierHorseVariants[variantId],
        [key]: value,
      } as SupplierHorseVariant;
      setSupplierHorseVariants(newSupplierHorseVariants);
      setIsDirty(true);
    },
    [supplierHorseVariants],
  );

  const removeAllVariants = useCallback(() => {
    setLoading(true);
    removeAllSupplierHorseVariants(supplierId)
      .then(() => {
        setSupplierHorseVariants({} as SupplierHorseVariants);
        setOriginalSupplierHorseVariants({} as SupplierHorseVariants);
      })
      .catch((err: unknown) => {
        Rollbar.error(err, { supplierId });
        const message = extractMessageFromError(err);
        setErrorMessage(message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [supplierId]);

  const removeVariant = useCallback(
    (id: number) => {
      setLoading(true);
      removeSupplierHorseVariant(id)
        .then(() => {
          shopify.toast.show("Variant removed");
          // Refetch supplier horse variants
          void getSupplierHorseVariants(supplierId, { page: pageNum }).then(
            ({ rows: newSupplierHorseVariants, hasNext: newHasNext, hasPrev: newHasPrev }) => {
              setSupplierHorseVariantsData(newSupplierHorseVariants, newHasNext, newHasPrev);
            },
          );
        })
        .catch((err: unknown) => {
          Rollbar.error(err, { supplierId, variantId: id });
          const message = extractMessageFromError(err);
          setErrorMessage(message);
          setSupplierHorseVariants(originalSupplierHorseVariants);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [supplierId, pageNum, originalSupplierHorseVariants],
  );

  const handleDiscardAction = useCallback(() => {
    setSupplier(originalSupplier);
    setSupplierHorseVariants(originalSupplierHorseVariants);
    setIsDirty(false);
  }, [originalSupplier, originalSupplierHorseVariants]);

  const setPageNumAndScroll = useCallback(
    async (newPageNum: number) => {
      setLoading(true);
      setPageNum(newPageNum);
      await getSupplierHorseVariants(supplierId, { page: newPageNum }).then(
        ({ rows: newSupplierHorseVariants, hasNext: newHasNext, hasPrev: newHasPrev }) => {
          setSupplierHorseVariantsData(newSupplierHorseVariants, newHasNext, newHasPrev);
          setLoading(false);

          document.getElementsByClassName("Polaris-IndexTable__Table")[0]?.scrollIntoView();
        },
      );
    },
    [supplierId],
  );
  const nextPage = useCallback(async () => {
    if (hasNext) {
      await setPageNumAndScroll(pageNum + 1);
    }
  }, [hasNext, pageNum, setPageNumAndScroll]);
  const prevPage = useCallback(async () => {
    if (hasPrev) {
      await setPageNumAndScroll(pageNum - 1);
    }
  }, [hasPrev, pageNum, setPageNumAndScroll]);

  const emptyState = (
    <EmptyState
      action={{
        content: "Add variants",
        onAction: () => {
          setAddVariantModalActive(true);
        },
      }}
      heading="No variants added"
      image=""
    >
      <p>You haven't added any variants to your supplier yet.</p>
    </EmptyState>
  );

  const pageMarkup = supplier && (
    <Page
      actionGroups={[
        {
          title: "More actions",
          actions: [
            {
              content: "Export CSV",
              onAction: async (): Promise<void> => {
                const supplierName = supplier.name || supplierId;
                const filename = `Horse Supplier ${supplierName}.csv`;
                await downloadFile(`/suppliers/${supplierId}.csv${window.location.search}`, filename);
              },
              icon: ExportMinor,
            },
            {
              content: "Import CSV",
              onAction: () => {
                setImportCSVModalIsOpen(true);
              },
              icon: ImportMinor,
            },
          ],
        },
      ]}
      backAction={{
        content: "Suppliers",
        url: "/suppliers",
      }}
      title={supplier.name}
    >
      <SaveBarWithConfirmation handleDiscard={handleDiscardAction} handleSave={handleSaveSupplier} isDirty={isDirty} />

      <ImportFile
        apiCallMethod={async (formData: object) => importSupplierHorseVariants(supplierId, formData)}
        modalIsOpen={importCSVModalIsOpen}
        setApiResponse={(supplier_horse_variants: SupplierHorseVariant[]) => {
          const newSupplierHorseVariants = formatSupplierHorseVariants(supplier_horse_variants);
          setSupplierHorseVariants(newSupplierHorseVariants);
          setOriginalSupplierHorseVariants(newSupplierHorseVariants);
        }}
        setErrorMessage={setErrorMessage}
        setModalIsOpen={setImportCSVModalIsOpen}
        title="Import supplier CSV"
      />

      <Layout>
        {errorMessage ? (
          <Layout.Section variant="fullWidth">
            <ErrorBanner errorMessage={errorMessage} setErrorMessage={setErrorMessage} />
          </Layout.Section>
        ) : null}
        <Layout.Section>
          <LegacyCard>
            <LegacyCard.Section>
              <FormLayout>
                <TextField
                  autoComplete="on"
                  id="name"
                  label="Name"
                  onChange={handleSupplierChange}
                  value={supplier.name}
                />
                <TextField
                  autoComplete="on"
                  id="email"
                  label="Contact email"
                  onChange={handleSupplierChange}
                  placeholder="contact@example.com"
                  value={assertString(supplier.email)}
                />
                <TextField
                  autoComplete="on"
                  id="notes"
                  label="Purchase order notes"
                  multiline={2}
                  onChange={handleSupplierChange}
                  value={assertString(supplier.notes)}
                />
                <FormLayout.Group condensed>
                  <TextField
                    autoComplete="off"
                    id="delivery_lead_days"
                    label="Delivery lead days"
                    min={0}
                    onChange={handleSupplierNumberChange}
                    suffix="days"
                    type="integer"
                    value={assertString(supplier.delivery_lead_days)}
                  />
                  <TextField
                    autoComplete="on"
                    id="minimum_order_value"
                    label="Minimum Order Value"
                    onChange={handleSupplierNumberChange}
                    suffix={supplier.currency || userCurrency}
                    type="number"
                    value={assertString(supplier.minimum_order_value)}
                  />
                  <Select
                    id="currency"
                    label="Currency"
                    onChange={handleSupplierChange}
                    options={currencies}
                    value={supplier.currency || userCurrency}
                  />
                </FormLayout.Group>
              </FormLayout>
            </LegacyCard.Section>

            <LegacyCard.Section title="Add variants">
              <LegacyStack distribution="center">
                <AddVariantDialog
                  active={addVariantModalActive}
                  handleVariantUpdate={handleVariantUpdate}
                  primary
                  removeAllVariants={removeAllVariants}
                  selected={Object.values(supplierHorseVariants).map(({ horse_variant_id }) => horse_variant_id)}
                  selectionKey="supplier_horse_variants_attributes"
                  setActive={setAddVariantModalActive}
                  setErrorMessage={setErrorMessage}
                  updateSelection={async (payload: object, queryParams: QueryParams) =>
                    updateSupplierSelection(supplierId, payload, queryParams)
                  }
                />
              </LegacyStack>
            </LegacyCard.Section>
          </LegacyCard>
        </Layout.Section>
        <Layout.Section>
          <LegacyCard>
            <IndexTable
              emptyState={emptyState}
              headings={columnNames}
              itemCount={Object.keys(supplierHorseVariants).length}
              pagination={{
                hasPrevious: hasPrev,
                previousKeys: [37],
                onPrevious: prevPage,
                hasNext: hasNext,
                nextKeys: [39],
                onNext: nextPage,
              }}
              selectable={false}
            >
              {Object.values(supplierHorseVariants).map((variant: SupplierHorseVariant, index) => (
                <IndexTable.Row id={variant.id.toString()} key={variant.id} position={index}>
                  <IndexTable.Cell>
                    <div className="medium_hv">
                      <HorseVariant key={variant.id} variant={variant} />
                    </div>
                  </IndexTable.Cell>
                  <IndexTable.Cell>
                    <div className="w120">
                      <TextField
                        autoComplete="off"
                        id="minimum_order_quantity"
                        label="Minimum order quantity"
                        labelHidden
                        min={1}
                        name="Minimum order quantity"
                        onChange={(value: string, key: string) => {
                          handleHorseVariantsChange(variant.id, softAssertNumber(value), key);
                        }}
                        type="integer"
                        value={assertString(variant.minimum_order_quantity)}
                      />
                    </div>
                  </IndexTable.Cell>
                  <IndexTable.Cell>
                    <div className="w120">
                      <TextField
                        autoComplete="off"
                        id="pack_size"
                        label="Pack size"
                        labelHidden
                        min={0}
                        name="Pack size"
                        onChange={(value: string, key: string) => {
                          handleHorseVariantsChange(variant.id, softAssertNumber(value), key);
                        }}
                        type="integer"
                        value={assertString(variant.pack_size)}
                      />
                    </div>
                  </IndexTable.Cell>
                  <IndexTable.Cell>
                    <div className="w120">
                      <TextField
                        autoComplete="off"
                        id="supplier_sku"
                        label="Supplier SKU"
                        labelHidden
                        name="Supplier SKU"
                        onChange={(value: string, key: string) => {
                          handleHorseVariantsChange(variant.id, value, key);
                        }}
                        value={assertString(variant.supplier_sku)}
                      />
                    </div>
                  </IndexTable.Cell>
                  <IndexTable.Cell>
                    <div className="w120">
                      <TextField
                        autoComplete="off"
                        id="cost"
                        label="Supplier cost"
                        labelHidden
                        min={0.0}
                        name="Supplier cost"
                        onChange={(value: string, key: string) => {
                          handleHorseVariantsChange(variant.id, value, key);
                        }}
                        prefix={getCurrencySymbol(supplier.currency || userCurrency)}
                        step={0.01}
                        type="currency"
                        value={assertString(variant.cost)}
                      />
                    </div>
                  </IndexTable.Cell>
                  <IndexTable.Cell>
                    <Button
                      icon={<Icon source={MobileCancelMajor} />}
                      onClick={() => {
                        removeVariant(variant.id);
                      }}
                      variant="plain"
                    />
                  </IndexTable.Cell>
                </IndexTable.Row>
              ))}
            </IndexTable>
          </LegacyCard>
        </Layout.Section>
      </Layout>
      <PageActions
        secondaryActions={[
          {
            content: "Delete",
            destructive: true,
            onAction: () => {
              setDeletionDialogueActive(true);
            },
          },
        ]}
      />
      <DeleteConfirmationDialog
        active={deletionDialogueActive}
        content="Are you sure you want to delete this supplier? This action cannot be reversed."
        deleteItem={deleteSupplier}
        gotoPath="/suppliers"
        itemId={supplierId}
        setActive={setDeletionDialogueActive}
        title="Delete supplier"
        toastMessage="Supplier deleted successfully"
      />
      <Footer pageTitle="suppliers" url={SUPPLIERS_HELP_PAGE_URL} />
    </Page>
  );

  return pageLoading ? <SupplierShowPageSkeleton /> : pageMarkup;
}

export default ShowSupplier;
