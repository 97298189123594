import React, { useState } from "react";
import { Page, AppProvider, LegacyCard, Text, Button, LegacyStack, TextField, FormLayout } from "@shopify/polaris";
import enTranslations from "@shopify/polaris/locales/en.json";
import logo from "images/horse-logo-110.png";
import textLogo from "images/text-440.png";
import bg from "images/email_images/bg1.svg";

function Login(): React.JSX.Element {
  const [domain, setDomain] = useState("");

  // If inside an iframe, then redirect to the auth page to avoid rendering the login page inside the iframe
  if (window.top !== window.self) {
    const body = document.querySelector("body");
    // data-api-key="<%= ShopifyApp.configuration.api_key %>"
    // data-host="<%= host %>"
    // data-shop="<%= ShopifyApp::Utils.sanitize_shop_domain(params[:shop]) %>"
    if (!body.dataset.shop || !body.dataset.apiKey) {
      throw new Error("Missing data-shop or data-api-key attributes on body");
    }

    let redirectTargetElement = "https://" + body.dataset.shop + "/admin/apps/" + body.dataset.apiKey;
    if (window.location.pathname !== "/") {
      redirectTargetElement += "?return_to=" + window.location.pathname;
    } else if (window.location.search) {
      const parsedQuery = new URLSearchParams(window.location.search);
      if (parsedQuery.has("return_to")) {
        redirectTargetElement += "?return_to=" + parsedQuery.get("return_to");
      }
    }

    const anchorTag = document.createElement("a");
    anchorTag.href = redirectTargetElement;

    open(anchorTag.href, "_top");
  }

  return (
    <AppProvider i18n={enTranslations}>
      <div className="loginCenter">
        <div
          className="background"
          style={{
            background: `url(${bg})`,
            backgroundSize: "cover",
          }}
        />
        <Page>
          <LegacyCard sectioned>
            <LegacyStack alignment="center" distribution="center">
              <img height="70px" src={logo} />
              <img height="35px" src={textLogo} />
            </LegacyStack>
            <form action="/login">
              <Text as="span" visuallyHidden>
                <TextField autoComplete="off" label="" labelHidden name="top_level" value="true" />
                <TextField autoComplete="off" label="" labelHidden name="return_to" value="/home" />
              </Text>
              <Text as="h2" variant="headingMd">
                Login
              </Text>
              <Text as="span" tone="subdued">
                Continue to Horse
              </Text>
              <FormLayout>
                <FormLayout.Group>
                  <div className="input-placeholder-right">
                    <TextField
                      autoComplete="on"
                      label=""
                      labelHidden
                      name="shop"
                      onChange={setDomain}
                      placeholder=".myshopify.com"
                      value={domain}
                    />
                    <span className="helperText">Shopify store domain name</span>
                  </div>
                </FormLayout.Group>
                <FormLayout.Group>
                  <Button fullWidth submit variant="primary">
                    Next
                  </Button>
                </FormLayout.Group>
              </FormLayout>
            </form>
          </LegacyCard>
        </Page>
      </div>
    </AppProvider>
  );
}

export default Login;
