import { ChoiceList, Select, LegacyStack, Button, FormLayout, Labelled, TextField } from "@shopify/polaris";
import React, { useState, useCallback } from "react";
import PopoverWithCheckList from "../../common/Filters/PopoverWithCheckList";
import {
  allTypes,
  useHorseVariantsProductTypeOptions,
  useShopifyChannelAvailabilityOptions,
  useShopifyChannelSalesOptions,
} from "../../../api_utils/requests";
import { KEYS } from "./utils";
import type { Choice, RecommendationQueryParams } from "../../../api_utils/types";
import { softAssertNumber } from "../../../helper_functions/utils";

const samplingPeriods = [
  { label: "Past 30 Days", value: "30" },
  { label: "Past 90 Days", value: "90" },
  { label: "Past 180 Days", value: "180" },
  { label: "Past 365 Days", value: "365" },
];

const forecastingMethods: Choice[] = [
  { label: "Seasonal", value: "seasonal", helpText: "Best for seasonal products" },
  { label: "Sales rate", value: "sales_rate", helpText: "Best for products with consistent sales" },
];

const defaultProductTypesFilter = { vendor: null, supplier_id: null };

function ForecastingForm({
  daysOfInventory,
  autoAdd,
  close,
  handleFormFieldChange,
  salesChannelIds,
  productTypesFilter = defaultProductTypesFilter,
  selectedForecastingMethod,
  selectedSamplingPeriod,
  hideProductAvailability = false,
  hideProductTypes = false,
}: {
  readonly daysOfInventory: number;
  readonly autoAdd?: (query: RecommendationQueryParams) => void;
  readonly close?: () => void;
  readonly handleFormFieldChange: (
    key: (typeof KEYS)[keyof typeof KEYS],
    value: "sales_rate" | "seasonal" | number | "Any" | number[],
  ) => void;
  readonly salesChannelIds: number[];
  readonly productTypesFilter?: { vendor: string; supplier_id: number };
  readonly selectedForecastingMethod: string;
  readonly selectedSamplingPeriod: number;
  readonly hideProductAvailability?: boolean;
  readonly hideProductTypes?: boolean;
}): React.ReactElement {
  const { data: salesShopifyChannels } = useShopifyChannelSalesOptions();
  const { data: availableShopifyChannels } = useShopifyChannelAvailabilityOptions();
  const [productType, setProductType] = useState<string>();
  const { data: productTypes } = useHorseVariantsProductTypeOptions({
    ...productTypesFilter,
    collection: "undiscarded",
  });

  const [productAvailability, setProductAvailability] = useState<string>();

  const getQuery = useCallback((): RecommendationQueryParams => {
    const query: RecommendationQueryParams = { minimum_needed: undefined };
    if (productType) {
      query.product_type = productType;
    }
    const shopifyChannelOption = availableShopifyChannels.find((channel) => channel.value === productAvailability);
    if (shopifyChannelOption) {
      const [shopifyChannelIds, isPublished] = shopifyChannelOption.value.split(":");
      if (isPublished === "true") {
        query.published_shopify_channel_ids = shopifyChannelIds.split("|").map(softAssertNumber);
      } else if (isPublished === "false") {
        query.unpublished_shopify_channel_ids = shopifyChannelIds.split("|").map(softAssertNumber);
      }
    }

    if (selectedSamplingPeriod) {
      query.sampling_period = selectedSamplingPeriod;
    }
    if (selectedForecastingMethod) {
      query.forecasting_method = selectedForecastingMethod;
    }
    if (salesChannelIds) {
      query.sale_shopify_channel_ids = salesChannelIds;
    }
    if (daysOfInventory) {
      query.days_of_inventory = daysOfInventory;
    }
    return query;
  }, [
    daysOfInventory,
    salesChannelIds,
    productAvailability,
    productType,
    selectedForecastingMethod,
    selectedSamplingPeriod,
    availableShopifyChannels,
  ]);

  const handleForecastingModelChange = useCallback(
    ([forecasting_method]: ("sales_rate" | "seasonal")[]): void => {
      handleFormFieldChange(KEYS.FORECASTING_METHOD, forecasting_method);
    },
    [handleFormFieldChange],
  );
  const handleSamplingPeriodChange = useCallback(
    (sampling_period: string): void => {
      handleFormFieldChange(KEYS.SAMPLING_PERIOD, Number(sampling_period));
    },
    [handleFormFieldChange],
  );
  const handleSalesSourceSelection = useCallback(
    (shopify_channel_ids: string[]): void => {
      handleFormFieldChange(KEYS.SALES_CHANNEL_IDS, shopify_channel_ids.map(softAssertNumber));
    },
    [handleFormFieldChange],
  );
  const removeShopifyChannel = useCallback(
    (removedSalesChannelId: string): void => {
      const remaining = salesChannelIds.filter((salesChannelId) => salesChannelId.toString() !== removedSalesChannelId);
      handleFormFieldChange(KEYS.SALES_CHANNEL_IDS, remaining);
    },
    [handleFormFieldChange, salesChannelIds],
  );
  const [daysOfInventoryError, setDaysOfInventoryError] = useState<string>();
  const handleDaysOfInventoryChange = useCallback(
    (newDaysOfInventory: number): void => {
      if (newDaysOfInventory > 365 || newDaysOfInventory < 1) {
        setDaysOfInventoryError("Days of inventory must be between 1 and 365 days.");
      } else {
        setDaysOfInventoryError(undefined);
      }
      handleFormFieldChange(KEYS.DAYS_OF_INVENTORY, newDaysOfInventory);
    },
    [handleFormFieldChange],
  );
  const handleProductTypeChange = useCallback(
    (product_type: string): void => {
      if (product_type === allTypes) {
        setProductType(null);
      } else {
        setProductType(product_type);
      }
    },
    [setProductType],
  );

  const handleAutoAdd = useCallback(() => {
    const query = getQuery();
    autoAdd(query);
    close();
  }, [autoAdd, close, getQuery]);

  const selectedMethod = forecastingMethods.find((item) => item.value === selectedForecastingMethod);
  const selectedPeriod = samplingPeriods.find((item) => item.value === selectedSamplingPeriod.toString());

  return (
    <LegacyStack vertical>
      <FormLayout>
        <ChoiceList
          allowMultiple={false}
          choices={forecastingMethods}
          onChange={handleForecastingModelChange}
          selected={[selectedMethod.value]}
          title="Select a forecasting model"
        />

        {selectedForecastingMethod !== "seasonal" && (
          <div style={{ maxWidth: "300px" }}>
            <Select
              helpText="How many days of sales to use."
              label="Sampling period"
              onChange={handleSamplingPeriodChange}
              options={samplingPeriods}
              value={selectedPeriod.value.toString()}
            />
          </div>
        )}
        <div style={{ maxWidth: "300px" }}>
          <TextField
            autoComplete="off"
            error={daysOfInventoryError}
            helpText="How long you'd like the inventory to last for after the purchase order arrives."
            inputMode="numeric"
            label="Days of inventory"
            max={365}
            min={1}
            onChange={(value: string) => {
              handleDaysOfInventoryChange(Number(value));
            }}
            type="integer"
            value={daysOfInventory?.toString()}
          />
        </div>
        {!hideProductTypes && (
          <Select
            id="productType"
            label="Type"
            onChange={handleProductTypeChange}
            options={productTypes}
            value={productType}
          />
        )}
        {!hideProductAvailability && (
          <Labelled
            helpText="Only recommend products currently published to these sales channels."
            id="select-publications"
            label="Select published channels"
          >
            <PopoverWithCheckList
              allowMultiple={false}
              choices={availableShopifyChannels}
              defaultTitle="Publishing"
              onChange={([value]: string[]) => {
                setProductAvailability(value);
              }}
              onValueRemoved={() => {
                setProductAvailability(undefined);
              }}
              selected={productAvailability ? [productAvailability] : []}
            />
          </Labelled>
        )}

        <Labelled
          helpText="Only use sales from certain sales channels."
          id="select-sales-sources"
          label="Select sales channels (optional)"
        >
          <PopoverWithCheckList
            choices={salesShopifyChannels}
            defaultTitle="Sales channels"
            onChange={handleSalesSourceSelection}
            onValueRemoved={removeShopifyChannel}
            selected={salesChannelIds.map((id) => id.toString())}
          />
        </Labelled>
      </FormLayout>

      {close ? (
        <LegacyStack alignment="center" distribution="center">
          <Button onClick={close}>Cancel</Button>
          <Button onClick={handleAutoAdd} variant="primary">
            Auto-Add
          </Button>
        </LegacyStack>
      ) : null}
    </LegacyStack>
  );
}

export default ForecastingForm;
