import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import HomeIndex from "../home/Index";
import HorseVariantShow from "../horse_variants/show/Show";
import HorseVariantsIndex from "../horse_variants/index/Index";
import HorseLocationShow from "../horse_locations/Show";
import HorseLocationsIndex from "../horse_locations/Index";
import PurchaseOrderNew from "../purchase_orders/New";
import PurchaseOrderShow from "../purchase_orders/Show";
import PurchaseOrdersIndex from "../purchase_orders/index/Index";
import TransferOrderNew from "../transfer_orders/New";
import TransferOrderShow from "../transfer_orders/Show";
import TransferOrdersIndex from "../transfer_orders/index/Index";
import InventoryHistoriesIndex from "../inventory_level_histories/Index";
import SalesTrendsIndex from "../sales_trends/Index";
import SupplierNew from "../suppliers/New";
import SupplierShow from "../suppliers/Show";
import SuppliersIndex from "../suppliers/Index";
import BundleNew from "../bundles/New";
import BundleShow from "../bundles/Show";
import BundlesIndex from "../bundles/Index";
import Settings from "../settings/Show";
import Login from "../sessions/new/New";
import App from "./App";

const router = createBrowserRouter([
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/",
    element: <App />,
    children: [
      {
        index: true,
        element: <HomeIndex />,
      },
      {
        path: "/home",
        element: <HomeIndex />,
      },
      {
        element: <HorseVariantShow />,
        path: "horse_variants/:id",
      },
      {
        element: <HorseVariantsIndex />,
        path: "horse_variants",
      },
      {
        element: <HorseLocationShow />,
        path: "horse_locations/:id",
      },
      {
        element: <HorseLocationsIndex />,
        path: "horse_locations",
      },
      {
        element: <PurchaseOrderNew />,
        path: "purchase_orders/new",
      },
      {
        element: <PurchaseOrderShow />,
        path: "purchase_orders/:id",
      },
      {
        element: <PurchaseOrdersIndex />,
        path: "purchase_orders",
      },
      {
        element: <TransferOrderNew />,
        path: "transfer_orders/new",
      },
      {
        element: <TransferOrderShow />,
        path: "transfer_orders/:id",
      },
      {
        element: <TransferOrdersIndex />,
        path: "transfer_orders",
      },
      {
        element: <InventoryHistoriesIndex />,
        path: "inventory_level_histories",
      },
      {
        element: <SalesTrendsIndex />,
        path: "sales_trends",
      },
      {
        element: <SupplierNew />,
        path: "suppliers/new",
      },
      {
        element: <SupplierShow />,
        path: "suppliers/:id",
      },
      {
        element: <SuppliersIndex />,
        path: "suppliers",
      },
      {
        element: <BundleNew />,
        path: "bundles/new",
      },
      {
        element: <BundleShow />,
        path: "bundles/:id",
      },
      {
        element: <BundlesIndex />,
        path: "bundles",
      },
      {
        element: <Settings />,
        path: "settings",
      },
    ],
  },
]);

export default function Router(): React.ReactElement {
  // window.addEventListener("message", (message) => {
  //   if (message?.data?.payload) {
  //     console.debug("Window message", message?.data?.payload);
  //   }
  // });

  return <RouterProvider router={router} />;
}
