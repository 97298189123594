import React from "react";
import { Link } from "@shopify/polaris";

export function AdjustedByLink({
  url,
  title,
}: {
  readonly url?: string;
  readonly title: string;
}): React.ReactElement | string {
  if (url?.startsWith("https://")) {
    const parsedUrl = new URL(url);
    // Remove the leading / from the pathname
    let pathname = parsedUrl.pathname.startsWith("/") ? parsedUrl.pathname.slice(1) : parsedUrl.pathname;
    if (pathname.startsWith("admin/")) {
      pathname = pathname.slice(6);
    }
    return (
      <a href={`shopify://admin/${pathname}`} target="_top">
        {title}
      </a>
    );
  } else if (url) {
    return <Link url={url}>{title}</Link>;
  } else {
    return title;
  }
}
