import React from "react";
import { BlockStack, FooterHelp, Link } from "@shopify/polaris";

export function Footer({ url, pageTitle }: { readonly url: string; readonly pageTitle: string }): React.ReactElement {
  return (
    <FooterHelp>
      <BlockStack gap="200" inlineAlign="center">
        <div>
          Learn more about{" "}
          <Link target="_blank" url={url}>
            {pageTitle}
          </Link>
        </div>
        <div>
          <Link target="_blank" url="mailto:hello@inventory.horse">
            Contact support
          </Link>
        </div>
      </BlockStack>
    </FooterHelp>
  );
}
