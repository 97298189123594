import React, { useState } from "react";
import {
  Form,
  FormLayout,
  TextField,
  Button,
  LegacyCard,
  ResourceList,
  ResourceItem,
  Text,
  Link,
  LegacyStack,
  TextContainer,
} from "@shopify/polaris";
import { saveCombineStore, updateUser, useCombineStores } from "../../../api_utils/requests";
import type { ShopifyShop } from "../../../api_utils/types";
import { extractMessageFromError } from "../../../helper_functions/utils";
import { EnableFullSyncModal } from "./EnableFullSyncModal";
import { CompleteConnectStore } from "./CompleteConnectStore";
import { CombineStoreRow } from "./CombineStoreRow";

export function NewShopForm({
  currentUser,
  shopifyShops,
}: {
  readonly currentUser: { id: number; syncLevel: string };
  readonly shopifyShops: ShopifyShop[];
}): React.JSX.Element {
  const [syncModalActive, setSyncModalActive] = useState(false);
  const [addedShopUrl, setAddedShopUrl] = useState("");
  const [errorMessage, setErrorMessage] = useState<string>();

  const connectStore = async (): Promise<void> => {
    const payload = {
      add_store: addedShopUrl,
    };
    await saveCombineStore(payload)
      .then(() => {
        setReadyToInstallHorse(true);
        void refetch();
      })
      .catch((err: unknown) => {
        Rollbar.error(err, payload);
        const message = extractMessageFromError(err);
        setErrorMessage(message);
      });
  };

  const checkUserSyncLevel = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    if (currentUser.syncLevel === "hybrid_sync") {
      event.preventDefault();
      setSyncModalActive(true);
    } else {
      await connectStore();
    }
  };

  const renderShopifyShop = (shopifyShop: ShopifyShop, id: string): React.ReactElement => (
    <ResourceItem
      accessibilityLabel={`Visit ${shopifyShop.shopify_domain}'s dashboard`}
      external
      id={id}
      url={`https://${shopifyShop.shopify_domain}/admin`}
    >
      <LegacyStack distribution="equalSpacing">
        <LegacyStack.Item>
          <h4>
            <Text as="strong">
              <Link className="blue-link" monochrome removeUnderline target="_blank">
                {shopifyShop.name}
              </Link>
            </Text>
          </h4>
          <Text tone="subdued">{shopifyShop.shopify_domain}</Text>
        </LegacyStack.Item>
      </LegacyStack>
    </ResourceItem>
  );

  const { data: combine_stores, refetch } = useCombineStores();

  const handleEnableFullSync = (): void => {
    const payload = {
      sync_level: "full_sync",
    };
    updateUser(currentUser.id, payload)
      .then(async (): Promise<void> => {
        setSyncModalActive(false);
        await connectStore();
      })
      .catch((err: unknown) => {
        Rollbar.error(err, payload);
        const message = extractMessageFromError(err);
        setErrorMessage(message);
      });
  };

  const [readyToInstallHorse, setReadyToInstallHorse] = useState(false);

  return (
    <>
      <EnableFullSyncModal
        active={syncModalActive}
        handleEnableFullSync={handleEnableFullSync}
        setActive={setSyncModalActive}
      />
      <CompleteConnectStore
        active={readyToInstallHorse}
        addedShopUrl={addedShopUrl}
        setActive={setReadyToInstallHorse}
      />
      <LegacyCard>
        <LegacyCard.Header title="Connect another store" />
        <LegacyCard.Section>
          <LegacyStack spacing="baseTight" vertical>
            <Form action="/combine_stores" autoComplete method="post" noValidate onSubmit={checkUserSyncLevel}>
              <div className="settings-form-container">
                <FormLayout>
                  <FormLayout.Group>
                    <TextField
                      autoComplete="on"
                      connectedRight={
                        <Button disabled={addedShopUrl.length < 3} size="large" submit variant="primary">
                          Connect
                        </Button>
                      }
                      error={errorMessage}
                      helpText={
                        <TextContainer>
                          If you receive the error &quot;Unable to access ...&quot;, please{" "}
                          <Link monochrome target="_blank" url="https://accounts.shopify.com/store-login">
                            login
                          </Link>{" "}
                          to the store you are trying to connect to, and try again.
                        </TextContainer>
                      }
                      inputMode="url"
                      label="Shopify store domain name"
                      name="add_store"
                      onChange={setAddedShopUrl}
                      placeholder="my-store.myshopify.com"
                      type="url"
                      value={addedShopUrl}
                    />
                  </FormLayout.Group>
                </FormLayout>
              </div>
            </Form>
          </LegacyStack>
        </LegacyCard.Section>
        <ResourceList
          headerContent="Stores pending installation"
          items={combine_stores || []}
          renderItem={(combine_store, id) => (
            <CombineStoreRow combine_store={combine_store} key={id} refetchCombineStore={refetch} />
          )}
          resourceName={{ singular: "shop", plural: "shops" }}
          showHeader
        />
        <ResourceList
          headerContent="Connected stores"
          items={shopifyShops || []}
          renderItem={renderShopifyShop}
          resourceName={{ singular: "shop", plural: "shops" }}
          showHeader
        />
      </LegacyCard>
    </>
  );
}
