import { Popover, ActionList, Button } from "@shopify/polaris";
import React, { useState, useCallback } from "react";

export default function PopoverWithActionListExample({
  comment,
  handleEdit,
  handleDelete,
}: {
  readonly comment: object;
  readonly handleEdit: (comment: object) => void;
  readonly handleDelete: (comment: object) => void;
}): React.JSX.Element {
  const [popoverActive, setPopoverActive] = useState<boolean>(false);

  const togglePopoverActive = useCallback(() => {
    setPopoverActive((exitingPopoverActive: boolean) => !exitingPopoverActive);
  }, []);
  const activator = <Button onClick={togglePopoverActive}>...</Button>;

  const handleEditAction = (): void => {
    setPopoverActive(false);
    handleEdit(comment);
  };

  const handleDeleteAction = (): void => {
    setPopoverActive(false);
    handleDelete(comment);
  };

  return (
    <Popover activator={activator} active={popoverActive} onClose={togglePopoverActive}>
      <ActionList
        actionRole="menuitem"
        items={[
          { content: "Edit comment", onAction: handleEditAction },
          { content: "Delete comment", onAction: handleDeleteAction },
        ]}
      />
    </Popover>
  );
}
