import React from "react";
import { Card, Layout, SkeletonBodyText, SkeletonPage } from "@shopify/polaris";

export function TransferOrdersIndexPageSkeleton(): React.JSX.Element {
  return (
    <SkeletonPage fullWidth primaryAction>
      <Layout>
        <Layout.Section>
          <Card>
            <SkeletonBodyText lines={5} />
          </Card>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );
}
