import React from "react";
import { LegacyCard, TextField, FormLayout } from "@shopify/polaris";
import { assertString } from "../../../../helper_functions/utils";
import type { IHorseVariant } from "../../../../api_utils/types";

export function Title({ horseVariant }: { readonly horseVariant: IHorseVariant }): JSX.Element {
  return (
    <LegacyCard sectioned title="Titles">
      <FormLayout>
        <TextField
          autoComplete="off"
          disabled
          label="Product title"
          type="text"
          value={assertString(horseVariant.title)}
        />
        <TextField
          autoComplete="off"
          disabled
          label="Variant title"
          type="text"
          value={assertString(horseVariant.variant_title)}
        />
      </FormLayout>
    </LegacyCard>
  );
}
