import React from "react";
import { Banner, Link } from "@shopify/polaris";

export function ErrorBanner({
  errorMessage,
  setErrorMessage,
}: {
  readonly errorMessage: string;
  readonly setErrorMessage: (errorMessage: string) => void;
}): React.ReactElement {
  const visible = !!errorMessage;

  return visible ? (
    <Banner
      onDismiss={() => {
        setErrorMessage("");
      }}
      title="Saving failed"
      tone="critical"
    >
      <br />
      <ul>
        {errorMessage.split("\n").map((line) => (
          <li key={line}>{line}</li>
        ))}
      </ul>
      <br />
      We have been automatically notified about the problem.
      <br />
      Still need help?{" "}
      <Link target="_blank" url="mailto:hello@inventory.horse">
        Email support
      </Link>{" "}
      or{" "}
      <Link target="_blank" url="tel:5149438996">
        call support
      </Link>
    </Banner>
  ) : null;
}
