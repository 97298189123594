import React, { useState, useCallback, useEffect, useMemo } from "react";
import qs from "query-string";
import type { SortButtonChoice, AppliedFilterInterface } from "@shopify/polaris";
import { Select, useSetIndexFiltersMode, IndexFiltersMode, IndexFilters } from "@shopify/polaris";
import { isEmpty, getCurrencySymbol, urlParamToDate } from "../../../helper_functions/utils";
import {
  allLocations,
  allSuppliers,
  allVendors,
  useHorseLocationsOptions,
  useHorseVariantVendorsOptions,
  useSuppliersSearchOptions,
  useUser,
} from "../../../api_utils/requests";
import CustomRangePicker from "../../common/CustomDatePicker/CustomRangePicker";
import MoreLessFilter from "../../common/Filters/MoreLessFilter";
import type { DateRangeFilter, DecimalRangeFilter, QueryParams } from "../../../api_utils/types";
import { dateLabel, moreThanLessThanLabel } from "../../common/Filters/label_tools";

const keys = {
  vendor: "horse-variants_vendor",
  location: "purchase_orders_location",
  purchaseCost: "purchase_orders_cost",
  receivedDate: "purchase_orders_received-date",
  supplierId: "purchase_orders_supplierId",
};

type LabelDisambiguation =
  | {
      key: "location";
      value: string;
      label?: never;
      prefix?: never;
      suffix?: never;
    }
  | {
      key: "purchaseCost";
      value: DecimalRangeFilter;
      label: string;
      prefix: string;
      suffix?: never;
    }
  | {
      key: "receivedDate";
      value: DateRangeFilter;
      label: string;
      prefix?: never;
      suffix?: never;
    }
  | {
      key: "vendor";
      value: string;
      label?: never;
      prefix?: never;
      suffix?: never;
    }
  | {
      key: "supplierId";
      value: string;
      label?: never;
      prefix?: never;
      suffix?: never;
    };

const disambiguateLabel = function ({ key, value, label, prefix, suffix }: LabelDisambiguation): string {
  switch (key) {
    case "vendor":
      return `Vendor is ${value}`;
    case "supplierId":
      return `Supplier is ${value}`;
    case "receivedDate":
      return dateLabel(value, label);
    case "location":
      return `Location is ${value}`;
    case "purchaseCost":
      return moreThanLessThanLabel(value, label, prefix, suffix);
    default:
      throw new Error(`Unknown key: ${key as any}`);
  }
};

const sortOptions = [
  { label: "Created at", directionLabel: "Oldest/Newest", value: "created_at asc" },
  { label: "Created at", directionLabel: "Newest/Oldest", value: "created_at desc" },
  { label: "Status", directionLabel: "A-Z", value: "state asc" },
  { label: "Status", directionLabel: "Z-A", value: "state desc" },
  { label: "Arrival date", directionLabel: "Latest/Earliest", value: "arrival_date asc" },
  { label: "Arrival date", directionLabel: "Earliest/Latest", value: "arrival_date desc" },
  { label: "Cost", directionLabel: "Small/Big", value: "cost asc" },
  { label: "Cost", directionLabel: "Big/Small", value: "cost desc" },
] as SortButtonChoice[];

const tabs = [
  {
    id: "purchase-orders-all",
    content: "All",
    accessibilityLabel: "All purchase orders result",
    panelID: "all-purchase-orders-tab-content",
    backendState: "",
  },
  {
    id: "purchase-orders-draft",
    content: "Draft",
    accessibilityLabel: "Draft purchase orders result",
    panelID: "draft-purchase-orders-tab-content",
    backendState: "draft",
  },
  {
    id: "purchase-orders-ordered",
    content: "Ordered",
    accessibilityLabel: "Ordered purchase orders result",
    panelID: "ordered-purchase-orders-tab-content",
    backendState: "ordered",
  },
  {
    id: "purchase-orders-closed",
    content: "Received",
    accessibilityLabel: "Closed purchase orders result",
    panelID: "closed-purchase-orders-tab-content",
    backendState: "closed",
  },
];

export function PurchaseOrdersSearchFieldAndMoreFilters({
  setFilters,
  onSelect,
  selected,
}: {
  readonly setFilters: (params: QueryParams) => void;
  readonly onSelect: (id: number) => void;
  readonly selected: number;
}): React.JSX.Element {
  const parsedUrlSearch = qs.parse(window.location.search, { arrayFormat: "bracket" });
  const curDateMonth = useMemo(() => new Date(), []);
  curDateMonth.setUTCHours(0, 0, 0, 0);

  const initSortedCol = (parsedUrlSearch["by_sort[column]"] as string) || "created_at";
  const initSortedColDir = (parsedUrlSearch["by_sort[direction]"] as string) || "desc";
  const [sort, setSort] = useState([`${initSortedCol} ${initSortedColDir}`]);

  const init: {
    location?: string;
    purchaseCost: DecimalRangeFilter;
    receivedDate: { start?: Date; end?: Date };
    search?: string;
    vendor?: string;
  } = {
    // initially selected values (coming from URL)
    location: parsedUrlSearch.horse_location as string,
    purchaseCost: {
      more_than: parsedUrlSearch["by_cost[more_than]"] as string,
      less_than: parsedUrlSearch["by_cost[less_than]"] as string,
    },
    receivedDate: {
      start: urlParamToDate(parsedUrlSearch["by_received_date[start]"]),
      end: urlParamToDate(parsedUrlSearch["by_received_date[end]"]),
    },
    search: parsedUrlSearch.search as string,
    vendor: parsedUrlSearch.vendor as string,
  };
  const [location, setLocation] = useState(init.location);
  const [purchaseCost, setPurchaseCost] = useState<DecimalRangeFilter>(init.purchaseCost);
  const [search, setSearch] = useState(init.search || "");
  const [selectedReceivedRange, setSelectedReceivedRange] = useState<DateRangeFilter>(init.receivedDate);
  const receivedDateIsSelected = !!selectedReceivedRange.start && !!selectedReceivedRange.end;
  const [vendor, setVendor] = useState<string>(init.vendor);
  const [supplierId, setSupplierId] = useState<string>(parsedUrlSearch.supplier_id as string);

  const {
    data: {
      user: { currency: userCurrency },
    },
  } = useUser();

  useEffect(() => {
    // TODO: test date filter!!!!

    const [column, direction] = sort[0].split(" ");

    const queryParams = {
      horse_location_id: location,
      supplier_id: supplierId,
      by_cost: purchaseCost,
      search: search,
      by_received_date: selectedReceivedRange,
      vendor,
      by_sort: { column, direction },
    };
    setFilters(queryParams);
  }, [
    location,
    purchaseCost,
    search,
    selectedReceivedRange,
    vendor,
    supplierId,
    sort,
    setFilters,
    receivedDateIsSelected,
  ]);

  const { data: locations } = useHorseLocationsOptions();
  const { data: vendors } = useHorseVariantVendorsOptions();
  const { data: suppliers } = useSuppliersSearchOptions();

  const handleVendorChange = useCallback((value: string) => {
    setVendor(value === allVendors ? undefined : value);
  }, []);
  const handleSupplierIdChange = useCallback((value: string) => {
    setSupplierId(value === allSuppliers ? undefined : value);
  }, []);

  const handleLocationChange = useCallback((value: string) => {
    setLocation(value === allLocations ? undefined : value);
  }, []);

  const handleReceivedMonthChange = setSelectedReceivedRange;

  const handleVendorRemove = useCallback(() => {
    setVendor(undefined);
  }, []);
  const handleSupplierIdRemove = useCallback(() => {
    setSupplierId(undefined);
  }, []);
  const handleLocationRemove = useCallback(() => {
    setLocation(undefined);
  }, []);
  const handlePurchaseCostRemove = useCallback((): void => {
    setPurchaseCost({ more_than: undefined, less_than: undefined });
  }, []);

  const handleReceivedDateRemove = useCallback(() => {
    setSelectedReceivedRange({
      start: undefined,
      end: undefined,
    });
  }, []);

  const handleSearchRemove = useCallback(() => {
    setSearch("");
  }, []);

  const handleFiltersClearAll = useCallback(() => {
    handleSearchRemove();
    handleLocationRemove();
    handleReceivedDateRemove();
    handleVendorRemove();
    handlePurchaseCostRemove();
  }, [
    handleSearchRemove,
    handleLocationRemove,
    handleReceivedDateRemove,
    handleVendorRemove,
    handlePurchaseCostRemove,
  ]);

  const filters = [
    {
      key: keys.vendor,
      label: "Vendor",
      filter: (
        <Select
          id="purchase-orders_vendor_select"
          label="Vendor"
          onChange={handleVendorChange}
          options={vendors}
          value={vendor || allVendors}
        />
      ),
    },
    {
      key: keys.supplierId,
      label: "Supplier",
      filter: (
        <Select
          id="purchase-orders_supplier_select"
          label="Supplier"
          onChange={handleSupplierIdChange}
          options={suppliers}
          value={supplierId || allSuppliers}
        />
      ),
    },
    {
      key: keys.location,
      label: "Destination",
      filter: (
        <Select
          id="inventory-level-histories_product-type_select"
          label="Vendor"
          onChange={handleLocationChange}
          options={locations}
          value={location || allLocations}
        />
      ),
    },
    {
      key: keys.receivedDate,
      label: "Arrival date",
      filter: <CustomRangePicker dateRange={selectedReceivedRange} setDateRange={handleReceivedMonthChange} />,
    },
    {
      key: keys.purchaseCost,
      label: "Cost",
      filter: (
        <MoreLessFilter
          align="right"
          less_than={purchaseCost.less_than}
          more_than={purchaseCost.more_than}
          prefix={getCurrencySymbol(userCurrency)}
          setStateCallback={setPurchaseCost}
          targetState={purchaseCost}
          type="currency"
        />
      ),
    },
  ];

  const appliedFilters: AppliedFilterInterface[] = [];
  if (!isEmpty(vendor)) {
    const key = keys.vendor;
    appliedFilters.push({
      key,
      label: disambiguateLabel({ key: "vendor", value: vendor }),
      onRemove: handleVendorRemove,
    });
  }
  if (!isEmpty(supplierId)) {
    const key = keys.supplierId;
    const supplierLabel: string | undefined =
      locations.length > 0 && suppliers.find((element) => element.value === supplierId).label;
    appliedFilters.push({
      key,
      label: disambiguateLabel({ key: "supplierId", value: supplierLabel }),
      onRemove: handleSupplierIdRemove,
    });
  }
  if (!isEmpty(location)) {
    const key = keys.location;
    const value: string | undefined =
      locations.length > 0 && locations.find((element) => element.value === location).label;
    appliedFilters.push({
      key,
      label: disambiguateLabel({ key: "location", value }),
      onRemove: handleLocationRemove,
    });
  }
  if (!isEmpty(purchaseCost.more_than) || !isEmpty(purchaseCost.less_than)) {
    const key = keys.purchaseCost;
    appliedFilters.push({
      key,
      label: disambiguateLabel({
        key: "purchaseCost",
        value: purchaseCost,
        label: "Cost",
        prefix: getCurrencySymbol(userCurrency),
      }),
      onRemove: handlePurchaseCostRemove,
    });
  }
  if (receivedDateIsSelected) {
    const key = keys.receivedDate;
    appliedFilters.push({
      key,
      label: disambiguateLabel({ key: "receivedDate", value: selectedReceivedRange, label: "Arrival date" }),
      onRemove: handleReceivedDateRemove,
    });
  }

  const { mode, setMode } = useSetIndexFiltersMode(parsedUrlSearch.search ? IndexFiltersMode.Filtering : undefined);

  return (
    <IndexFilters
      appliedFilters={appliedFilters}
      canCreateNewView={false}
      cancelAction={{
        onAction: () => {},
        disabled: false,
        loading: false,
      }}
      filters={filters}
      mode={mode}
      onClearAll={handleFiltersClearAll}
      onQueryChange={setSearch}
      onQueryClear={handleSearchRemove}
      onSelect={onSelect}
      onSort={setSort}
      queryPlaceholder="Search purchase orders"
      queryValue={search}
      selected={selected}
      setMode={setMode}
      sortOptions={sortOptions}
      sortSelected={sort}
      tabs={tabs}
    />
  );
}
