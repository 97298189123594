import React from "react";
import { LegacyCard, Select } from "@shopify/polaris";

export function CurrencyChange({
  currencies,
  handleCurrencyChange,
  currency,
}: {
  readonly currencies: string[];
  readonly handleCurrencyChange: (value: string) => void;
  readonly currency: string;
}): React.JSX.Element {
  return (
    <LegacyCard>
      <LegacyCard.Header title="Select currency" />
      <LegacyCard.Section>
        <Select
          helpText="The only options in this menu are the currencies from your store(s)."
          id="currencies"
          label=""
          labelHidden
          onChange={handleCurrencyChange}
          options={currencies}
          value={currency}
        />
      </LegacyCard.Section>
    </LegacyCard>
  );
}
