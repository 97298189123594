import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Page, LegacyCard, Layout, Badge, TextField, InlineStack, BlockStack, Text } from "@shopify/polaris";
import { UpdateOrCreateHorseInventoryLevel } from "../../../api_utils/requests";
import { updateHorseVariant, updateHorseInventoryLevels, getHorseVariant } from "../../../api_utils/requests";
import { ProfilePicture } from "./support/ProfilePicture";
import { Inventory } from "./support/Inventory";
import { Pricing } from "./support/Pricing";
import { ProductOrganization } from "./support/ProductOrganization";
import { LinkedVariants } from "./support/LinkedVariants";
import { Quantity } from "./support/Quantity";
import TagCheckList from "../../common/Filters/TagCheckList";
import { Title } from "./support/Title";
import { Weight } from "./support/Weight";
import { ProductAvailability } from "./support/ProductAvailability";
import { ErrorBanner } from "../../common/ErrorBanner";
import { Footer } from "../../common/Footer";
import SettingToggle from "../../common/SettingToggle";
import type { IHorseVariant, IUpdateOrCreateHorseInventoryLevel, ShopifyVariant } from "../../../api_utils/types";
import { HorseVariantShowPageSkeleton } from "../../common/skeletons";
import { HORSE_VARIANT_HELP_PAGE_URL } from "../../../constants";
import {
  assertNumber,
  assertString,
  extractMessageFromError,
  getIdFromPath,
  softAssertNumber,
} from "../../../helper_functions/utils";
import SaveBarWithConfirmation from "../../common/SaveBarWithConfirmation";

function HorseVariant(): React.JSX.Element {
  const horseVariantId = useMemo(() => getIdFromPath(), []);

  const [pageLoading, setPageLoading] = useState(true);
  const [hvIsDirty, setHvIsDirty] = useState(false);
  const [hilIsDirty, setHilIsDirty] = useState(false);
  const [horseVariant, setHorseVariant] = useState<IHorseVariant>();
  const [originalHorseVariant, setOriginalHorseVariant] = useState<IHorseVariant>();
  const [hils, setHils] = useState<UpdateOrCreateHorseInventoryLevel[]>([]);
  const [originalHils, setOriginalHils] = useState<IUpdateOrCreateHorseInventoryLevel[]>([]);
  const [shopifyVariants, setShopifyVariants] = useState<ShopifyVariant[]>([]);
  const [selectedReason, setSelectedReason] = useState("correction");
  const [stockUpdateComment, setStockUpdateComment] = useState("");
  const [availability, setAvailability] = useState<
    { shopify_domain: string; friendly_name: string; available: boolean }[]
  >([]);

  const [availableQuantities, setAvailableQuantities] = useState<Record<string, string>>(
    hils.reduce((map, hil) => {
      map[hil.updateOrCreateId] = assertString(hil.available);
      return map;
    }, {}),
  );

  useEffect(() => {
    setAvailableQuantities(
      hils.reduce((map, hil) => {
        map[hil.updateOrCreateId] = assertString(hil.available);
        return map;
      }, {}),
    );
  }, [hils]);

  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    getHorseVariant(horseVariantId)
      .then(
        ({
          horseVariant: updatedHorseVariant,
          shopifyVariants: updatedShopifyVariants,
          horseInventoryLevels,
          availability: updatedAvailability,
        }) => {
          setHorseVariant(updatedHorseVariant);
          setOriginalHorseVariant(updatedHorseVariant);
          setShopifyVariants(updatedShopifyVariants);
          setHils((horseInventoryLevels || []).map((hil) => new UpdateOrCreateHorseInventoryLevel(hil)));
          setOriginalHils(horseInventoryLevels || []);
          setAvailability(updatedAvailability);
          setPageLoading(false);
        },
      )
      .catch((error: unknown) => {
        console.error("Error fetching data:", error);
      });
  }, [horseVariantId]);

  const handleSave = (): void => {
    if (hilIsDirty) {
      const payload = {
        horse_variant_id: horseVariant.id,
        horse_inventory_levels: hils.map((hil) => {
          return {
            ...hil,
            available: hil.id
              ? assertNumber(availableQuantities[hil.updateOrCreateId])
              : softAssertNumber(availableQuantities[hil.updateOrCreateId]),
          };
        }),
        tracked: horseVariant.tracked,
        reason: selectedReason,
        comment: stockUpdateComment,
      };
      updateHorseInventoryLevels(payload)
        .then((newHils) => {
          shopify.toast.show("Save successful");
          setHils((newHils || []).map((hil) => new UpdateOrCreateHorseInventoryLevel(hil)));
          setOriginalHils(newHils || []);
          setHilIsDirty(false);
          setSelectedReason("correction");
          setStockUpdateComment("");
        })
        .catch((err: unknown) => {
          Rollbar.error(err, payload);
          const newErrorMessage = extractMessageFromError(err);
          setErrorMessage(newErrorMessage);
          setHilIsDirty(true);
        });
    }

    if (hvIsDirty) {
      updateHorseVariant(horseVariant.id, horseVariant)
        .then((updatedHorseVariant) => {
          shopify.toast.show("Save successful");
          setHorseVariant(updatedHorseVariant);
          setOriginalHorseVariant(updatedHorseVariant);
          setHvIsDirty(false);
        })
        .catch((err: unknown) => {
          Rollbar.error(err, horseVariant);
          const newErrorMessage = extractMessageFromError(err);
          setErrorMessage(newErrorMessage);
          setHvIsDirty(true);
        });
    }
  };

  const handleDiscard = (): void => {
    setHorseVariant(originalHorseVariant);
    setHils((originalHils || []).map((hil) => new UpdateOrCreateHorseInventoryLevel(hil)));
    setHvIsDirty(false);
    setHilIsDirty(false);
  };

  const toggleRecommendations = useCallback(() => {
    setHorseVariant((prevHorseVariant) => ({
      ...prevHorseVariant,
      recommendations_enabled: !prevHorseVariant.recommendations_enabled,
    }));
    setHvIsDirty(true);
  }, []);

  const handleThreshold = (value: string, type: "min"): void => {
    // Convert the value to a number
    const numericValue = value ? parseFloat(value) : 0;
    setHorseVariant((prevHorseVariant) => ({
      ...prevHorseVariant,
      [type]: numericValue,
    }));
    setHvIsDirty(true);
  };

  const pageMarkup = horseVariant && (
    <Page
      backAction={{
        url: "/horse_variants",
      }}
      subtitle={horseVariant.variant_title}
      title={horseVariant.title}
      titleMetadata={horseVariant.discarded_at ? <Badge tone="warning">Archived</Badge> : undefined}
    >
      <SaveBarWithConfirmation
        handleDiscard={handleDiscard}
        handleSave={handleSave}
        isDirty={hvIsDirty || hilIsDirty}
      />
      <Layout>
        {errorMessage ? (
          <Layout.Section variant="fullWidth">
            <ErrorBanner errorMessage={errorMessage} setErrorMessage={setErrorMessage} />
          </Layout.Section>
        ) : null}
        <Layout.Section variant="oneThird">
          <ProfilePicture horseVariant={horseVariant} />
          <Title horseVariant={horseVariant} />
          <Pricing
            cost={horseVariant.cost}
            horseVariant={horseVariant}
            setCost={(value) => {
              setHvIsDirty(true);
              setHorseVariant({
                ...horseVariant,
                cost: value,
              });
            }}
          />
          <ProductOrganization horseVariant={horseVariant} />
          <Weight
            height={horseVariant.height?.toString()}
            length={horseVariant.length?.toString()}
            setHeight={(value: string) => {
              setHorseVariant({
                ...horseVariant,
                height: softAssertNumber(value),
              });
            }}
            setIsDirty={setHvIsDirty}
            setLength={(value: string) => {
              setHorseVariant({
                ...horseVariant,
                length: softAssertNumber(value),
              });
            }}
            setWeight={(value: string) => {
              setHorseVariant({
                ...horseVariant,
                weight: softAssertNumber(value),
              });
            }}
            setWidth={(value: string) => {
              setHorseVariant({
                ...horseVariant,
                width: softAssertNumber(value),
              });
            }}
            weight={horseVariant.weight?.toString()}
            width={horseVariant.width?.toString()}
          />
          <LegacyCard title="Tags">
            <LegacyCard.Section>
              <TagCheckList disabled selectedOptions={horseVariant.tags} />
            </LegacyCard.Section>
          </LegacyCard>
        </Layout.Section>

        <Layout.Section variant="oneHalf">
          <Inventory
            horseVariant={horseVariant}
            setWarehouseLocation={(value: string): void => {
              setHorseVariant({
                ...horseVariant,
                warehouse_location: value,
              });
              setHvIsDirty(true);
            }}
          />

          <LegacyCard>
            <LegacyCard.Section>
              <BlockStack gap="300">
                <SettingToggle
                  description="Horse will always recommend ordering more of this product when it is forecast to go out of stock soon."
                  enabled={horseVariant.recommendations_enabled}
                  handleToggle={toggleRecommendations}
                  title="Purchase order recommendations"
                />
                <div>
                  <Text as="h6" variant="headingSm">
                    Manual target quantity
                  </Text>
                  <Text as="p" tone="subdued" variant="bodyMd">
                    In addition to Horse&apos;s forecasting recommendations, recommend this product when the inventory
                    is below this amount
                  </Text>
                </div>
                <InlineStack gap="300">
                  <TextField
                    align="right"
                    autoComplete="off"
                    label="Always have at least"
                    min="0"
                    onChange={(value: string) => {
                      handleThreshold(value, "min");
                    }}
                    suffix="pcs"
                    type="number"
                    value={String(horseVariant.min)}
                  />
                </InlineStack>
              </BlockStack>
            </LegacyCard.Section>
          </LegacyCard>

          <LinkedVariants shopifyVariants={shopifyVariants} />

          <Quantity
            availableQuantities={availableQuantities}
            hils={hils}
            horseVariant={horseVariant}
            selectedReason={selectedReason}
            setAvailableQuantities={(updatedAvailableQuantities): void => {
              setHilIsDirty(true);
              setAvailableQuantities(updatedAvailableQuantities);
            }}
            setSelectedReason={(value: string): void => {
              setSelectedReason(value);
            }}
            setStockUpdateComment={(value: string): void => {
              setStockUpdateComment(value);
            }}
            setTracked={(value: boolean): void => {
              setHilIsDirty(true);
              setHorseVariant({
                ...horseVariant,
                tracked: value,
              });
            }}
            stockUpdateComment={stockUpdateComment}
          />
          <ProductAvailability channels={availability} />
        </Layout.Section>
      </Layout>
      <Footer pageTitle="variants" url={HORSE_VARIANT_HELP_PAGE_URL} />
    </Page>
  );

  return pageLoading ? <HorseVariantShowPageSkeleton /> : pageMarkup;
}

export default HorseVariant;
