import React from "react";
import { BlockStack, Card, Layout, SkeletonBodyText, SkeletonDisplayText, SkeletonPage } from "@shopify/polaris";

export function PurchaseOrderDraftPageSkeleton(): React.JSX.Element {
  return (
    <SkeletonPage primaryAction>
      <Layout>
        <Layout.Section variant="oneHalf">
          <Card>
            <SkeletonBodyText lines={5} />
          </Card>
        </Layout.Section>
        <Layout.Section variant="oneHalf">
          <Card>
            <SkeletonBodyText lines={10} />
          </Card>
        </Layout.Section>
        <Layout.Section variant="fullWidth">
          <Card>
            <BlockStack gap="400">
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText lines={10} />
            </BlockStack>
          </Card>
        </Layout.Section>
        <Layout.Section variant="fullWidth">
          <Card>
            <SkeletonBodyText lines={10} />
          </Card>
        </Layout.Section>
        <Layout.Section variant="oneThird">
          <Card>
            <BlockStack gap="400">
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText lines={5} />
            </BlockStack>
          </Card>
        </Layout.Section>
        <Layout.Section variant="oneThird">
          <div />
        </Layout.Section>
        <Layout.Section variant="oneThird">
          <Card>
            <BlockStack gap="400">
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText lines={5} />
            </BlockStack>
          </Card>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );
}
