import React, { useMemo, useState } from "react";
import {
  LegacyCard,
  Page,
  Badge,
  TextField,
  Label,
  FormLayout,
  PageActions,
  IndexTable,
  Layout,
  Button,
} from "@shopify/polaris";
import CustomDatePicker from "../common/CustomDatePicker/CustomDatePicker";
import { deleteTransferOrder, downloadFile, updateTransferOrder } from "../../api_utils/requests";
import DeleteConfirmationDialog from "../common/DeleteConfirmationDialog";
import HorseVariant from "../common/HorseVariant/HorseVariant";
import { ErrorBanner } from "../common/ErrorBanner";
import { Footer } from "../common/Footer";
import { assertNumber, extractMessageFromError, humanDateFormat } from "../../helper_functions/utils";
import { TRANSFER_ORDER_HELP_PAGE_URL } from "../../constants";
import type { HorseLocation, TransferOrder, TransferOrderLineItem } from "../../api_utils/types";
import type { NonEmptyArray } from "@shopify/polaris/build/ts/src/types";
import type { IndexTableHeading } from "@shopify/polaris/build/ts/src/components/IndexTable";
import SaveBarWithConfirmation from "../common/SaveBarWithConfirmation";

const columnNames = [
  { title: "Variant" },
  { title: "Sent" },
  { title: "Received" },
] satisfies NonEmptyArray<IndexTableHeading>;

const Index = ({
  origin,
  destination,
  transferOrder,
  lineItems,
}: {
  origin: HorseLocation;
  destination: HorseLocation;
  transferOrder: TransferOrder;
  lineItems: TransferOrderLineItem[];
}): React.ReactElement => {
  const today = useMemo(() => new Date(), []);

  const estimatedArrivalDate = transferOrder?.arrived_date ? new Date(transferOrder?.arrived_date) : undefined;
  const [label, setLabel] = useState(transferOrder.label || "");
  const [isDirty, setIsDirty] = useState(false);
  const [deletionDialogueActive, setDeletionDialogueActive] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");

  const handleLabelChange = (newLabel: string) => {
    setLabel(newLabel);
    setIsDirty(true);
  };

  const handleDiscardAction = () => {
    setIsDirty(false);
    setLabel(transferOrder.label || "");
  };

  const handleSaveAction = () => {
    const payload = { label };
    updateTransferOrder(transferOrder.id, payload)
      .then(() => {
        shopify.toast.show("Save successful");
      })
      .catch((err: unknown) => {
        Rollbar.error(err, payload);
        const message = extractMessageFromError(err);
        setErrorMessage(message);
      })
      .finally(() => {
        setIsDirty(false);
      });
  };

  const total = lineItems.reduce(
    (acc, item) => {
      acc.quantity_sent += assertNumber(item.quantity_sent);
      acc.quantity_received += assertNumber(item.quantity_received);
      return acc;
    },
    { quantity_received: 0, quantity_sent: 0 },
  );

  const pageMarkup = transferOrder && (
    <Page
      backAction={{
        content: "Closed transfer order",
        url: "/transfer_orders",
      }}
      primaryAction={
        <Button
          onClick={async (): Promise<void> => {
            const transferOrderName = transferOrder.label || transferOrder.id;
            const filename = `Horse Transfer Order ${transferOrderName}.csv`;
            await downloadFile(`/transfer_orders/${transferOrder.id}.csv${window.location.search}`, filename);
          }}
          removeUnderline
          size="medium"
          variant="monochromePlain"
        >
          Export CSV
        </Button>
      }
      title="Closed transfer order"
      titleMetadata={<Badge tone="success">Received</Badge>}
    >
      <SaveBarWithConfirmation handleDiscard={handleDiscardAction} handleSave={handleSaveAction} isDirty={isDirty} />

      <Layout>
        {errorMessage ? (
          <Layout.Section variant="fullWidth">
            <ErrorBanner errorMessage={errorMessage} setErrorMessage={setErrorMessage} />
          </Layout.Section>
        ) : null}
        <Layout.Section variant="oneThird">
          <LegacyCard>
            <LegacyCard.Section title="Origin">
              <Label id="to_origin_name">{origin.name}</Label>
            </LegacyCard.Section>
            <LegacyCard.Section title="Destination">
              <Label id="to_destination_name">{destination.name}</Label>
            </LegacyCard.Section>
            <LegacyCard.Section>
              <FormLayout>
                <FormLayout.Group>
                  <TextField
                    autoComplete="off"
                    label="Label"
                    onChange={handleLabelChange}
                    placeholder="64568793"
                    value={label}
                  />
                </FormLayout.Group>
                <FormLayout.Group>
                  <CustomDatePicker
                    date={estimatedArrivalDate || today}
                    disabled
                    label="Arrival date"
                    placeholder={humanDateFormat(today)}
                    setDate={() => {}}
                  />
                </FormLayout.Group>
              </FormLayout>
            </LegacyCard.Section>
          </LegacyCard>
        </Layout.Section>
        <Layout.Section>
          <LegacyCard>
            <IndexTable headings={columnNames} itemCount={lineItems.length} selectable={false}>
              <IndexTable.Row id="transferOrdersReceivedTotalsRow" key={0} position={0} rowType="subheader">
                <IndexTable.Cell>Totals</IndexTable.Cell>
                <IndexTable.Cell>
                  <div
                    key="total_quantity_sent"
                    style={{ display: "flex", alignItems: "start", justifyContent: "start" }}
                  >
                    {total.quantity_sent}
                  </div>
                </IndexTable.Cell>
                <IndexTable.Cell>
                  <div
                    key="total_quantity_received"
                    style={{ display: "flex", alignItems: "start", justifyContent: "start" }}
                  >
                    {total.quantity_received}
                  </div>
                </IndexTable.Cell>
              </IndexTable.Row>
              {lineItems.map((variant, index) => (
                <IndexTable.Row id={variant.id.toString()} key={variant.id} position={index + 1}>
                  <IndexTable.Cell>
                    <HorseVariant key={variant.id} variant={variant} />
                  </IndexTable.Cell>
                  <IndexTable.Cell>
                    <div key={variant.id}>{variant.quantity_sent}</div>
                  </IndexTable.Cell>
                  <IndexTable.Cell>
                    <div key={variant.id}>{variant.quantity_received}</div>
                  </IndexTable.Cell>
                </IndexTable.Row>
              ))}
            </IndexTable>
          </LegacyCard>
        </Layout.Section>
      </Layout>
      <PageActions
        secondaryActions={[
          {
            content: "Delete",
            destructive: true,
            onAction: () => {
              setDeletionDialogueActive(true);
            },
          },
        ]}
      />
      <DeleteConfirmationDialog
        active={deletionDialogueActive}
        content="Are you sure you want to delete this transfer? This action cannot be reversed."
        deleteItem={deleteTransferOrder}
        gotoPath="/transfer_orders"
        itemId={transferOrder.id}
        setActive={setDeletionDialogueActive}
        title="Delete transfer"
        toastMessage="Transfer order deleted successfully"
      />
      <Footer pageTitle="transfer orders" url={TRANSFER_ORDER_HELP_PAGE_URL} />
    </Page>
  );

  return pageMarkup;
};

export default Index;
