import React from "react";
import {
  LegacyCard,
  LegacyStack,
  TextField,
  Link,
  Checkbox,
  Select,
  InlineGrid,
  Label,
  BlockStack,
} from "@shopify/polaris";
import QueryString from "qs";
import type { IHorseVariant } from "../../../../api_utils/types";
import type { UpdateOrCreateHorseInventoryLevel } from "../../../../api_utils/requests";

export function Quantity({
  hils,
  availableQuantities,
  setAvailableQuantities,
  horseVariant,
  setTracked,
  selectedReason,
  setSelectedReason,
  stockUpdateComment,
  setStockUpdateComment,
}: {
  readonly hils: UpdateOrCreateHorseInventoryLevel[];
  readonly availableQuantities: Record<number, string>;
  readonly setAvailableQuantities: (updatedAvailableQuantities: Record<string, string>) => void;
  readonly horseVariant: IHorseVariant;
  readonly setTracked: (tracked: boolean) => void;
  readonly selectedReason: string;
  readonly setSelectedReason: (value: string) => void;
  readonly stockUpdateComment: string;
  readonly setStockUpdateComment: (value: string) => void;
}): JSX.Element {
  // 'hils' stands for 'HorseInventoryLevels'
  // link to 'Inventory History' tab that shows only data for this horseVariant
  const inventoryLevelHistoriesUriParams = {
    horse_variant_id: horseVariant.id,
    collection: horseVariant.discarded ? 1 : 0,
  };
  const inventoryLevelHistoriesUri = `/inventory_level_histories?${QueryString.stringify(
    inventoryLevelHistoriesUriParams,
  )}`;

  const handleChange = (newAvailable: string, updateOrCreateId: string): void => {
    const cleanAvailable = newAvailable?.replace(/[^\d.-]/g, "");
    setAvailableQuantities({ ...availableQuantities, [updateOrCreateId]: cleanAvailable });
  };

  const options = [
    { label: "Correction (default)", value: "correction" },
    { label: "Count", value: "count_inventory" },
    { label: "Received", value: "received" },
    { label: "Return restock", value: "return_restock" },
    { label: "Damaged", value: "damaged" },
    { label: "Theft or loss", value: "theft_or_loss" },
    { label: "Promotion or donation", value: "promotion_or_donation" },
    { label: "Other", value: "other" },
  ];

  return (
    <LegacyCard sectioned>
      <div style={{ marginBottom: "10px" }}>
        <LegacyStack distribution="equalSpacing">
          <LegacyStack.Item>
            <h2 className="Polaris-Heading">Quantity</h2>
          </LegacyStack.Item>
          <LegacyStack.Item>
            <Link url={inventoryLevelHistoriesUri}>Show history</Link>
          </LegacyStack.Item>
        </LegacyStack>
      </div>
      <LegacyCard.Section>
        <Checkbox checked={horseVariant.tracked} label="Track quantity" onChange={setTracked} />
      </LegacyCard.Section>
      {hils.map((hil) => (
        <LegacyCard.Section key={`${hil.updateOrCreateId}-quantity`}>
          <LegacyStack alignment="baseline" distribution="fillEvenly">
            <h4>
              <Link url={`/horse_locations/${hil.horse_location_id}`}>{hil.horse_location.name}</Link>
            </h4>
            <TextField
              autoComplete="off"
              disabled={!horseVariant.tracked}
              inputMode="numeric"
              label=""
              labelHidden
              onChange={(newAvailable: string) => {
                handleChange(newAvailable, hil.updateOrCreateId);
              }}
              type="integer"
              // Polaris TextField's onChange prop function automatically
              // returns changed string (i.e. newAvailable)
              value={
                horseVariant.tracked && availableQuantities[hil.updateOrCreateId]
                  ? availableQuantities[hil.updateOrCreateId]
                  : ""
              }
            />
          </LegacyStack>
        </LegacyCard.Section>
      ))}
      <LegacyCard.Section>
        <BlockStack gap="200">
          <Select label="Reason" onChange={setSelectedReason} options={options} value={selectedReason} />
          <InlineGrid>
            <Label id="stock-comment">Comment</Label>
            <TextField
              autoComplete="off"
              inputMode="text"
              label="test"
              labelHidden
              multiline={2}
              onChange={(value: string) => {
                setStockUpdateComment(value);
              }}
              selectTextOnFocus
              type="text"
              value={stockUpdateComment}
            />
          </InlineGrid>
        </BlockStack>
      </LegacyCard.Section>
    </LegacyCard>
  );
}
