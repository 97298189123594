import React from "react";
import { Modal } from "@shopify/polaris";
import type { InvalidateQueryFilters } from "@tanstack/react-query";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

function DeleteConfirmationDialog({
  itemId,
  deleteItem,
  gotoPath,
  title,
  content,
  active,
  setActive,
  toastMessage,
  invalidateQueries,
  from = null,
  refetch,
}: {
  readonly itemId: number;
  readonly from?: number;
  readonly deleteItem: (itemId: number, from?: number) => Promise<any>;
  readonly gotoPath: string;
  readonly title: string;
  readonly content: string;
  readonly active: boolean;
  readonly setActive: (newState: boolean) => void;
  readonly toastMessage: string;
  readonly invalidateQueries?: InvalidateQueryFilters;
  readonly refetch?: () => Promise<any>;
}): React.JSX.Element {
  const queryClient = useQueryClient();
  const handleClose = (): void => {
    setActive(false);
  };

  const navigate = useNavigate();

  const handleDelete = async (): Promise<void> => {
    setActive(false);
    if (!from) {
      await deleteItem(itemId).then(async () => {
        if (refetch) {
          await refetch();
        }
        if (invalidateQueries) {
          await queryClient.invalidateQueries(invalidateQueries);
        }
        setTimeout(() => {
          shopify.toast.show(toastMessage);
          navigate(gotoPath);
        });
      });
    } else {
      await deleteItem(from, itemId).then(async () => {
        if (refetch) {
          await refetch();
        }
        if (invalidateQueries) {
          await queryClient.invalidateQueries(invalidateQueries);
        }
        setTimeout(() => {
          shopify.toast.show(toastMessage);
          navigate(gotoPath);
        });
      });
    }
  };

  return (
    <Modal
      onClose={handleClose}
      open={active}
      primaryAction={{
        content: "Delete",
        destructive: true,
        onAction: handleDelete,
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: handleClose,
        },
      ]}
      title={title}
    >
      <Modal.Section>{content}</Modal.Section>
    </Modal>
  );
}

export default DeleteConfirmationDialog;
