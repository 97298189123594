import React, { useCallback } from "react";
import { Modal } from "@shopify/polaris";
import { SaveBar } from "@shopify/app-bridge-react";
import { useBlocker } from "react-router-dom";

function SaveBarWithConfirmation({
  handleSave,
  handleDiscard,
  isDirty,
}: {
  readonly handleSave: () => void;
  readonly handleDiscard: () => void;
  readonly isDirty: boolean;
}): React.ReactElement {
  const blocker = useBlocker((): boolean => {
    return isDirty;
  });

  const closeModal = useCallback(() => {
    blocker.reset();
  }, [blocker]);

  const handleNavigation = useCallback(() => {
    void shopify.saveBar.hide("save-bar");
    blocker.proceed();
  }, [blocker]);

  return (
    <>
      <Modal
        onClose={closeModal}
        open={blocker.state === "blocked"}
        primaryAction={{
          content: "Leave page",
          onAction: handleNavigation,
          destructive: true,
        }}
        secondaryActions={[
          {
            content: "Cancel",
            onAction: closeModal,
          },
        ]}
        title="You have unsaved changes"
      >
        <Modal.Section>If you leave this page, all unsaved changes will be lost.</Modal.Section>
      </Modal>
      <SaveBar id="save-bar" open={isDirty}>
        <button
          onClick={handleSave}
          type="button"
          // eslint-disable-next-line react/no-unknown-property
          variant="primary"
        />
        <button onClick={handleDiscard} type="button" />
      </SaveBar>
    </>
  );
}

export default SaveBarWithConfirmation;
