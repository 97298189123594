import React from "react";
import { BlockStack, Card, Layout, SkeletonBodyText, SkeletonPage } from "@shopify/polaris";

export function HorseLocationShowPageSkeleton(): React.JSX.Element {
  return (
    <SkeletonPage>
      <Layout>
        <Layout.Section>
          <BlockStack gap="400">
            <Card>
              <SkeletonBodyText lines={3} />
            </Card>
            <Card>
              <SkeletonBodyText lines={3} />
            </Card>
            <Card>
              <SkeletonBodyText lines={3} />
            </Card>
          </BlockStack>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );
}
