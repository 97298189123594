import React from "react";
import { LegacyStack } from "@shopify/polaris";
import placeholderImg from "images/placeholder.png";
import ProductCaption from "./ProductCaption";

export default function HorseVariant({
  variant,
  showAvailable = false,
  disableClick = false,
}: {
  readonly variant: {
    id?: number;
    currently_available?: number;
    horse_variant_id?: number;
    image_src: string;
    product_title: string;
    product_type: string;
    sku: string;
    tracked?: boolean;
    variant_title: string;
    vendor: string;
  };
  readonly showAvailable?: boolean;
  readonly disableClick?: boolean;
}): React.ReactElement {
  const horseVariant = {
    ...variant,
    id: variant.horse_variant_id || variant.id,
  };
  let imgSrc = placeholderImg;
  if (variant.image_src) {
    imgSrc = `${horseVariant.image_src}&height=48`;
  }
  return (
    <div className="horse-variant" style={{ overflow: "hidden", maxWidth: showAvailable ? "100%" : null }}>
      <LegacyStack alignment="center" key={horseVariant.id} spacing="none" wrap={false}>
        <LegacyStack.Item>
          <span className="Polaris-Thumbnail">
            <img alt={horseVariant.product_title} className="product-photo" loading="lazy" src={imgSrc} />
          </span>
          <div id="PolarisPortalsContainer" />
        </LegacyStack.Item>
        <LegacyStack.Item fill>
          <ProductCaption disableClick={disableClick} showAvailable={showAvailable} variant={horseVariant} />
        </LegacyStack.Item>
      </LegacyStack>
    </div>
  );
}
