const sessionTokenEvent = new Event("sessionToken");

const SESSION_TOKEN_REFRESH_INTERVAL = 2000; // Request a new token every 2s

const maintain_token = (): void => {
  async function retrieveToken(): Promise<string> {
    const sessionToken = await shopify.idToken();
    window.sessionToken = sessionToken;
    // console.log("TOKEN", window.sessionToken);
    document.dispatchEvent(sessionTokenEvent);
    return sessionToken;
  }

  // We must refresh the token every 2 minutes!
  setInterval(retrieveToken, SESSION_TOKEN_REFRESH_INTERVAL);

  // Return initial token retrieval
  window.initialSessionTokenPromise = retrieveToken();
};

document.addEventListener("DOMContentLoaded", () => {
  const body: Element | undefined = document.getElementsByTagName("body")[0];
  const host: string = new URLSearchParams(location.search).get("host") || body?.getAttribute("data-host");
  let apiKey = body?.getAttribute("data-api-key");
  apiKey ||= document.querySelector('meta[name="shopify-api-key"]').getAttribute("content");

  if (!host) {
    console.error("No host provided");
    return;
  }

  maintain_token();
});
