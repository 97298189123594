import React from "react";

import { Text, Tooltip } from "@shopify/polaris";
import type { IHorseVariant } from "../../../api_utils/types";
import { formattedSalesRate } from "../../../helper_functions/utils";

export default function NiceSalesRate({
  row,
  tooltip = true,
}: {
  readonly row: IHorseVariant | number;
  readonly tooltip?: boolean;
}): React.JSX.Element | string {
  let calculation = "";
  let sales_rate: number;
  let discarded: boolean;
  if (typeof row === "object") {
    calculation = `Calculation: ${row.sales_quantity} sales / ${row.available_days} available days`;
    ({ sales_rate, discarded } = row);
  } else {
    // for total sales rate we pass only a number not a row
    calculation = "Total sales quantity / total available days";
    sales_rate = row;
    discarded = false;
  }
  const salesRate = formattedSalesRate({ sales_rate });
  if (tooltip) {
    return (
      <Tooltip
        active={false}
        content={`While the variant was available for purchase, \
              it sold at an average rate of \
              ${salesRate}. ${calculation}`}
        preferredPosition="above"
      >
        <Text as="strong" tone={discarded ? "subdued" : undefined}>
          {salesRate}
        </Text>
      </Tooltip>
    );
  } else {
    return salesRate;
  }
}
