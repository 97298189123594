import React from "react";
import { Tooltip } from "@shopify/polaris";

export function TruncatedText({
  text,
  maxLength,
  children,
}: {
  readonly text?: string;
  readonly maxLength: number;
  readonly children: React.ReactNode;
}): React.ReactNode {
  if (text && text.length > maxLength) {
    return (
      <Tooltip content={text} preferredPosition="above">
        {children}
      </Tooltip>
    );
  }
  return children;
}
