import React from "react";
import weightedAverageFormula from "images/weighted-average-formula.svg";
import { LegacyCard, LegacyStack, RadioButton, List } from "@shopify/polaris";
import type { User } from "../../../api_utils/types";

export function CostSyncOptions({
  costsyncSetting,
  handleSyncOptionChange,
  handleCostAdjustment,
  user,
}: {
  readonly costsyncSetting: string;
  readonly handleSyncOptionChange: (newValue: boolean, id: string) => void;
  readonly handleCostAdjustment: (_checked: boolean, newValue: string) => void;
  readonly user: User;
}): React.JSX.Element {
  const cost_adjustment_options = [
    { id: "yes", label: "Yes", value: true },
    { id: "no", label: "No", value: false },
  ];

  return (
    <LegacyCard>
      <LegacyCard.Header title="Select cost sync mode" />
      <LegacyCard.Section>How should variant cost be synced to Shopify?</LegacyCard.Section>
      <LegacyCard.Section>
        <LegacyStack vertical>
          <RadioButton
            checked={costsyncSetting === "latest"}
            helpText={
              <List type="bullet">
                <List.Item>Horse will sync the latest variant cost from a purchase order to Shopify.</List.Item>
              </List>
            }
            id="latest"
            label="Latest"
            onChange={handleSyncOptionChange}
          />
          <RadioButton
            checked={costsyncSetting === "weighted_average"}
            helpText={
              <>
                <List type="bullet">
                  <List.Item>
                    Horse will calculate a weighted average cost of your current inventory and new inventory from a
                    purchase order.
                  </List.Item>
                </List>
                <img
                  src={weightedAverageFormula}
                  style={{ maxWidth: "100%", marginTop: "8px", shapeRendering: "crispEdges" }}
                />
              </>
            }
            id="weighted_average"
            label="Weighted average"
            onChange={handleSyncOptionChange}
          />
        </LegacyStack>
      </LegacyCard.Section>
      <LegacyCard.Header title="Include cost adjustments in variant costs?" />
      <LegacyCard.Section>
        Do you want a purchase order's cost adjustments (shipping, duties, taxes, etc.) to be added to the variant costs
        when syncing? The total of the cost adjustments of the purchase order will be divided evenly between the
        variants of the purchase order.
      </LegacyCard.Section>
      <LegacyCard.Section>
        <LegacyStack vertical>
          {cost_adjustment_options.map((option) => (
            <RadioButton
              checked={user?.includeCostAdjustment === option.value}
              id={option.id}
              key={option.id}
              label={option.label}
              onChange={handleCostAdjustment}
              value={option.label}
            />
          ))}
        </LegacyStack>
      </LegacyCard.Section>
    </LegacyCard>
  );
}
