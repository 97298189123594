import React from "react";
import { NavMenu } from "@shopify/app-bridge-react";
import { Link } from "react-router-dom";

export default function NavigationMenu(): React.ReactElement {
  return (
    <NavMenu>
      <Link rel="home" to="/">
        Home
      </Link>
      <Link to="/horse_variants">Variants</Link>
      <Link to="/sales_trends">Sales trends</Link>
      <Link to="/purchase_orders">Purchase orders</Link>
      <Link to="/transfer_orders">Transfer orders</Link>
      <Link to="/inventory_level_histories">Inventory history</Link>
      <Link to="/bundles">Bundles</Link>
      <Link to="/suppliers">Suppliers</Link>
      <Link to="/horse_locations">Locations</Link>
      <Link to="/settings">Settings</Link>
    </NavMenu>
  );
}
