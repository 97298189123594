import React, { useCallback } from "react";
import { Modal, TextContainer } from "@shopify/polaris";

export function CompleteConnectStore({
  addedShopUrl,
  active,
  setActive,
}: {
  readonly addedShopUrl: string;
  readonly active: boolean;
  readonly setActive: (active: boolean) => void;
}): React.JSX.Element {
  const handleClose = useCallback(() => {
    setActive(false);
  }, [setActive]);

  return (
    <Modal
      instant
      onClose={handleClose}
      open={active}
      primaryAction={{
        content: "Install Horse",
        url: `${window.location.origin}/login?shop=${addedShopUrl}`,
        external: true,
      }}
      title="Finish connecting store"
    >
      <Modal.Section>
        <TextContainer>
          <p>To finish connecting, you must install Horse on that store as well.</p>
          <p>You will NOT be billed for secondary stores.</p>
        </TextContainer>
      </Modal.Section>
    </Modal>
  );
}
