import React, { useState, useCallback, useEffect, useMemo } from "react";
import qs from "query-string";
import type { SortButtonChoice, AppliedFilterInterface, FilterInterface } from "@shopify/polaris";
import { Select, IndexFilters, useSetIndexFiltersMode, IndexFiltersMode, TextField } from "@shopify/polaris";
import { isEmpty, softAssertNumber, urlParamToDate } from "../../../helper_functions/utils";
import {
  allTypes,
  allVendors,
  useHorseVariantsProductTypeOptions,
  useHorseVariantVendorsOptions,
} from "../../../api_utils/requests";
import MoreLessFilter from "../../common/Filters/MoreLessFilter";
import CustomRangePicker from "../../common/CustomDatePicker/CustomRangePicker";
import type { DateRangeFilter, DecimalRangeFilter, QueryParams } from "../../../api_utils/types";
import { dateLabel, moreThanLessThanLabel } from "../../common/Filters/label_tools";
// import TagCheckList from '../../../common/Filters/TagCheckList';

const sortOptions = [
  { label: "Created at", directionLabel: "Oldest/Newest", value: "created_at asc" },
  { label: "Created at", directionLabel: "Newest/Oldest", value: "created_at desc" },
  { label: "Location", directionLabel: "A-Z", value: "horse_location_name asc" },
  { label: "Location", directionLabel: "Z-A", value: "horse_location_name desc" },
  { label: "Variant", directionLabel: "A-Z", value: "horse_variant_title asc" },
  { label: "Variant", directionLabel: "Z-A", value: "horse_variant_title desc" },
  { label: "Adjusted by", directionLabel: "A-Z", value: "adjustment_type asc" },
  { label: "Adjusted by", directionLabel: "Z-A", value: "adjustment_type desc" },
  { label: "Adjustment", directionLabel: "Small/Big", value: "quantity_change asc" },
  { label: "Adjustment", directionLabel: "Big/Small", value: "quantity_change desc" },
  { label: "Quantity", directionLabel: "Small/Big", value: "new_available asc" },
  { label: "Quantity", directionLabel: "Big/Small", value: "new_available desc" },
] as SortButtonChoice[];

const adjustmentTypes = [
  { label: "Bundle child adjustment", value: "bundle_child" },
  { label: "Cancellation", value: "cancellation" },
  { label: "From Shopify", value: "from_shopify" },
  { label: "Fulfillment adjustment", value: "fulfillment_adjustment" },
  { label: "Fulfillment order", value: "fulfillment_order" },
  { label: "Fulfillment", value: "fulfillment" },
  { label: "Initial", value: "initial" },
  { label: "Stock location", value: "track_sil" },
  { label: "Empty location", value: "made_empty" },
  { label: "Purchase order", value: "purchase_order" },
  { label: "Restock", value: "restock" },
  { label: "Stock take", value: "stock_take" },
  { label: "Tracked to untracked", value: "tracked_to_untracked" },
  { label: "Transfer order receive", value: "transfer_order_receive" },
  { label: "Transfer order send", value: "transfer_order_send" },
  { label: "Untracked to tracked", value: "untracked_to_tracked" },
];

const tabs = [
  {
    // sends collection=undiscarded param to backend
    id: "horse-inventory-levels-unarchived-tab",
    content: "Unarchived",
    panelID: "horse-inventory-levels-unarchived-tab-content",
    accessibilityLabel: "Unarchived variants",
  },
  {
    // sends collection=all param to backend
    id: "horse-inventory-levels-all-tab",
    content: "All",
    panelID: "horse-inventory-levels-all-tab-content",
    accessibilityLabel: "All variants (includes unarchived and archived)",
  },
];

type DisambiguateLabelArgs =
  | {
      key: "adjustmentDate";
      value: DateRangeFilter;
      label: string;
    }
  | {
      key: "adjustmentQuantity";
      value: DecimalRangeFilter;
      label?: never;
    }
  | {
      key: "adjustmentType";
      value?: never;
      label: string;
    }
  | {
      key: "horseVariantId";
      value: number;
      label?: never;
    }
  | {
      key: "productTags";
      value: string[];
      label?: never;
    }
  | {
      key: "productType";
      value: string;
      label?: never;
    }
  | {
      key: "vendor";
      value: string;
      label?: never;
    };

const disambiguateLabel = ({ key, value, label }: DisambiguateLabelArgs): string => {
  switch (key) {
    case "horseVariantId":
      return `Variant: ${value}`;
    case "vendor":
      return `Vendor: ${value}`;
    case "productType":
      return `Type: ${value}`;
    // case 'productTags':
    //   return `Tags: ${value.join(', ')}`;
    case "adjustmentType":
      return `Adjustment type: ${label}`;
    case "adjustmentQuantity":
      return moreThanLessThanLabel(value, "Adjustment quantity");
    case "adjustmentDate":
      return dateLabel(value, label);
    default:
      throw new Error(`Unknown key: ${key}`);
  }
};

const TABS = {
  UNDISCARDED: 0,
  ALL: 1,
};

const COLLECTIONS: Record<number, "undiscarded" | "all"> = {
  [TABS.UNDISCARDED]: "undiscarded",
  [TABS.ALL]: "all",
};

export function SearchFieldAndMoreFilters({
  setFilters,
}: {
  readonly setFilters: (filters: QueryParams) => void;
}): React.JSX.Element {
  const curDateMonth = useMemo(() => new Date(), []);
  curDateMonth.setUTCHours(0, 0, 0, 0);

  const parsedUrlSearch = qs.parse(window.location.search);
  const init = {
    adjustmentDate: {
      start: urlParamToDate(parsedUrlSearch["by_date[start]"]),
      end: urlParamToDate(parsedUrlSearch["by_date[end]"]),
    },
    adjustmentQuantity: {
      less_than: parsedUrlSearch["by_adjustment[more_than]"] as string,
      more_than: parsedUrlSearch["by_adjustment[less_than]"] as string,
    },
    adjustmentType: parsedUrlSearch.adjustment_type as string,
    horseVariantId: softAssertNumber(parsedUrlSearch.horse_variant_id as string),
    // productTags: typeof parsedUrlSearch['product_tags[]'] === 'string' ? parsedUrlSearch['product_tags[]'].split(',') : parsedUrlSearch['product_tags[]'],
    productType: parsedUrlSearch.product_type as string,
    vendor: parsedUrlSearch.vendor as string,
    search: parsedUrlSearch.search as string,
    collection: parsedUrlSearch.collection as string,
  };

  const [adjustmentDateRange, setAdjustmentDateRange] = useState<DateRangeFilter>({
    start: typeof init.adjustmentDate.start === "number" ? new Date(init.adjustmentDate.start) : undefined,
    end: typeof init.adjustmentDate.end === "number" ? new Date(init.adjustmentDate.end) : undefined,
  });
  const adjustmentDateIsSelected = adjustmentDateRange.start && adjustmentDateRange.end;
  const [adjustmentQuantity, setAdjustmentQuantity] = useState<DecimalRangeFilter>(init.adjustmentQuantity);
  const [adjustmentType, setAdjustmentType] = useState(init.adjustmentType);
  const [horseVariantId, setHorseVariantId] = useState<number>(init.horseVariantId);
  // const [productTags, setProductTags] = useState(init.productTags || []);
  const [productType, setProductType] = useState(init.productType);
  const [queryValue, setQueryValue] = useState(init.search);
  const [vendor, setVendor] = useState(init.vendor);
  const [collection, setCollection] = useState<number>(softAssertNumber(init.collection) || TABS.UNDISCARDED);

  const initSortedColDir = (parsedUrlSearch["by_sort[direction]"] as string) || "desc";
  const initSortedCol = (parsedUrlSearch["by_sort[column]"] as string) || "created_at";
  const [sortValue, setSortValue] = useState([`${initSortedCol} ${initSortedColDir}`]);

  useEffect(() => {
    const [column_name, direction] = sortValue[0].split(" ");
    setFilters({
      by_date: adjustmentDateRange,
      by_adjustment: adjustmentQuantity,
      adjustment_type: adjustmentType,
      horse_variant_id: horseVariantId,
      // 'product_tags[]': productTags,
      product_type: productType,
      search: queryValue,
      vendor,
      collection: COLLECTIONS[collection],

      by_sort: { column: column_name, direction },
    });
  }, [
    setFilters,
    adjustmentDateIsSelected,
    adjustmentDateRange,
    adjustmentQuantity,
    adjustmentType,
    horseVariantId,
    // productTags,
    productType,
    queryValue,
    sortValue,
    vendor,
    collection,
  ]);

  const { data: productTypes } = useHorseVariantsProductTypeOptions();
  const { data: vendors } = useHorseVariantVendorsOptions();

  const handleAdjustmentDateRangeChange = setAdjustmentDateRange;
  const handleAdjustmentTypeChange = useCallback((value: string) => {
    setAdjustmentType(value === allTypes ? null : value);
  }, []);
  const handleProductTypeChange = useCallback((value: string) => {
    setProductType(value === allTypes ? null : value);
  }, []);
  const handleVendorChange = useCallback((value: string) => {
    setVendor(value === allVendors ? null : value);
  }, []);

  // const handleNewTagAddition = (newTag) => {
  //   const payload = [...productTags, newTag];
  //   setProductTags(payload);
  // };

  const handleAdjustmentDateRemove = useCallback(() => {
    setAdjustmentDateRange({
      start: undefined,
      end: undefined,
    });
  }, []);
  const handleAdjustmentQuantityRemove = useCallback(() => {
    setAdjustmentQuantity({ more_than: undefined, less_than: undefined });
  }, []);
  const handleAdjustmentTypeRemove = useCallback(() => {
    setAdjustmentType(undefined);
  }, []);
  const handleHorseVariantIdRemove = useCallback(() => {
    setHorseVariantId(undefined);
  }, []);
  // const handleProductTagsRemove = useCallback(() => setProductTags([]), []);
  const handleProductTypeRemove = useCallback(() => {
    setProductType(undefined);
  }, []);
  const handleQueryValueRemove = useCallback(() => {
    setQueryValue(undefined);
  }, []);
  const handleVendorRemove = useCallback(() => {
    setVendor(undefined);
  }, []);

  const handleFiltersClearAll = useCallback(() => {
    handleAdjustmentDateRemove();
    handleAdjustmentQuantityRemove();
    handleAdjustmentTypeRemove();
    handleHorseVariantIdRemove();
    // handleProductTagsRemove();
    handleProductTypeRemove();
    handleVendorRemove();
  }, [
    handleAdjustmentDateRemove,
    handleAdjustmentQuantityRemove,
    handleAdjustmentTypeRemove,
    handleHorseVariantIdRemove,
    // handleProductTagsRemove,
    handleProductTypeRemove,
    handleVendorRemove,
  ]);

  const filters: FilterInterface[] = [
    {
      key: "vendor",
      label: "Vendor",
      filter: (
        <Select
          id="inventory-level-histories_vendor_select"
          label=""
          labelHidden
          onChange={handleVendorChange}
          options={vendors}
          value={vendor || allVendors}
        />
      ),
    },
    {
      key: "productType",
      label: "Product type",
      filter: (
        <Select
          id="inventory-level-histories_product-type_select"
          label=""
          labelHidden
          onChange={handleProductTypeChange}
          options={productTypes}
          value={productType || allTypes}
        />
      ),
    },
    {
      key: "adjustmentType",
      label: "Adjustment type",
      filter: (
        <Select
          id="inventory-level-histories_adjustment-type_select"
          label=""
          labelHidden
          onChange={handleAdjustmentTypeChange}
          options={[{ value: allTypes, label: allTypes }, ...adjustmentTypes]}
          value={adjustmentType || allTypes}
        />
      ),
    },
    {
      key: "adjustmentQuantity",
      label: "Adjustment quantity",
      filter: (
        <MoreLessFilter
          less_than={adjustmentQuantity.less_than}
          more_than={adjustmentQuantity.more_than}
          setStateCallback={setAdjustmentQuantity}
          targetState={adjustmentQuantity}
          type="integer"
        />
      ),
    },
    {
      key: "adjustmentDate",
      label: "Adjustment date",
      filter: <CustomRangePicker dateRange={adjustmentDateRange} setDateRange={handleAdjustmentDateRangeChange} />,
    },
    // {
    //   key: 'productTags',
    //   label: 'Tags',
    //   filter: (
    //     <TagCheckList
    //       onAdd={handleNewTagAddition}
    //       onChange={setProductTags}
    //       selectedOptions={productTags}
    //     />
    //   ),
    // },
    {
      key: "horseVariantId",
      label: "Variant",
      filter: <TextField autoComplete="on" disabled label="" labelHidden value={horseVariantId?.toString()} />,
      disabled: true,
      hidden: true,
    },
  ];

  const appliedFilters: AppliedFilterInterface[] = [];
  if (!isEmpty(vendor)) {
    appliedFilters.push({
      key: "vendor",
      label: disambiguateLabel({ key: "vendor", value: vendor }),
      onRemove: handleVendorRemove,
    });
  }
  if (!isEmpty(productType)) {
    appliedFilters.push({
      key: "productType",
      label: disambiguateLabel({ key: "productType", value: productType }),
      onRemove: handleProductTypeRemove,
    });
  }
  // if (!isEmpty(productTags)) {
  //   const key = keys.productTags;
  //   appliedFilters.push({
  //     key,
  //     label: disambiguateLabel({key, productTags}),
  //     onRemove: handleProductTagsRemove,
  //   });
  // }
  if (!isEmpty(horseVariantId)) {
    appliedFilters.push({
      key: "horseVariantId",
      label: disambiguateLabel({ key: "horseVariantId", value: horseVariantId }),
      onRemove: handleHorseVariantIdRemove,
    });
  }
  if (!isEmpty(adjustmentType)) {
    const { label } = adjustmentTypes.find(({ value }) => value === adjustmentType);
    appliedFilters.push({
      key: "adjustmentType",
      label: disambiguateLabel({ key: "adjustmentType", label }),
      onRemove: handleAdjustmentTypeRemove,
    });
  }
  if (!isEmpty(adjustmentQuantity.more_than) || !isEmpty(adjustmentQuantity.less_than)) {
    appliedFilters.push({
      key: "adjustmentQuantity",
      label: disambiguateLabel({ key: "adjustmentQuantity", value: adjustmentQuantity }),
      onRemove: handleAdjustmentQuantityRemove,
    });
  }
  if (adjustmentDateIsSelected) {
    appliedFilters.push({
      key: "adjustmentDate",
      label: disambiguateLabel({ key: "adjustmentDate", value: adjustmentDateRange, label: "Adjustment date" }),
      onRemove: handleAdjustmentDateRemove,
    });
  }

  const { mode, setMode } = useSetIndexFiltersMode(IndexFiltersMode.Filtering);

  return (
    <IndexFilters
      appliedFilters={appliedFilters}
      canCreateNewView={false}
      cancelAction={{
        onAction: () => {},
        disabled: false,
        loading: false,
      }}
      disableQueryField={false}
      filters={filters}
      hideFilters={false}
      hideQueryField={false}
      mode={mode}
      onClearAll={handleFiltersClearAll}
      onQueryChange={setQueryValue}
      onQueryClear={handleQueryValueRemove}
      onSelect={setCollection}
      onSort={setSortValue}
      queryPlaceholder="Search variants"
      queryValue={queryValue}
      selected={collection}
      setMode={setMode}
      sortOptions={sortOptions}
      sortSelected={sortValue}
      tabs={tabs}
    />
  );
}
