import React, { useState, useCallback } from "react";
import logo from "images/horse-logo-110.png";
import { BlockStack, TextField, Button, Card, InlineStack, Text } from "@shopify/polaris";
import PopoverWithActionListExample from "./Popover";
import { useUpdateCommentMutation } from "../../api_utils/requests";
import { prettyFormatDate } from "../../helper_functions/utils";
import type { Comment } from "../../api_utils/types";

export default function EditComment({
  comment,
  setDeleteCommentId,
  purchaseOrderId,
  setDeletionDialogueActive,
  refetch,
}: {
  readonly comment: Comment;
  readonly setDeleteCommentId: (value: number) => void;
  readonly refetch: () => void;
  readonly purchaseOrderId: number;
  readonly setDeletionDialogueActive: (value: boolean) => void;
}): React.JSX.Element {
  const [editingCommentId, setEditingCommentId] = useState<number | null>(null);
  console.log("editingCommentId", editingCommentId);
  const [editedContent, setEditedContent] = useState<string>("");
  const { mutate } = useUpdateCommentMutation({
    onSuccess: () => {
      refetch();
      setEditingCommentId(null);
      setEditedContent("");
      shopify.toast.show("Comment updated");
    },
    onError: () => {
      shopify.toast.show("Error updating comment");
    },
  });

  const handleEditContent = (value: string): void => {
    setEditedContent(value);
  };

  const handleEdit = (cmt: Comment): void => {
    setEditingCommentId(cmt.id);
    setEditedContent(cmt.content);
  };

  const handleDelete = (cmt: Comment): void => {
    setDeleteCommentId(cmt.id);
    openDeletionModal();
  };

  const openDeletionModal = useCallback(() => {
    setDeletionDialogueActive(true);
  }, [setDeletionDialogueActive]);

  const handleUpdateComment = (): void => {
    if (!editedContent.trim()) {
      return;
    }

    mutate({
      purchaseOrderId,
      commentId: editingCommentId,
      content: editedContent,
    });
  };

  return (
    <Card key={comment.id}>
      <InlineStack gap="100">
        <img alt="Logo" height={50} src={logo} />
        <div style={{ flex: 1 }}>
          <BlockStack gap="100">
            <InlineStack align="space-between" blockAlign="center" gap="100">
              <Text as="p" tone="subdued">
                {prettyFormatDate(comment.created_at)}
              </Text>
              <PopoverWithActionListExample comment={comment} handleDelete={handleDelete} handleEdit={handleEdit} />
            </InlineStack>

            {editingCommentId === comment.id ? (
              <BlockStack as="div" gap="100">
                <TextField
                  autoComplete="off"
                  label=""
                  multiline
                  onChange={handleEditContent}
                  value={editedContent}
                  variant="borderless"
                />
                <InlineStack align="end">
                  <Button disabled={editedContent.trim() === ""} onClick={handleUpdateComment}>
                    Save
                  </Button>
                </InlineStack>
              </BlockStack>
            ) : (
              <Text as="p">{comment.content}</Text>
            )}
          </BlockStack>
        </div>
      </InlineStack>
    </Card>
  );
}
