import React from "react";
import { BlockStack, Card, Layout, SkeletonBodyText, SkeletonPage } from "@shopify/polaris";

export function HorseVariantShowPageSkeleton(): React.JSX.Element {
  return (
    <SkeletonPage>
      <Layout>
        <Layout.Section variant="oneThird">
          <BlockStack gap="400">
            <Card>
              <SkeletonBodyText lines={3} />
            </Card>
            <Card>
              <SkeletonBodyText lines={3} />
            </Card>
            <Card>
              <SkeletonBodyText lines={3} />
            </Card>
            <Card>
              <SkeletonBodyText lines={5} />
            </Card>
            <Card>
              <SkeletonBodyText lines={3} />
            </Card>
          </BlockStack>
        </Layout.Section>
        <Layout.Section variant="oneHalf">
          <BlockStack gap="400">
            <Card>
              <SkeletonBodyText lines={5} />
            </Card>
            <Card>
              <SkeletonBodyText lines={2} />
            </Card>
            <Card>
              <SkeletonBodyText lines={2} />
            </Card>
            <Card>
              <SkeletonBodyText lines={5} />
            </Card>
            <Card>
              <SkeletonBodyText lines={5} />
            </Card>
          </BlockStack>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );
}
