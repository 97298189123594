import qs from "query-string";
import React, { useState, useCallback } from "react";
import {
  Page,
  Button,
  LegacyCard,
  Pagination,
  LegacyStack,
  Link,
  IndexTable,
  InlineStack,
  Text,
} from "@shopify/polaris";
import { SearchFieldAndMoreFilters } from "./support/SearchFieldAndMoreFilters";
import { downloadFile, useInventoryLevelHistories } from "../../api_utils/requests";
import { humanDateFormat } from "../../helper_functions/utils";
import HorseVariant from "../common/HorseVariant/HorseVariant";
import { Footer } from "../common/Footer";
import { HorseLocationSelect } from "../common/Filters/HorseLocationSelect";
import { TruncatedText } from "../common/HorseVariant/TruncatedText";
import type { InventoryLevelHistory, QueryParams } from "../../api_utils/types";
import type { NonEmptyArray } from "@shopify/polaris/build/ts/src/types";
import type { IndexTableHeading } from "@shopify/polaris/build/ts/src/components/IndexTable";
import { AdjustedByLink } from "./support/AdjustedByLink";

const columnNames = [
  { title: "Date" },
  { title: "Location" },
  { title: "Variant" },
  { title: "Adjusted by" },
  { title: "Adjustment" },
  { title: "Quantity" },
  { title: "Reason" },
  { title: "Comment" },
] satisfies NonEmptyArray<IndexTableHeading>;

const reasonMapping: Record<string, string> = {
  correction: "Correction",
  count_inventory: "Count inventory",
  received: "Received",
  return_restock: "Return restock",
  damaged: "Damaged",
  theft_or_loss: "Theft or loss",
  promotion_or_donation: "Promotion or donation",
  other: "Other",
};

const convertSnakeCaseToHumanReadableCapitalized = (str: string): string =>
  str.length > 0 ? str[0].toUpperCase() + str.split("_").join(" ").slice(1) : "";

const adjustedBy = (inventoryLevelHistory: InventoryLevelHistory): React.ReactElement | string => {
  const { url } = inventoryLevelHistory;
  let adjustedByTitle: string;
  if (inventoryLevelHistory.adjustment_type === "fulfillment_order" && inventoryLevelHistory.quantity_change > 0) {
    adjustedByTitle = "Fulfillment order completed or cancelled";
  } else {
    adjustedByTitle = convertSnakeCaseToHumanReadableCapitalized(
      inventoryLevelHistory.adjustment_type || inventoryLevelHistory.missing_inventory_adjustment_reason || "",
    );
  }
  const primaryLink = <AdjustedByLink title={adjustedByTitle} url={url} />;

  if (inventoryLevelHistory.adjustment_type === "bundle_child") {
    const source_inventory_adjustment = inventoryLevelHistory.inventory_adjustment.adjustable.adjustment_type;
    const sourceAdjustedByTitle = convertSnakeCaseToHumanReadableCapitalized(source_inventory_adjustment);
    if (inventoryLevelHistory.inventory_adjustment.adjustable.url) {
      return (
        <>
          {primaryLink}
          &nbsp;
          <AdjustedByLink
            title={`(${sourceAdjustedByTitle})`}
            url={inventoryLevelHistory.inventory_adjustment.adjustable.url}
          />
        </>
      );
    } else {
      return (
        <>
          {primaryLink}
          &nbsp;
          {`(${sourceAdjustedByTitle})`}
        </>
      );
    }
  }

  return primaryLink;
};

const inventoryLevelHistoryHelpPageUrl =
  "https://horse-inventory.notion.site/Inventory-History-f54ca590f6774698bd19cb5a5977d79c";

function InventoryLevelHistories(): React.ReactElement {
  const parsedUrlSearch = qs.parse(window.location.search);
  const [pageNum, setPageNum] = useState(Number(parsedUrlSearch.page) || 1);
  const [filters, setFilters] = useState<QueryParams>(parsedUrlSearch);
  const initHorseLocationId = parsedUrlSearch.horse_location_id as string;
  const [horseLocationId, setHorseLocationId] = useState<string>(initHorseLocationId);

  const formatReason = (reason: string): string => {
    return reasonMapping[reason] || reason;
  };
  const query = {
    ...filters,
    horse_location_id: horseLocationId,
    page: pageNum,
  };
  const { data, isFetching: isLoading } = useInventoryLevelHistories(query);
  const { rows: inventoryLevelHistories, hasNext, hasPrev } = data;

  const setFiltersAndResetPage = useCallback((newFilters: QueryParams) => {
    setFilters(newFilters);
    setPageNum(1);
  }, []);

  const setPageNumAndScroll = useCallback((newPageNum: number) => {
    setPageNum(newPageNum);
    document.getElementsByTagName("body")[0]?.scrollIntoView();
  }, []);
  const nextPage = useCallback(() => {
    if (hasNext) {
      setPageNumAndScroll(pageNum + 1);
    }
  }, [hasNext, pageNum, setPageNumAndScroll]);
  const prevPage = useCallback(() => {
    if (hasPrev) {
      setPageNumAndScroll(pageNum - 1);
    }
  }, [hasPrev, pageNum, setPageNumAndScroll]);

  return (
    <Page
      fullWidth
      primaryAction={
        <Button
          onClick={async (): Promise<void> => {
            const filename = `Horse Inventory History.csv`;
            await downloadFile("/inventory_level_histories.csv", filename, filters);
          }}
          removeUnderline
          size="medium"
          variant="monochromePlain"
        >
          Export CSV
        </Button>
      }
      title="Inventory history"
    >
      <LegacyCard>
        <LegacyCard.Section>
          <InlineStack blockAlign="start" gap="300">
            <HorseLocationSelect horseLocationId={horseLocationId} setHorseLocationId={setHorseLocationId} />
          </InlineStack>
        </LegacyCard.Section>
        <SearchFieldAndMoreFilters setFilters={setFiltersAndResetPage} />
        <IndexTable
          headings={columnNames}
          itemCount={inventoryLevelHistories.length}
          loading={isLoading}
          selectable={false}
        >
          {inventoryLevelHistories.map((inventoryLevelHistory, index) => (
            <IndexTable.Row id={inventoryLevelHistory.id.toString()} key={inventoryLevelHistory.id} position={index}>
              <IndexTable.Cell>{humanDateFormat(new Date(inventoryLevelHistory.created_at))}</IndexTable.Cell>
              <IndexTable.Cell>
                <TruncatedText maxLength={25} text={inventoryLevelHistory.horse_location_name}>
                  <Link url={`/horse_locations/${inventoryLevelHistory.horse_location_id}`}>
                    <div style={{ maxWidth: "160px" }}>
                      <Text as="p" truncate>
                        {inventoryLevelHistory.horse_location_name}
                      </Text>
                    </div>
                  </Link>
                </TruncatedText>
              </IndexTable.Cell>
              <IndexTable.Cell>
                <div className="medium_hv">
                  <HorseVariant
                    key={inventoryLevelHistory.horse_variant_id}
                    variant={{
                      id: inventoryLevelHistory.horse_variant_id,
                      variant_title: inventoryLevelHistory.horse_variant_variant_title,
                      product_title: inventoryLevelHistory.horse_variant_title,
                      sku: inventoryLevelHistory.horse_variant_sku,
                      vendor: inventoryLevelHistory.horse_variant_vendor,
                      product_type: inventoryLevelHistory.horse_variant_product_type,
                      image_src: inventoryLevelHistory.horse_variant_image_src,
                    }}
                  />
                </div>
              </IndexTable.Cell>
              <IndexTable.Cell>{adjustedBy(inventoryLevelHistory)}</IndexTable.Cell>
              <IndexTable.Cell>{inventoryLevelHistory.quantity_change}</IndexTable.Cell>
              <IndexTable.Cell>
                {Number.isInteger(inventoryLevelHistory.new_available) ? inventoryLevelHistory.new_available : "∞"}
              </IndexTable.Cell>
              <IndexTable.Cell>
                {inventoryLevelHistory.reason ? formatReason(inventoryLevelHistory.reason) : "-"}
              </IndexTable.Cell>
              <IndexTable.Cell>{inventoryLevelHistory.comment ? inventoryLevelHistory.comment : "-"}</IndexTable.Cell>
            </IndexTable.Row>
          ))}
        </IndexTable>
        <LegacyCard.Section>
          <LegacyStack alignment="center" distribution="center">
            <Pagination
              hasNext={hasNext}
              hasPrevious={hasPrev}
              nextKeys={[39]}
              onNext={nextPage}
              onPrevious={prevPage}
              previousKeys={[37]}
            />
          </LegacyStack>
        </LegacyCard.Section>
      </LegacyCard>
      <Footer pageTitle="inventory history" url={inventoryLevelHistoryHelpPageUrl} />
    </Page>
  );
}

export default InventoryLevelHistories;
