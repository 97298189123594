import React, { useState, useCallback, useEffect } from "react";
import {
  ActionList,
  BlockStack,
  Box,
  Button,
  Card,
  InlineStack,
  Layout,
  Page,
  Popover,
  Text,
  TextField,
} from "@shopify/polaris";
import { HorizontalDotsMinor, EditMinor } from "@shopify/polaris-icons";
import { extractMessageFromError, isEmpty } from "../../helper_functions/utils";
import { createBundle } from "../../api_utils/requests";
import { ErrorBanner } from "../common/ErrorBanner";
import HorseVariant from "../common/HorseVariant/HorseVariant";
import { SelectVariantDialog } from "../common/SelectVariantDialog";
import { Footer } from "../common/Footer";
import type { Bundle, IHorseVariant } from "../../api_utils/types";
import { useNavigate } from "react-router-dom";
import { SaveBar } from "@shopify/app-bridge-react";

const bundlesHelpPageUrl = "https://horse-inventory.notion.site/Bundles-88ac584381a445de890d4f68870d5920";

function CreateBundle(): React.ReactElement {
  const [newBundle, setNewBundle] = useState<Partial<Bundle>>({});
  const [horseVariant, setHorseVariant] = useState<IHorseVariant>();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    shopify.loading(loading);
  }, [loading]);

  const [isDirty, setIsDirty] = useState(false);

  const [popoverActive, setPopoverActive] = useState(false);

  const [selectVariantModalActive, setSelectVariantModalActive] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const togglePopoverActive = useCallback(() => {
    setPopoverActive((newPopoverActive) => !newPopoverActive);
  }, []);

  const handleChange = useCallback(
    (value, id) => {
      setNewBundle({ ...newBundle, [id]: value });
      const dirty = !isEmpty(newBundle.name) && !isEmpty(horseVariant);
      setIsDirty(dirty);
    },
    [horseVariant, newBundle],
  );

  const handleHorseVariantUpdate = (parentHorseVariant: IHorseVariant): void => {
    setHorseVariant(parentHorseVariant);
    setNewBundle({ ...newBundle, horse_variant_id: parentHorseVariant.id });
    const dirty = !isEmpty(newBundle.name);
    setIsDirty(dirty);
  };

  const navigate = useNavigate();

  const [showSaveBar, setShowSaveBar] = useState(true);

  const handleSaveBundle = (): void => {
    setLoading(true);
    setShowSaveBar(false);
    createBundle({ bundle: newBundle })
      .then(({ id }) => {
        shopify.toast.show("Bundle created");
        navigate(`/bundles/${id}`);
      })
      .catch((err: unknown) => {
        Rollbar.error(err, newBundle);
        const newErrorMessage = extractMessageFromError(err);
        setErrorMessage(newErrorMessage);
        setShowSaveBar(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDiscardAction = () => {
    setNewBundle({ name: "" });
    setHorseVariant(undefined);
    setIsDirty(false);
  };

  const activator = (
    <Button accessibilityLabel="Menu" disclosure icon={HorizontalDotsMinor} onClick={togglePopoverActive} />
  );

  return (
    <Page
      backAction={{
        content: "Bundles",
        url: "/bundles",
        onAction: () => {
          void shopify.saveBar.hide("save-bar");
        },
      }}
      narrowWidth
      title="Create bundle"
    >
      <SaveBar id="save-bar" open={isDirty ? showSaveBar : false}>
        <button
          onClick={handleSaveBundle}
          type="button"
          // eslint-disable-next-line react/no-unknown-property
          variant="primary"
        />
        <button onClick={handleDiscardAction} type="button" />
      </SaveBar>

      <SelectVariantDialog
        active={selectVariantModalActive}
        handleDiscard={() => {}}
        selected={horseVariant}
        setActive={setSelectVariantModalActive}
        updateSelection={handleHorseVariantUpdate}
      />
      <Layout>
        <Layout.Section>
          <ErrorBanner errorMessage={errorMessage} setErrorMessage={setErrorMessage} />
        </Layout.Section>
        <Layout.Section>
          <BlockStack gap="400">
            <Card>
              <TextField
                autoComplete="off"
                helpText="Choose a name for your bundle."
                id="name"
                label="Bundle name"
                onChange={handleChange}
                value={newBundle.name}
              />
            </Card>
            <Card>
              <Text as="h6" variant="headingSm">
                Select parent variant
              </Text>
              <Box paddingBlock="200">
                <Text as="h6" variant="bodyXs">
                  The parent is the variant that the customer buys
                </Text>
              </Box>
              <BlockStack gap="200">
                {isEmpty(horseVariant) ? (
                  <InlineStack align="center">
                    <Button
                      onClick={() => {
                        setSelectVariantModalActive(true);
                      }}
                      variant="primary"
                    >
                      Select variant
                    </Button>
                  </InlineStack>
                ) : (
                  <InlineStack align="space-between" blockAlign="center">
                    <HorseVariant variant={horseVariant} />
                    <Popover
                      activator={activator}
                      active={popoverActive}
                      autofocusTarget="first-node"
                      onClose={togglePopoverActive}
                    >
                      <ActionList
                        actionRole="menuitem"
                        items={[
                          {
                            content: "Edit variant",
                            icon: EditMinor,
                            onAction: () => {
                              setSelectVariantModalActive(true);
                            },
                          },
                        ]}
                      />
                    </Popover>
                  </InlineStack>
                )}
              </BlockStack>
            </Card>
          </BlockStack>
        </Layout.Section>
      </Layout>
      <Footer pageTitle="bundles" url={bundlesHelpPageUrl} />
    </Page>
  );
}

export default CreateBundle;
