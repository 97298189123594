import React, { useState, useCallback } from "react";
import qs from "query-string";
import { Button, Modal, Icon, LegacyStack, DatePicker, Text } from "@shopify/polaris";
import { CalendarMajor } from "@shopify/polaris-icons";

export function TimeMachineModal({ setDate }: { readonly setDate: (date: Date) => void }): React.JSX.Element {
  const [active, setActive] = useState(false);

  const parsedUrlSearch = qs.parse(window.location.search);
  const initDate = parsedUrlSearch.date ? new Date(parsedUrlSearch.date as string) : new Date();
  const [calendarDate, setCalendarDate] = useState<Date>(initDate);
  const [calendar, setCalendar] = useState({
    month: initDate ? initDate.getMonth() : new Date().getMonth(),
    year: initDate ? initDate.getFullYear() : new Date().getFullYear(),
  });

  const makeActive = (): void => {
    setActive(true);
  };
  const makeInactive = (): void => {
    setActive(false);
  };

  const handleDateChange = useCallback(({ start }: { start: Date }) => {
    setCalendarDate(start);
  }, []);
  const handleMonthChange = useCallback((month: number, year: number) => {
    setCalendar({ month, year });
  }, []);

  const handleVisitDate = (): void => {
    makeInactive();
    setDate(calendarDate);
  };

  const activator = (
    <Button onClick={makeActive} size="medium" variant="primary">
      <LegacyStack alignment="center" spacing="extraTight" wrap={false}>
        <div>Time machine</div>
        <div style={{ height: "1em" }}>
          <Icon source={CalendarMajor} tone="base" />
        </div>
      </LegacyStack>
    </Button>
  );

  return (
    <Modal
      activator={activator}
      onClose={makeInactive}
      open={active}
      primaryAction={{
        content: "Visit date",
        onAction: handleVisitDate,
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: makeInactive,
        },
      ]}
      title="Time travel"
    >
      <Modal.Section>
        <Text as="p" variant="bodySm">
          View inventory as it was on a specific date
        </Text>
        <br />
        <DatePicker
          disableDatesAfter={new Date()}
          month={calendar.month}
          onChange={handleDateChange}
          onMonthChange={handleMonthChange}
          selected={calendarDate}
          year={calendar.year}
        />
      </Modal.Section>
    </Modal>
  );
}
