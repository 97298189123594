/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { Link } from "react-router-dom";

const IS_EXTERNAL_LINK_REGEX = /^(?:[a-z][a-z\d+.-]*:|\/\/)/;

export function PolarisLinkComponent({
  children,
  url = "",
  external = false,
  ...rest
}: {
  readonly children: React.ReactNode;
  readonly url?: string;
  readonly external?: boolean;
}): React.ReactElement {
  // react-router only supports links to pages it can handle itself. It does not
  // support arbitrary links, so anything that is not a path-based link should
  // use a regular old `a` tag
  if (external || IS_EXTERNAL_LINK_REGEX.test(url)) {
    return (
      <a href={url} {...rest} rel="noopener noreferrer" target="_blank">
        {children}
      </a>
    );
  }

  return (
    <Link to={url} {...rest}>
      {children}
    </Link>
  );
}
