export interface IHorseVariant {
  id?: number;
  horse_variant_id?: number;
  image_src: string;
  title?: string;
  product_title: string;
  variant_title: string;
  sku: string;
  vendor: string;
  product_type: string;
  tracked?: boolean;
  currently_available?: number;
  ordered?: number;
  sales_quantity?: number;
  available_days?: number;
  estimated_days_of_sales_remaining?: number;
  sales_rate?: number;
  cost?: number;
  price?: number;
  discarded?: boolean;
  discarded_at?: Date;
  recommendations_enabled: boolean;
  weight?: number;
  height?: number;
  length?: number;
  width?: number;
  barcode?: string;
  warehouse_location?: string;
  tags?: string[];
  min: number;
}

export interface Comment {
  id: number;
  user: string;
  content: string;
  created_at: string;
}

export interface PostComment {
  content: string;
}

export interface SalesTrendsTotalsAndMax {
  total_sales_pre_day: number;
  total_sales: number;
  max_sales_rate: number;
  total_ordered: number;
  total_available: number;
}

export interface Option {
  label: string;
  value: string;
}

export interface HorseInventoryLevel {
  id: number;
  horse_location_id: number;
  horse_variant_id: number;
  available: number;
  horse_location: {
    id: number;
    name: string;
  };
  reason: string;
  comment: string;
}

export interface IUpdateOrCreateHorseInventoryLevel {
  id?: number;
  horse_location_id: number;
  horse_variant_id: number;
  available: number;
  horse_location: {
    id: number;
    name: string;
  };
}

export interface Supplier {
  id: number;
  name: string;
  email: string;
  delivery_lead_days: number;
  user_id: number;
  discarded_at: string;
  discarded: boolean;
  currency: string;
  notes: string;
  minimum_order_value: number;
}

export interface ISupplierSearchOption {
  name: string;
  id: number;
}

export interface PaginatedResponse<T> {
  rows: T[];
  hasNext: boolean;
  hasPrev: boolean;
}

export interface FormattedShopifyChannel {
  helpText: string;
  label: string;
  type: "available" | "unavailable";
  value: string;
  shopifyChannelId: number;
}

export interface SupplierHorseVariant {
  id: number;
  supplier_id: number;
  horse_variant_id: number;
  minimum_order_quantity: number;
  pack_size: number;
  supplier_sku: string;
  cost: number;
  product_title: string;
  tracked: boolean;
  variant_title: string;
  sku: string;
  product_type: string;
  vendor: string;
  image_src: string;
}

export type ChildBundleHorseVariant = IHorseVariant & {
  bundle_id: number;
  quantity: number;
};

export interface Bundle {
  id: number;
  name: string;
  user_id: number;
  horse_variant_id: number;
  horseVariant?: IHorseVariant;
  parent_inventory_tracks_children: boolean;
}

export enum PurchaseOrderState {
  Draft = "draft",
  Ordered = "ordered",
  Closed = "closed",
}

export interface IPurchaseOrder {
  id: number;
  vendor?: string;
  horse_location_id: number;
  destination: string;
  arrival_date?: Date;
  forecasting_method?: "sales_rate" | "seasonal";
  sampling_period?: number;
  days_of_inventory?: number;
  unbuyable_choice?: string;
  buffer_type?: string;
  buffer_value?: number;
  growth_factor?: number;
  state: PurchaseOrderState;
  created_at: Date;
  updated_at: Date;
  currency?: string;
  cargo_control_number?: string;
  entry_summary_id?: string;
  exchange_rate?: number;
  release_date?: Date;
  label?: string;
  note?: string;
  supplier_id?: number;
  depletion_date?: Date;
  supplier: Supplier;
}

export type CreatePurchaseOrder =
  | {
      horse_location_id: number;
      supplier_id?: never;
      vendor: string;
    }
  | {
      horse_location_id: number;
      supplier_id: number;
      vendor?: never;
    };

export interface PurchaseOrderUpdatePayload
  extends Omit<Partial<IPurchaseOrder>, "id" | "updated_at" | "created_at" | "destination" | "supplier"> {
  id?: never;
  updated_at?: never;
  created_at?: never;
  destination?: never;
  supplier?: never;
  sync_costs_requested?: boolean;
  purchase_order_line_items_attributes?: PurchaseOrderLineItemUpdatePayload[];
}

export interface PurchaseOrderLineItemUpdatePayload {
  id: number;
  cost?: number;
  description_of_goods?: string;
  entry_summary_line_number?: string;
  horse_variant_id?: number;
  quantity?: number;
  received?: number;
  rejected?: number;
}

export interface IPurchaseOrderLineItem {
  id: number;
  variant_title: string;
  sku: string;
  barcode: string;
  horse_variant_id: number;
  product_title: string;
  vendor: string;
  product_type: string;
  received: number;
  rejected: number;
  quantity: number;
  cost: number;
  description_of_goods: string;
  entry_summary_line_number: string;
  classification_number: string;
  duty_rate: number;
  image_src: string;
  tracked: boolean;
  currently_available: number;
  ordered_quantity: number;
  needed: number;
  oos_date: Date;
  line_total: number;
  sales_rate: number;
  created_at: Date;
  shopify_image_id: string;
  warehouse_location: string;
  sortIndex: number;
  supplier_cost?: number;
  minimum_order_quantity?: number;
  pack_size?: number;
  supplier_sku?: string;
}

export interface NumberRangeFilter {
  more_than?: number;
  less_than?: number;
}

// Needed to support the user typing a decimal
export interface DecimalRangeFilter {
  more_than?: string;
  less_than?: string;
}

export interface DateRangeFilter {
  start?: Date;
  end?: Date;
}

export interface SortFilter {
  column: string;
  direction: string;
}

export type BaseQueryParams = Record<
  string,
  | Date
  | DateRangeFilter
  | DecimalRangeFilter
  | number[]
  | NumberRangeFilter
  | SortFilter
  | string[]
  | boolean
  | number
  | string
  | undefined
  | null
>;

export type QueryParams<T extends BaseQueryParams = BaseQueryParams> = T;

export interface HomeRecommendationResponse {
  variant_title: string;
  sku: string;
  product_title: string;
  vendor: string;
  product_type: string;
  horse_variant_id: number;
  image_src: string;
  oos_date?: Date;
  available?: number;
  amount: number;
}

export interface HomeRecommendationsResponse {
  oos_lost: number;
  ooss_future_lost: number;
  os_frozen: number;

  out_of_stock_soon: HomeRecommendationResponse[];
  out_of_stock: HomeRecommendationResponse[];
  over_stock: HomeRecommendationResponse[];

  primary_location_id: number;
}

export interface ShopifyVariant {
  id: number;
  title: string;
  image_src: string;
  shop_domain: string;
  variant_url: string;
  price: number;
}

export interface HorseLocation {
  id: number;
  name: string;
  user_id: number;
  discarded_at?: Date;
  discarded?: boolean;
  country_code?: string;
  country?: string;
  city?: string;
  province?: string;
  province_code?: string;
  zip?: string;
  phone?: string;
  address1?: string;
  address2?: string;
  localized_country_name?: string;
  localized_province_name?: string;
  legacy?: boolean;
  shopify_updated_at?: Date;
  shopify_locations_count?: number;
  created_at: Date;
  updated_at: Date;
}

export interface LinkedLocation {
  id: number;
  shopify_location_id: number;
  name: string;
  shopifyDomain: string;
}

export interface TransferOrder {
  id: number;
  origin_horse_location_id: number;
  destination_horse_location_id: number;
  state: string;
  label?: string;
  estimated_arrival_date?: Date;
  sent_date?: Date;
  arrived_date?: Date;
  created_at: Date;
  updated_at: Date;
}

export interface IndexTransferOrder {
  id: number;
  label: string;
  origin?: HorseLocation;
  destination?: HorseLocation;
  status: string;
  sent: number;
  received: number;
  quantity: number;
  value: number;
}

export interface TransferOrderLineItem {
  id: number;
  variant_title: string;
  sku: string;
  barcode: string;
  product_title: string;
  vendor: string;
  horse_variant_id: number;
  product_type: string;
  quantity_received: number;
  quantity_sent: number;
  image_src: string;
  warehouse_location: string;
  cost: number;
  currently_available: number;
  tracked: boolean;
}

export type ArchivedChoice = "all" | "unarchived";

export interface SalesTrendsQueryParams extends BaseQueryParams {
  sampling_period?: string;
  info_query?: string;
  forecasting_method?: string;
  horse_location_id?: number;
  page?: number;
  per_page?: number;
  product_type?: string;
  search?: string;
  unarchived_horse_variants?: ArchivedChoice;
  vendor?: string;
  supplier_id?: number;
  published_shopify_channel_ids?: number[];
  unpublished_shopify_channel_ids?: number[];
  sale_shopify_channel_ids?: number[];
  product_tags?: string[];
  by_cost?: NumberRangeFilter;
  by_currently_available?: NumberRangeFilter;
  by_ordered_quantity?: NumberRangeFilter;
  by_sales_quantity?: NumberRangeFilter;
  by_sales_rate?: NumberRangeFilter;
  by_weight?: NumberRangeFilter;
  by_available_days?: NumberRangeFilter;
  by_sort?: SortFilter;
}

export interface CostAdjustment {
  id?: number;
  label: string;
  amount: number;
  purchase_order_id: number;
  _destroy?: boolean;
}

export interface CostAdjustmentEditable {
  id?: number;
  label: string;
  // String while it's being edited client-side
  amount: string;
  purchase_order_id: number;
  _destroy?: boolean;
}

export interface ShopifyShop {
  shopify_domain: string;
  name: string;
}

export interface Adjustable {
  adjustment_type: string;
  url: string;
}

export interface InventoryAdjustment {
  id: number;
  adjustment_tye: string;
  url: string;
  adjustable: Adjustable;
}

export interface InventoryLevelHistory {
  id: number;
  created_at: Date;
  horse_location_name: string;
  horse_location_id: number;
  horse_variant_id: number;
  horse_variant_image_src: string;
  horse_variant_title: string;
  horse_variant_variant_title: string;
  horse_variant_sku: string;
  horse_variant_product_type: string;
  horse_variant_vendor: string;
  adjustment_type: string;
  quantity_change: number;
  new_available: number;
  missing_inventory_adjustment_reason: string;
  url: string;
  adjustable_id: number;
  adjustable_type: string;
  inventory_adjustment?: InventoryAdjustment;
  reason: string;
  comment: string;
}

export interface IndexPurchaseOrder {
  id: number;
  vendor: string;
  supplierName: string;
  horse_location_name: string;
  status: string;
  ordered_date?: Date;
  date_received?: Date;
  cost: number;
  label: string;
  quantity: number;
  received: number;
}

export interface User {
  id: number;
  currency: string;
  syncLevel: string;
  syncbackSetting: string;
  costsyncSetting: string;
  companyName: string;
  companyAddress: string;
  brandLogo: string;
  email: string;
  includeCostAdjustment: boolean;
  recommendation_subscription: boolean;
}
export interface UserResponse {
  user: User;
}
export interface UpdateUser {
  currency: string;
  company_name: string;
  company_address: string;
  sync_level: string;
  syncback_setting: string;
  costsync_setting: string;
  brand_logo: string;
  email?: string;
  include_cost_adjustment: boolean;
  recommendation_subscription: boolean;
}
export interface Banner {
  info?: string;
  progress?: number;
}

export interface PurchaseOrderLineItemsQueryParams extends BaseQueryParams {
  by_sort?: string;
  justify?: boolean;
  published_shopify_channel_ids?: number[];
  unpublished_shopify_channel_ids?: number[];
  sale_shopify_channel_ids?: number[];
  arrival_date?: Date;
  days_of_inventory?: number;
}

export interface HomeQueryParams extends BaseQueryParams {
  horse_location_id: number[];
  sale_shopify_channel_ids: number[];
}

export interface RecommendationQueryParams extends QueryParams {
  minimum_needed?: number;
  product_type?: string;
  sale_shopify_channel_ids?: number[];
  published_shopify_channel_ids?: number[];
  unpublished_shopify_channel_ids?: number[];
  sampling_period?: number;
  forecasting_method?: string;
  arrival_date?: Date;
  days_of_inventory?: number;
}

// From node_modules/@shopify/polaris/build/ts/src/components/ChoiceList/ChoiceList.d.ts
export interface Choice {
  /** Value of the choice */
  value: string;
  /** Label for the choice */
  label: React.ReactNode;
  /** A unique identifier for the choice */
  id?: string;
  /** Disable choice */
  disabled?: boolean;
  /** Additional text to aide in use */
  helpText?: React.ReactNode;
  /** Indicates that the choice is aria-describedBy the error message */
  describedByError?: boolean;
  /**  Method to render children with a choice */
  renderChildren?: (isSelected: boolean) => React.ReactNode | false;
}

export interface HorseVariantsQueryParams extends BaseQueryParams {
  collection?: "undiscarded" | "discarded" | "all";
  horse_location_id?: number;
  product_type?: string;
  search?: string;
  supplier_id?: number;
  vendor?: string;
  without_bundle?: boolean;
  published_shopify_channel_ids?: number[];
  unpublished_shopify_channel_ids?: number[];
  by_cost?: DecimalRangeFilter;
  by_price?: DecimalRangeFilter;
  by_quantity?: DecimalRangeFilter | NumberRangeFilter;
  by_value?: DecimalRangeFilter;
  by_weight?: DecimalRangeFilter;
  product_tags?: string[];
  by_sort?: SortFilter;
}

export interface CombineStore {
  id: number;
  shopify_shop_id: number;
  add_store: string;
}
