import React, { useCallback, useState } from "react";
import type { CombineStore } from "../../../api_utils/types";
import { useMutateCombineStore } from "../../../api_utils/requests";
import { Button, InlineStack, ResourceItem, Text } from "@shopify/polaris";
import { DeleteMinor } from "@shopify/polaris-icons";

export function CombineStoreRow({
  combine_store,
  refetchCombineStore,
}: {
  readonly combine_store: CombineStore;
  readonly refetchCombineStore: () => void;
}): React.ReactElement {
  const destroyCombineStore = useMutateCombineStore();

  const [enableDeleteButton, setEnableDeleteButton] = useState(true);

  const deleteCombineStore = useCallback(() => {
    setEnableDeleteButton(false);
    void destroyCombineStore.mutateAsync(combine_store.id).then(() => {
      refetchCombineStore();
    });
  }, [combine_store.id, destroyCombineStore, refetchCombineStore]);

  return (
    <ResourceItem
      accessibilityLabel="Install Horse"
      external
      id={combine_store.id.toString()}
      key={combine_store.id.toString()}
      onClick={(): void => {}}
    >
      <InlineStack align="space-between" blockAlign="stretch" wrap={false}>
        <Text as="strong">{combine_store.add_store}</Text>
        <InlineStack blockAlign="stretch" gap="200" wrap={false}>
          <Button external url={`${window.location.origin}/login?shop=${combine_store.add_store}`}>
            Install Horse
          </Button>
          <Button disabled={!enableDeleteButton} icon={DeleteMinor} onClick={deleteCombineStore} />
        </InlineStack>
      </InlineStack>
    </ResourceItem>
  );
}
