import type { KeyboardEventHandler } from "react";
import React, { useState, useCallback } from "react";
import { RangeSlider, LegacyStack, TextField } from "@shopify/polaris";
import { roundToTwoDecimals, softAssertNumber } from "../../../helper_functions/utils";
import type { DecimalRangeFilter, NumberRangeFilter } from "../../../api_utils/types";

export function DualThumbRangeSlider({
  maxSalesRate,
  setRangeValue,
  rangeValue,
  step,
  defaultMax,
  suffix,
}: {
  readonly maxSalesRate?: number;
  readonly setRangeValue: (numberRangeFilter: NumberRangeFilter) => void;
  readonly rangeValue: NumberRangeFilter;
  readonly step: number;
  readonly defaultMax: number;
  readonly suffix?: string;
}): React.ReactElement {
  const selectedOrDefaultMax = rangeValue.less_than ? rangeValue.less_than : defaultMax;
  const min = 0;
  const max = maxSalesRate ? maxSalesRate : selectedOrDefaultMax;
  const initialValue: DecimalRangeFilter = { more_than: min.toString(), less_than: max.toString() };
  const [intermediateTextFieldValue, setIntermediateTextFieldValue] = useState<DecimalRangeFilter>(initialValue);

  const handleRangeSliderChange = useCallback(
    (value: [number, number]) => {
      if (isNaN(value[0]) || isNaN(value[1])) {
        throw new Error("Invalid range slider value");
      }

      const more_than = roundToTwoDecimals(value[0]);
      const less_than = roundToTwoDecimals(value[1]);

      setRangeValue({
        more_than: more_than === 0 ? undefined : more_than,
        less_than: less_than === max ? undefined : less_than,
      });
      setIntermediateTextFieldValue({
        more_than: more_than === 0 ? undefined : more_than.toString(),
        less_than: less_than === max ? undefined : less_than.toString(),
      });
    },
    [max, setRangeValue],
  );

  const handleLowerTextFieldChange = useCallback(
    (more_than: string) => {
      const less_than = rangeValue.less_than?.toString();
      setIntermediateTextFieldValue({ more_than, less_than });
    },
    [rangeValue.less_than],
  );

  const handleUpperTextFieldChange = useCallback(
    (less_than: string) => {
      const more_than = rangeValue.more_than?.toString();
      setIntermediateTextFieldValue({ more_than, less_than });
    },
    [rangeValue.more_than],
  );

  const handleLowerTextFieldBlur = useCallback(() => {
    const upperValue = rangeValue.less_than;
    const value = intermediateTextFieldValue.more_than;

    setRangeValue({
      more_than: softAssertNumber(value),
      less_than: upperValue,
    });
  }, [intermediateTextFieldValue.more_than, rangeValue.less_than, setRangeValue]);

  const handleUpperTextFieldBlur = useCallback(() => {
    const lowerValue = rangeValue.more_than;
    const value = intermediateTextFieldValue.less_than;

    setRangeValue({
      more_than: lowerValue,
      less_than: softAssertNumber(value),
    });
  }, [intermediateTextFieldValue.less_than, rangeValue.more_than, setRangeValue]);

  const handleEnterKeyPress = useCallback<KeyboardEventHandler>(
    (event) => {
      const newValue = intermediateTextFieldValue;
      const oldValue = rangeValue;

      if (
        (event.key === "Enter" && softAssertNumber(newValue.more_than) !== oldValue.more_than) ||
        softAssertNumber(newValue.less_than) !== oldValue.less_than
      ) {
        setRangeValue({
          more_than: softAssertNumber(newValue.more_than),
          less_than: softAssertNumber(newValue.less_than),
        });
      }
    },
    [intermediateTextFieldValue, rangeValue, setRangeValue],
  );

  return (
    <div onKeyDown={handleEnterKeyPress}>
      <RangeSlider
        label="Sales rate per day"
        labelHidden
        max={max}
        min={min}
        onChange={handleRangeSliderChange}
        step={step}
        value={[rangeValue.more_than || min, rangeValue.less_than || max]}
      />
      <LegacyStack distribution="equalSpacing" vertical>
        <TextField
          autoComplete="off"
          inputMode="decimal"
          label="More than"
          max={max}
          min={min}
          onBlur={handleLowerTextFieldBlur}
          onChange={handleLowerTextFieldChange}
          size="medium"
          step={step}
          suffix={suffix}
          type="number"
          value={intermediateTextFieldValue.more_than}
        />
        <TextField
          autoComplete="off"
          inputMode="decimal"
          label="Less than"
          max={max}
          min={min}
          onBlur={handleUpperTextFieldBlur}
          onChange={handleUpperTextFieldChange}
          size="medium"
          step={step}
          suffix={suffix}
          type="number"
          value={intermediateTextFieldValue.less_than}
        />
      </LegacyStack>
    </div>
  );
}
