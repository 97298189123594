import React, { useState, useEffect, useCallback, useRef, useMemo } from "react";
import {
  LegacyCard,
  Page,
  Badge,
  TextField,
  Label,
  Button,
  Icon,
  LegacyStack,
  FormLayout,
  PageActions,
  IndexTable,
  EmptyState,
  Layout,
  Link,
} from "@shopify/polaris";
import { MobileCancelMajor, ExportMinor, ImportMinor } from "@shopify/polaris-icons";
import AddVariantDialog from "../common/AddVariantDialog";
import DeleteConfirmationDialog from "../common/DeleteConfirmationDialog";
import CustomDatePicker from "../common/CustomDatePicker/CustomDatePicker";
import {
  updateTransferOrder,
  getTransferOrderHorseVariants,
  removeTransferOrderHorseVariantLineItem,
  updateTransferOrderSelection,
  deleteTransferOrder,
  removeAllTransferOrderHorseVariantLineItem,
  importTransferOrderLineItems,
  downloadFile,
  useUser,
} from "../../api_utils/requests";
import ConfirmationDialog from "../common/ConfirmationDialog";
import {
  assertNumber,
  assertString,
  commaDelimiter,
  extractMessageFromError,
  formatMoney,
  makeLineItemsMap,
} from "../../helper_functions/utils";
import HorseVariant from "../common/HorseVariant/HorseVariant";
import { ImportFile } from "../common/ImportFile";
import { ErrorBanner } from "../common/ErrorBanner";
import { Footer } from "../common/Footer";
import type { HorseLocation, TransferOrder, TransferOrderLineItem } from "../../api_utils/types";
import { TRANSFER_ORDER_HELP_PAGE_URL } from "../../constants";
import type { NonEmptyArray } from "@shopify/polaris/build/ts/src/types";
import type { IndexTableHeading } from "@shopify/polaris/build/ts/src/components/IndexTable";
import SaveBarWithConfirmation from "../common/SaveBarWithConfirmation";

type TransferOrderLineItems = Record<number, TransferOrderLineItem>;

const notValidateQuantities = (formattedTransferOrderLineItems: TransferOrderLineItems): boolean =>
  Object.values(formattedTransferOrderLineItems).some(({ quantity_sent }) => !quantity_sent || quantity_sent === 0);

const UPDATE_TRANSFER_ORDER = 1;
const MARK_AS_SENT = 2;

const SUCCESS_MESSAGES = {
  1: "Save successful",
  2: "Marked as sent",
};

const getPayload = (
  tohvs: TransferOrderLineItems,
): {
  id: number;
  quantity_sent: number;
}[] =>
  Object.values(tohvs).map((tohv: TransferOrderLineItem): { id: number; quantity_sent: number } => ({
    id: tohv.id,
    quantity_sent: tohv.quantity_sent,
  }));

const columnNames: NonEmptyArray<IndexTableHeading> = [
  { title: "Variant" },
  { title: "Available" },
  { title: "Quantity" },
  { title: "Unit cost" },
  { title: "Total value" },
  { title: "" },
];

export default function Index({
  origin,
  destination,
  transferOrder: initialTransferOrder,
  refetch,
}: {
  readonly origin: HorseLocation;
  readonly destination: HorseLocation;
  readonly transferOrder: TransferOrder;
  readonly refetch: () => void;
}): React.ReactElement {
  const defaultArrivalDate = useMemo(() => new Date(), []);
  defaultArrivalDate.setMonth(defaultArrivalDate.getMonth() + 1);
  const initialTransferOrderWithEstimatedArrivalDate = {
    ...initialTransferOrder,
    estimated_arrival_date: initialTransferOrder.estimated_arrival_date
      ? new Date(initialTransferOrder.estimated_arrival_date)
      : defaultArrivalDate,
  };
  const [transferOrder, setTransferOrder] = useState<TransferOrder>(initialTransferOrderWithEstimatedArrivalDate);
  const [originalTransferOrder, setOriginalTransferOrder] = useState<TransferOrder>(
    initialTransferOrderWithEstimatedArrivalDate,
  );
  const [formattedTransferOrderLineItems, setFormattedTransferOrderLineItems] = useState<TransferOrderLineItems>(
    {} as TransferOrderLineItems,
  );
  const [isDirty, setIsDirty] = useState(false);
  const [loading, setLoading] = useState(true);
  const [importCSVModalIsOpen, setImportCSVModalIsOpen] = useState(false);
  const [markAsSentModalActive, setMarkAsSentModalActive] = useState(false);

  const [deletionDialogueActive, setDeletionDialogueActive] = useState(false);
  const [addVariantModalActive, setAddVariantModalActive] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");

  const fetchAndUpdateVariant = useCallback(() => {
    setLoading(true);
    void getTransferOrderHorseVariants(transferOrder.id)
      .then((response) => {
        setFormattedTransferOrderLineItems(makeLineItemsMap(response || []));
      })
      .finally(() => {
        setLoading(false);
      });
  }, [transferOrder.id]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(fetchAndUpdateVariant, []);

  const {
    data: {
      user: { currency: userCurrency },
    },
  } = useUser();

  const handleDateChange = (date: Date): void => {
    setTransferOrder({ ...transferOrder, estimated_arrival_date: date });
    setIsDirty(true);
  };

  const handleLabelChange = (label: string): void => {
    setTransferOrder({ ...transferOrder, label });
    setIsDirty(true);
  };

  const handleQuantityChange = (quantity_sent: string, id: string): void => {
    const cleanQuantitySent = quantity_sent?.replace(/[^\d.-]/g, "");
    setFormattedTransferOrderLineItems({
      ...formattedTransferOrderLineItems,
      [id]: {
        ...formattedTransferOrderLineItems[id],
        quantity_sent: cleanQuantitySent,
      } as TransferOrderLineItem,
    });
    setIsDirty(true);
  };

  const handleMarkAsSent = (): void => {
    if (notValidateQuantities(formattedTransferOrderLineItems)) {
      setErrorMessage("Quantity must be greater or equal to 1");
      return;
    }
    setLoading(true);
    const payload = {
      transfer_order: {
        transfer_order_horse_variants_attributes: getPayload(formattedTransferOrderLineItems),
        state: "sent",
      },
    };
    updateTransferOrder(transferOrder.id, payload)
      .then(() => {
        shopify.toast.show(SUCCESS_MESSAGES[MARK_AS_SENT]);
        refetch();
      })
      .catch((err: unknown) => {
        Rollbar.error(err, payload);
        const message = extractMessageFromError(err);
        setErrorMessage(message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const removeVariant = (id: number) => (): void => {
    const newValues = { ...formattedTransferOrderLineItems };
    delete newValues[id];
    setFormattedTransferOrderLineItems(newValues);
    setLoading(true);
    removeTransferOrderHorseVariantLineItem(id)
      .catch((err: unknown) => {
        Rollbar.error(err, { transferOrderId: transferOrder.id, lineItemId: id });
        const message = extractMessageFromError(err);
        setErrorMessage(message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDiscardAction = (): void => {
    setTransferOrder(originalTransferOrder);
    fetchAndUpdateVariant();
    setIsDirty(false);
  };

  const handleSendAll = (): void => {
    const newFormattedTransferOrderLineItems = Object.values(
      formattedTransferOrderLineItems,
    ).reduce<TransferOrderLineItems>((acc, formattedTransferOrderLineItem) => {
      const currently_available = Number(formattedTransferOrderLineItem.currently_available);
      acc[formattedTransferOrderLineItem.id] = {
        ...formattedTransferOrderLineItem,
        quantity_sent: currently_available < 0 ? 0 : currently_available,
      };
      return acc;
    }, {});
    setFormattedTransferOrderLineItems(newFormattedTransferOrderLineItems);
    setIsDirty(true);
  };

  const handleSaveDraft = (): void => {
    setIsDirty(false);
    setLoading(true);

    const payload = {
      transfer_order: {
        ...transferOrder,
        transfer_order_horse_variants_attributes: getPayload(formattedTransferOrderLineItems),
        state: "draft",
      },
    };
    updateTransferOrder(transferOrder.id, payload)
      .then(() => {
        shopify.toast.show(SUCCESS_MESSAGES[UPDATE_TRANSFER_ORDER]);
        setOriginalTransferOrder(transferOrder);
      })
      .catch((err: unknown) => {
        Rollbar.error(err, payload);
        const message = extractMessageFromError(err);
        setErrorMessage(message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const tohvArray: TransferOrderLineItem[] = Object.values(formattedTransferOrderLineItems);
  const total = tohvArray.reduce(
    (acc, formattedTransferOrderLineItem) => {
      acc.cost +=
        assertNumber(formattedTransferOrderLineItem.quantity_sent) * assertNumber(formattedTransferOrderLineItem.cost);
      acc.quantity += assertNumber(formattedTransferOrderLineItem.quantity_sent);
      return acc;
    },
    { quantity: 0, cost: 0 },
  );

  const removeAllVariants = (): void => {
    setLoading(true);
    removeAllTransferOrderHorseVariantLineItem(transferOrder.id)
      .then(() => {
        setFormattedTransferOrderLineItems({} as TransferOrderLineItems);
        shopify.toast.show("All variants removed");
      })
      .catch((err: unknown) => {
        Rollbar.error(err, { transferOrderId: transferOrder.id });
        const message = extractMessageFromError(err);
        setErrorMessage(message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const firstSelectableDate = useRef(new Date());

  const emptyState = (
    <EmptyState
      action={{
        content: "Add variants",
        onAction: () => {
          setAddVariantModalActive(true);
        },
      }}
      heading="No variants added"
      image=""
    >
      <p>You haven't added any variants to your transfer order yet.</p>
    </EmptyState>
  );

  const pageMarkup = (
    <Page
      actionGroups={[
        {
          title: "CSV",
          actions: [
            {
              content: "Import",
              onAction: (): void => {
                setImportCSVModalIsOpen(true);
              },
              icon: ImportMinor,
            },
            {
              content: "Export",
              onAction: async (): Promise<void> => {
                const transferOrderName = transferOrder.label || transferOrder.id;
                const filename = `Horse Transfer Order ${transferOrderName}.csv`;
                await downloadFile(`/transfer_orders/${transferOrder.id}.csv${window.location.search}`, filename);
              },
              icon: ExportMinor,
            },
          ],
        },
      ]}
      backAction={{
        content: "Transfer orders",
        url: "/transfer_orders",
      }}
      primaryAction={{
        content: "Mark as sent",
        onAction: () => {
          setMarkAsSentModalActive(true);
        },
      }}
      title="Draft transfer order"
      titleMetadata={<Badge>Draft</Badge>}
    >
      <SaveBarWithConfirmation handleDiscard={handleDiscardAction} handleSave={handleSaveDraft} isDirty={isDirty} />

      <ConfirmationDialog
        active={markAsSentModalActive}
        cancelText="No"
        content="Are you sure you want to mark this transfer order as sent? You cannot undo this."
        okText="Yes"
        onCancel={() => {
          setMarkAsSentModalActive(false);
        }}
        onClose={() => {
          setMarkAsSentModalActive(false);
        }}
        onOk={() => {
          setMarkAsSentModalActive(false);
          handleMarkAsSent();
        }}
        title="Mark as sent"
      />
      <Layout>
        <Layout.Section>
          <ErrorBanner errorMessage={errorMessage} setErrorMessage={setErrorMessage} />
        </Layout.Section>
        <Layout.Section>
          <LegacyCard>
            <ImportFile
              apiCallMethod={async (formData: object) => importTransferOrderLineItems(transferOrder.id, formData)}
              modalIsOpen={importCSVModalIsOpen}
              setApiResponse={(newTOHV) => {
                setFormattedTransferOrderLineItems(makeLineItemsMap(newTOHV));
              }}
              setErrorMessage={setErrorMessage}
              setModalIsOpen={setImportCSVModalIsOpen}
              title="Import transfer order CSV"
            />
            <LegacyCard.Section title="Origin">
              <Label id="toOriginName">
                <Link url={`/horse_locations/${origin.id}`}>{origin.name}</Link>
              </Label>
            </LegacyCard.Section>
            <LegacyCard.Section title="Destination">
              <Label id="toDestinationName">
                <Link url={`/horse_locations/${destination.id}`}>{destination.name}</Link>
              </Label>
            </LegacyCard.Section>
            <LegacyCard.Section>
              <FormLayout>
                <FormLayout.Group condensed>
                  <TextField
                    autoComplete="off"
                    label="Label"
                    onChange={handleLabelChange}
                    placeholder="64568793"
                    value={transferOrder.label || ""}
                  />
                  <CustomDatePicker
                    date={transferOrder.estimated_arrival_date}
                    firstSelectableDate={firstSelectableDate.current}
                    label="Estimated arrival date"
                    placeholder="2021-07-01"
                    setDate={handleDateChange}
                  />
                </FormLayout.Group>
              </FormLayout>
            </LegacyCard.Section>
            <LegacyCard.Section title="Add variants">
              <LegacyStack alignment="center" distribution="center">
                <AddVariantDialog
                  active={addVariantModalActive}
                  handleVariantUpdate={fetchAndUpdateVariant}
                  initialHorseLocationId={origin.id}
                  initialQuantity={{ more_than: "0", less_than: undefined }}
                  primary
                  removeAllVariants={removeAllVariants}
                  selected={tohvArray.map(({ horse_variant_id }) => horse_variant_id)}
                  selectionKey="transfer_order_horse_variants_attributes"
                  setActive={setAddVariantModalActive}
                  setErrorMessage={setErrorMessage}
                  updateSelection={(payload, queryParams) =>
                    void updateTransferOrderSelection(transferOrder.id, payload, queryParams)
                  }
                />
              </LegacyStack>
            </LegacyCard.Section>
          </LegacyCard>

          <LegacyCard>
            <LegacyCard.Section>
              <LegacyStack distribution="trailing">
                <Button onClick={handleSendAll}>Send all</Button>
              </LegacyStack>
            </LegacyCard.Section>
            <IndexTable
              emptyState={emptyState}
              headings={columnNames}
              itemCount={tohvArray.length}
              loading={loading}
              selectable={false}
            >
              <IndexTable.Row id="subHeaderTotalsRow" key={0} position={0} rowType="subheader">
                <IndexTable.Cell>Totals</IndexTable.Cell>
                <IndexTable.Cell />
                <IndexTable.Cell>{commaDelimiter(total.quantity)}</IndexTable.Cell>
                <IndexTable.Cell />
                <IndexTable.Cell>{formatMoney(total.cost || 0, userCurrency)}</IndexTable.Cell>
                <IndexTable.Cell />
              </IndexTable.Row>

              {tohvArray.map((variant, index) => (
                <IndexTable.Row id={variant.id.toString()} key={variant.id} position={index + 1}>
                  <IndexTable.Cell>
                    <HorseVariant key={variant.id} variant={variant} />
                  </IndexTable.Cell>
                  <IndexTable.Cell>{variant.tracked ? variant.currently_available || "0" : "∞"}</IndexTable.Cell>
                  <IndexTable.Cell>
                    <div className="w120">
                      <TextField
                        autoComplete="off"
                        error={isDirty ? !variant.quantity_sent : null}
                        id={variant.id.toString()}
                        key={variant.id}
                        label=""
                        name="value"
                        onChange={handleQuantityChange}
                        type="integer"
                        value={assertString(variant.quantity_sent)}
                      />
                    </div>
                  </IndexTable.Cell>
                  <IndexTable.Cell>{formatMoney(variant.cost, userCurrency)}</IndexTable.Cell>
                  <IndexTable.Cell>
                    {formatMoney(Number(variant.cost || 0) * Number(variant.quantity_sent || 0), userCurrency)}
                  </IndexTable.Cell>
                  <IndexTable.Cell>
                    <Button
                      icon={<Icon source={MobileCancelMajor} />}
                      key={variant.id}
                      onClick={removeVariant(variant.id)}
                    />
                  </IndexTable.Cell>
                </IndexTable.Row>
              ))}
            </IndexTable>
          </LegacyCard>
        </Layout.Section>
      </Layout>
      <PageActions
        secondaryActions={[
          {
            content: "Delete",
            destructive: true,
            onAction: (): void => {
              setDeletionDialogueActive(true);
            },
          },
        ]}
      />
      <DeleteConfirmationDialog
        active={deletionDialogueActive}
        content="Are you sure you want to delete this transfer? This action cannot be reversed."
        deleteItem={deleteTransferOrder}
        gotoPath="/transfer_orders"
        itemId={transferOrder.id}
        setActive={setDeletionDialogueActive}
        title="Delete transfer"
        toastMessage="Transfer order deleted successfully"
      />
      <Footer pageTitle="transfer orders" url={TRANSFER_ORDER_HELP_PAGE_URL} />
    </Page>
  );

  return pageMarkup;
}
