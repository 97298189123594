import React, { useState, useCallback, useEffect } from "react";
import { TextField, LegacyCard, Page, Layout } from "@shopify/polaris";
import { createSupplier } from "../../api_utils/requests";
import { ErrorBanner } from "../common/ErrorBanner";
import { Footer } from "../common/Footer";
import { extractMessageFromError } from "../../helper_functions/utils";
import type { Supplier } from "../../api_utils/types";
import { useNavigate } from "react-router-dom";
import { SaveBar } from "@shopify/app-bridge-react";

function CreateSupplier(): React.ReactElement {
  const [newSupplier, setNewSupplier] = useState<Supplier>({} as Supplier);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    shopify.loading(loading);
  }, [loading]);
  const [isDirty, setIsDirty] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = useCallback(
    (value: string, key: keyof Supplier) => {
      setNewSupplier({ ...newSupplier, [key]: value });
      setIsDirty(true);
    },
    [newSupplier],
  );

  const navigate = useNavigate();

  const [showSaveBar, setShowSaveBar] = useState<boolean>(true);

  const handleSaveSupplier = (): void => {
    setLoading(true);
    setShowSaveBar(false);
    createSupplier({ supplier: newSupplier })
      .then((res) => {
        shopify.toast.show("Supplier created");
        setIsDirty(false);
        navigate(`/suppliers/${res.id}`);
      })
      .catch((err: unknown) => {
        Rollbar.error(err, newSupplier);
        const message = extractMessageFromError(err);
        setErrorMessage(message);
        setShowSaveBar(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDiscardAction = (): void => {
    setNewSupplier({} as Supplier);
    setIsDirty(false);
  };

  const suppliersHelpPageUrl = "https://horse-inventory.notion.site/Suppliers-d0942bec2f8c457797a42fb805b4a8cd";
  return (
    <Page
      backAction={{
        content: "Suppliers",
        url: "/suppliers",
        onAction: () => {
          void shopify.saveBar.hide("save-bar");
        },
      }}
      narrowWidth
      title="Create supplier"
    >
      <SaveBar id="save-bar" open={isDirty ? showSaveBar : false}>
        <button
          onClick={handleSaveSupplier}
          type="button"
          // eslint-disable-next-line react/no-unknown-property
          variant="primary"
        />
        <button onClick={handleDiscardAction} type="button" />
      </SaveBar>

      <Layout>
        <Layout.Section>
          <ErrorBanner errorMessage={errorMessage} setErrorMessage={setErrorMessage} />
        </Layout.Section>
        <Layout.Section>
          <LegacyCard>
            <LegacyCard.Section>
              <TextField
                autoComplete="on"
                helpText="Choose a name for your supplier."
                id="name"
                label="Supplier name"
                onChange={handleChange}
                placeholder=""
                value={newSupplier?.name}
              />
            </LegacyCard.Section>
          </LegacyCard>
        </Layout.Section>
      </Layout>
      <Footer pageTitle="suppliers" url={suppliersHelpPageUrl} />
    </Page>
  );
}

export default CreateSupplier;
