import React, { useCallback } from "react";
import { Select } from "@shopify/polaris";
import { allLocations, useHorseLocationsOptions } from "../../../api_utils/requests";

export function HorseLocationSelect({
  horseLocationId,
  setHorseLocationId,
}: {
  readonly horseLocationId?: string;
  readonly setHorseLocationId: (horseLocationId: string) => void;
}): JSX.Element {
  // TODO: Add query params to URLs
  // parsedUrlSearch.horse_variant_id
  const { data: locations } = useHorseLocationsOptions();

  const onChange = useCallback(
    (newHorseLocation_id: string) => {
      setHorseLocationId(newHorseLocation_id === allLocations ? undefined : newHorseLocation_id);
    },
    [setHorseLocationId],
  );

  return (
    <Select label="Location" labelHidden onChange={onChange} options={locations} value={horseLocationId?.toString()} />
  );
}
