import React from "react";
import { LegacyCard, TextField, FormLayout } from "@shopify/polaris";
import { assertString } from "../../../../helper_functions/utils";
import type { IHorseVariant } from "../../../../api_utils/types";

export function Inventory({
  horseVariant,
  setWarehouseLocation,
}: {
  readonly horseVariant: IHorseVariant;
  readonly setWarehouseLocation: (value: string) => void;
}): React.JSX.Element {
  return (
    <LegacyCard sectioned title="Inventory">
      <FormLayout>
        <TextField
          autoComplete="off"
          disabled
          helpText="Along with a variant's Barcode, its barcode is used to link multiple variants together"
          label="SKU (Stock Keeping Unit)"
          type="text"
          value={assertString(horseVariant.sku)}
        />
        <TextField
          autoComplete="off"
          disabled
          helpText="Along with a variant's SKU, its barcode is used to link multiple variants together"
          label="Barcode"
          type="text"
          value={assertString(horseVariant.barcode)}
        />
        <TextField
          autoComplete="off"
          helpText="Add a note to purchase orders and transfer orders to record the location of this variant"
          label="Warehouse location"
          onChange={setWarehouseLocation}
          type="text"
          value={assertString(horseVariant.warehouse_location)}
        />
      </FormLayout>
    </LegacyCard>
  );
}
