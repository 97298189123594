import React from "react";
import { LegacyCard, LegacyStack, TextField } from "@shopify/polaris";
import { assertString } from "../../../../helper_functions/utils";

export function ProductOrganization({
  horseVariant,
}: {
  readonly horseVariant: {
    product_type: string;
    vendor: string;
  };
}): JSX.Element {
  return (
    <LegacyCard sectioned>
      <h2 className="Polaris-Heading mb20-important">Product organization</h2>
      <LegacyStack distribution="fillEvenly">
        <TextField
          autoComplete="off"
          disabled
          label="Type"
          type="text"
          value={assertString(horseVariant.product_type)}
        />
        <TextField autoComplete="off" disabled label="Vendor" type="text" value={assertString(horseVariant.vendor)} />
      </LegacyStack>
    </LegacyCard>
  );
}
