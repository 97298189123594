import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Card, Text, BlockStack, InlineStack, TextField, Button, Box, Bleed } from "@shopify/polaris";

import DeleteConfirmationDialog from "../common/DeleteConfirmationDialog";
import logo from "images/horse-logo-110.png";
import { useComments, usePostCommentMutation, deleteComment } from "../../api_utils/requests";

import EditComment from "./editComment";

export default function Comments(): React.JSX.Element {
  const { id } = useParams<{ id: string }>();
  const purchaseOrderId = Number(id);
  const [comment, setComment] = useState<string>("");
  const [deleteCommentId, setDeleteCommentId] = useState<number | null>(null);
  const [deletionDialogueActive, setDeletionDialogueActive] = useState(false);
  const { data: allComments, isLoading, refetch } = useComments(purchaseOrderId);
  const { mutateAsync } = usePostCommentMutation();

  const handlePostComment = async (): Promise<void> => {
    if (!comment.trim()) {
      alert("Comment cannot be empty!");
      return;
    }

    try {
      await mutateAsync({ purchaseOrderId, payload: { content: comment } });
      shopify.toast.show("Comment posted");
    } catch (error: unknown) {
      Rollbar.error(error, { purchaseOrderId, comment });
      shopify.toast.show("Failed to post comment");
    }
    await refetch();
    setComment("");
  };

  return (
    <BlockStack gap="200">
      <Card>
        <InlineStack blockAlign="center" gap="100">
          <img height={50} src={logo} />
          <div style={{ flex: 1 }}>
            <TextField
              autoComplete="off"
              label=""
              multiline
              onChange={(value: string) => {
                setComment(value);
              }}
              placeholder="Leave a comment..."
              value={comment}
              variant="borderless"
            />
          </div>
        </InlineStack>
        <Bleed marginBlockEnd="400" marginInline="400">
          <Box background="bg-surface-secondary" padding="300">
            <InlineStack align="end">
              <Button disabled={comment?.trim() === ""} onClick={handlePostComment}>
                Post
              </Button>
            </InlineStack>
          </Box>
        </Bleed>
      </Card>

      {!isLoading && allComments.length > 0 ? (
        allComments.map((c) => (
          <EditComment
            comment={c}
            key={c.id}
            purchaseOrderId={purchaseOrderId}
            refetch={refetch}
            setDeleteCommentId={setDeleteCommentId}
            setDeletionDialogueActive={setDeletionDialogueActive}
          />
        ))
      ) : (
        <Text as="p" tone="subdued">
          No comments yet.
        </Text>
      )}

      <DeleteConfirmationDialog
        active={deletionDialogueActive}
        content="Are you sure you want to delete this comment? This action cannot be reversed."
        deleteItem={deleteComment}
        from={purchaseOrderId}
        gotoPath={`/purchase_orders/${purchaseOrderId}`}
        itemId={deleteCommentId}
        refetch={refetch}
        setActive={setDeletionDialogueActive}
        title="Delete purchase"
        toastMessage="Comment deleted successfully"
      />
    </BlockStack>
  );
}
