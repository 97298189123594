import React, { useCallback } from "react";
import { Page, Layout, Box, Card, Text, Link, Spinner, BlockStack } from "@shopify/polaris";

export default function ImportingStoreCard(): React.ReactElement {
  const handleRefresh = useCallback(() => {
    window.location.reload();
  }, []);

  return (
    <>
      <div style={{ display: "flex", alignItems: "center", height: "95%" }}>
        <Page fullWidth>
          <Layout>
            <BlockStack gap="500" inlineAlign="center">
              <Spinner size="large" />
              <Card padding="600" roundedAbove="sm">
                <BlockStack gap="800" inlineAlign="center">
                  <Text alignment="center" as="h1" variant="headingLg">
                    Importing your store
                  </Text>
                  <Box paddingBlock="200" width="350px">
                    <Text alignment="center" as="p" variant="bodyLg">
                      Please wait. This takes longer if your store is older or you make a lot of sales.
                    </Text>
                  </Box>
                </BlockStack>
                <Box paddingBlockStart="200">
                  <Text alignment="center" as="p" variant="bodyLg">
                    <Link onClick={handleRefresh}>Refresh</Link> the page to check again.
                  </Text>
                </Box>
              </Card>
            </BlockStack>
          </Layout>
        </Page>
      </div>
      <BlockStack gap="500" inlineAlign="center">
        <Link target="_blank" url="mailto:hello@inventory.horse">
          Contact support
        </Link>
      </BlockStack>
    </>
  );
}
