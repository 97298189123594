import React from "react";
import { LegacyCard, LegacyStack, TextField, FormLayout, Label, TextContainer } from "@shopify/polaris";
import { assertNumber, assertString, commaDelimiter } from "../../../../helper_functions/utils";

const HUNDRED = 100.0;

const convertCMToMeters = (value: number): number => value / HUNDRED;
const calculateVolumeInMeters = (height: string, width: string, length: string): number =>
  convertCMToMeters(assertNumber(height)) *
  convertCMToMeters(assertNumber(width)) *
  convertCMToMeters(assertNumber(length));

export function Weight({
  height,
  setHeight,
  width,
  setWidth,
  length,
  setLength,
  weight,
  setWeight,
  setIsDirty,
}: {
  readonly height: string;
  readonly weight: string;
  readonly length: string;
  readonly width: string;
  readonly setHeight: (value: string) => void;
  readonly setWidth: (value: string) => void;
  readonly setLength: (value: string) => void;
  readonly setWeight: (value: string) => void;
  readonly setIsDirty: (value: boolean) => void;
}): JSX.Element {
  const handleChange =
    (label: string) =>
    (newValue: string): string => {
      setIsDirty(true);
      switch (label) {
        case "weight": {
          setWeight(newValue);
          return;
        }
        case "width": {
          setWidth(newValue);
          return;
        }
        case "depth": {
          setLength(newValue);
          return;
        }
        case "height": {
          setHeight(newValue);
          return;
        }
      }
    };

  const volume = calculateVolumeInMeters(width, length, height);
  // Scale the number of decimal places to show based on the size of the number
  const decimalPlaces = Math.max(0, 6 - volume.toFixed(0).toString().length);

  return (
    <LegacyCard title="Weight and dimensions">
      <LegacyCard.Section>
        <FormLayout>
          <LegacyStack distribution="fillEvenly">
            <TextField
              autoComplete="off"
              label="Weight"
              onChange={handleChange("weight")}
              suffix="g"
              type="number"
              value={assertString(weight)}
            />
          </LegacyStack>
          <LegacyStack distribution="fillEvenly">
            <TextField
              autoComplete="off"
              label="Height"
              onChange={handleChange("height")}
              suffix="cm"
              type="number"
              value={assertString(height)}
            />
            <TextField
              autoComplete="off"
              inputMode="numeric"
              label="Width"
              onChange={handleChange("width")}
              suffix="cm"
              type="number"
              value={assertString(width)}
            />
            <TextField
              autoComplete="off"
              inputMode="numeric"
              label="Depth"
              onChange={handleChange("depth")}
              suffix="cm"
              type="number"
              value={assertString(length)}
            />
          </LegacyStack>
        </FormLayout>
      </LegacyCard.Section>
      <LegacyCard.Section subdued>
        <LegacyStack>
          <LegacyStack.Item fill>
            <Label id="horse_variant-size">Size</Label>
          </LegacyStack.Item>
          <LegacyStack.Item>
            <TextContainer>{commaDelimiter(volume, decimalPlaces)}&#13221;</TextContainer>
          </LegacyStack.Item>
        </LegacyStack>
      </LegacyCard.Section>
    </LegacyCard>
  );
}
