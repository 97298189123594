import React, { useCallback } from "react";
import { Modal, TextContainer, List } from "@shopify/polaris";

export function EnableFullSyncModal({
  handleEnableFullSync,
  active,
  setActive,
}: {
  readonly handleEnableFullSync: () => void;
  readonly active: boolean;
  readonly setActive: (active: boolean) => void;
}): React.JSX.Element {
  const handleClose = useCallback(() => {
    setActive(false);
  }, [setActive]);

  return (
    <Modal
      instant
      onClose={handleClose}
      open={active}
      primaryAction={{
        content: "Enable Full Sync",
        onAction: handleEnableFullSync,
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: handleClose,
        },
      ]}
      title="Connect store"
    >
      <Modal.Section>
        <TextContainer>
          <p>In order to connect another store, you must first enable Full Sync.</p>
          <p>By enabling Full Sync:</p>
          <List type="bullet">
            <List.Item>Locations from your stores will be linked together based on their name.</List.Item>
            <List.Item>Inventory changes from stores will be synced to all other stores.</List.Item>
          </List>
        </TextContainer>
      </Modal.Section>
    </Modal>
  );
}
