import React, { useEffect } from "react";
import enTranslations from "@shopify/polaris/locales/en.json";
import { AppProvider } from "@shopify/polaris";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import NavigationMenu from "./support/navigation_menu";
import QueryString from "qs";
import { PolarisLinkComponent } from "./support/PolarisLinkComponent";
import type { LinkLikeComponent } from "@shopify/polaris/build/ts/src/utilities/link";
import { Outlet, useNavigate } from "react-router-dom";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 1,
      refetchOnMount: false,
    },
  },
});

export default function App(): React.ReactElement {
  // This method is used to scroll the table horizontally to the right
  useEffect(function handleScrollHorizontal() {
    const scrollContainers = document.querySelectorAll(".Polaris-IndexTable-ScrollContainer");
    if (scrollContainers[0]) {
      scrollContainers.forEach((scrollContainer) => {
        scrollContainer.scrollTo(window.innerWidth, window.innerHeight);
      });
    }
  }, []);

  const navigate = useNavigate();

  // ?return_to=/foobar functionality
  const queryParams = QueryString.parse(window.location.search, { ignoreQueryPrefix: true });
  const return_to = queryParams.return_to as string;
  useEffect(() => {
    if (return_to) {
      setTimeout(() => {
        navigate(return_to);
      });
    }
  }, [navigate, return_to]);

  return (
    <AppProvider i18n={enTranslations} linkComponent={PolarisLinkComponent as LinkLikeComponent}>
      <QueryClientProvider client={queryClient}>
        <NavigationMenu />
        <Outlet />
      </QueryClientProvider>
    </AppProvider>
  );
}
